// @flow

import styled, { type StyledComponent } from "styled-components"
import Box from "@fetch/ui/Box"
import type { BoxComponent } from "@fetch/ui"
import type { Theme } from "@bluframe/blublocks"
import colors from "styles/colors"

export const Wrapper: StyledComponent<{||}, Theme, BoxComponent> = styled(Box)`
  background: ${colors.white};
  margin: 20px 15px 0;
`

export const Title: StyledComponent<{||}, Theme, HTMLElement> = styled.h4`
  color: ${colors.thunder};
  font-family: Roboto, Arial, open-sans;
  font-size: 20px;
  margin: 10px 0;
`

export const Option: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  align-items: center;
  border-top: 1px solid ${colors.bonJour};
  display: flex;
  padding: 20px 0 12px;
  justify-content: space-between;
`

export const Label: StyledComponent<{||}, Theme, HTMLElement> = styled.label`
  color: ${colors.thunder};
  font-family: Roboto, Arial, open-sans;
  font-size: 14px;
`

export const Left: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  align-items: center;
  column-gap: 20px;
  display: grid;
  grid-template-columns: 20px 1fr;
`

export const Right: StyledComponent<{||}, Theme, HTMLElement> = styled.div``

export const Price: StyledComponent<{||}, Theme, HTMLElement> = styled.p`
  color: ${({ theme }) => theme.palette.text.main};
  font-family: Roboto, Arial, open-sans;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
`

export const Text: StyledComponent<{||}, Theme, HTMLElement> = styled.span`
  color: ${colors.sunsetOrange};
  font-size: 14px;
  font-weight: 500;
`

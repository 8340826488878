// @flow

import styled, { type StyledComponent } from "styled-components"
import type { Theme } from "@bluframe/blublocks"

const Reset: StyledComponent<{||}, Theme, HTMLElement> = styled.button`
  border: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  color: inherit;
  font: inherit;

  line-height: normal;

  -webkit-appearance: none;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  &:focus {
    outline: none;
  }

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`

export default Reset

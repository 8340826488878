// @flow

import { Description, Image, Left, Name, Price, Text, Wrapper } from "./styled"
import { type ComponentProps } from "."
import React from "react"

const MenuItem = ({ detail, image, name, onClick, price }: ComponentProps) => (
  <Wrapper onClick={onClick}>
    <Left>
      <Text>
        <Name>{name}</Name>

        <Description>{detail}</Description>
      </Text>

      <Price>{price}</Price>
    </Left>

    {image && <Image src={image} />}
  </Wrapper>
)

export default MenuItem

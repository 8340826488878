// @flow
/* eslint-disable max-lines-per-function, max-statements */

import type { FetchState, OrdersTab } from "@fetch/frontend"
import type {
  FetchSummary,
  FetchSummaryStop,
  ScheduledFetchSummary
} from "@fetch/graphql"
import { useEffect, useMemo, useState } from "react"
import CANCEL_SCHEDULED_FETCH from "mutations/cancelScheduledFetch"
import Item from "./Item"
import POST_FETCH_MUTATION from "mutations/postFetch"
import config from "config/recurring"
import moment from "moment"
import prepareComponent from "@bluframe/grapple/prepareComponent"
import useFetchState from "hooks/useFetchState"
import { useHistory } from "react-router-dom"
import { useMutation } from "@apollo/client"

const { LABELS_MAP } = config

type Props = {|
  ...FetchSummary | ScheduledFetchSummary,
  +activeTab: OrdersTab
|}

export type ComponentProps = {|
  +activeTab: OrdersTab,
  +dateAndTime: string,
  +driverName?: string,
  +estimatedTotal: number,
  +id: number,
  +isReoderConfirm: boolean,
  +isShowCancel: boolean,
  +isShowDetails: boolean,
  +isShowReorder: boolean,
  +isShowState: boolean,
  +loading: boolean,
  +loadingCancelScheduledFetch: boolean,
  +loadingPostFetch: boolean,
  +onCancelScheduledFetch: () => void,
  +onReorder: () => void,
  +onToggleShowDetails: () => void,
  +recurring: ?string,
  +state: FetchState,
  +stops: FetchSummaryStop[],
  +total: ?number
|}

const DATE_FORMAT = "M/D/YY h:MM A"
const DEFAULT_SHOW_DETAILS = false

const usePrepareComponent = ({
  activeTab,
  dateTime,
  estimatedTotal,
  id,
  state,
  stops,
  ...props
}: Props): ComponentProps => {
  const history = useHistory()

  let driver = null
  let total = null
  let nextFetchDate = null
  let recurring = null

  if (props.driver) {
    // eslint-disable-next-line prefer-destructuring
    driver = props.driver
  }

  if (props.total) {
    // eslint-disable-next-line prefer-destructuring
    total = props.total
  }

  if (props.nextFetchDate) {
    // eslint-disable-next-line prefer-destructuring
    nextFetchDate = props.nextFetchDate
  }

  if (props.recurring) {
    // eslint-disable-next-line prefer-destructuring
    recurring = LABELS_MAP[props.recurring]
  }

  const [isShowDetails, setIsShowDetails] = useState(DEFAULT_SHOW_DETAILS)
  const [isReoderConfirm, setIsReoderConfirm] = useState(DEFAULT_SHOW_DETAILS)
  const [
    postFetch,
    { data: dataPostFetch, error: errorPostFetch, loading: loadingPostFetch }
  ] = useMutation(POST_FETCH_MUTATION)
  const [
    cancelScheduledFetch,
    { error: errorCancelScheduledFetch, loading: loadingCancelScheduledFetch }
  ] = useMutation(CANCEL_SCHEDULED_FETCH, {
    refetchQueries: ["fetchScheduled", "history"]
  })

  const { onResetFetchState } = useFetchState()

  const isShowReorder = useMemo(() => activeTab === "history", [activeTab])
  const isShowCancel = useMemo(() => activeTab === "scheduled", [activeTab])
  const isShowState = useMemo(
    () => state === "canceled" || state === "terminated",
    [state]
  )

  useEffect(() => {
    if (dataPostFetch) {
      onResetFetchState()
      history.push(`/chat/${dataPostFetch.postFetch.id}`)
    }
  }, [history, dataPostFetch, onResetFetchState])

  const dateAndTime = useMemo(() => {
    if (activeTab === "scheduled") {
      if (recurring) {
        return `Next Delivery: ${moment(nextFetchDate).format(DATE_FORMAT)}`
      }

      return `Scheduled for: ${moment(nextFetchDate).format(DATE_FORMAT)}`
    }

    return `Placed on: ${moment(dateTime).format(DATE_FORMAT)}`
  }, [activeTab, dateTime, nextFetchDate, recurring])

  const onToggleShowDetails = () => {
    setIsShowDetails(!isShowDetails)
    setIsReoderConfirm(false)
  }

  const reorderFetch = useMemo(
    () => ({
      fetch: {
        stops: stops.map((stop) => {
          const parsedStop: any = {
            index: stop.index,
            latitude: stop.latitude,
            longitude: stop.longitude,
            name: stop.name
          }

          if (stop.placeId) {
            parsedStop.placeId = stop.placeId
          }

          if (stop.items.length) {
            parsedStop.items = stop.items.map((item) => ({
              estimatedCost: item.estimatedCost,
              index: item.index,
              label: item.label
            }))
          }

          return parsedStop
        })
      }
    }),
    [stops]
  )

  if (errorCancelScheduledFetch || errorPostFetch) {
    // eslint-disable-next-line no-console
    console.log(errorCancelScheduledFetch || errorPostFetch)
  }

  const onReorder = () => {
    if (!isShowDetails) {
      onToggleShowDetails()
      setIsReoderConfirm(true)
      return
    }

    postFetch({
      variables: reorderFetch
    })
  }

  const onCancelScheduledFetch = () => {
    cancelScheduledFetch({ variables: { id } })
  }

  return {
    activeTab,
    dateAndTime,
    driverName: driver?.user?.firstName,
    estimatedTotal,
    id,
    isReoderConfirm,
    isShowCancel,
    isShowDetails,
    isShowReorder,
    isShowState,
    loading: loadingCancelScheduledFetch || loadingPostFetch,
    loadingCancelScheduledFetch,
    loadingPostFetch,
    onCancelScheduledFetch,
    onReorder,
    onToggleShowDetails,
    recurring,
    state,
    stops,
    total
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Item
)

// @flow

import styled, { type StyledComponent } from "styled-components"
import ResetButton from "components/buttons/Reset"
import type { Theme } from "@bluframe/blublocks"
import colors from "styles/colors"

export const Wrapper: StyledComponent<any, Theme, HTMLElement> = styled.div`
  position: relative;
`

export const CustomTip: StyledComponent<
  any,
  Theme,
  StyledComponent<any, Theme, HTMLElement>
> = styled(ResetButton)`
  color: ${colors.driftwood};
  cursor: pointer;
  font-family: Roboto, Arial, open-sans;
  margin-bottom: 40px;
  position: relative;
`

// @flow

import { useLazyQuery, useMutation } from "@apollo/client"
import GET_STRIPE_KEYS from "queries/getStripeKeys"
import LOGIN from "mutations/login"
import Login from "./Login"
import TagManager from "react-gtm-module"
import prepareComponent from "hocs/prepareComponent"
import { useHistory } from "react-router-dom"
import { useLastLocation } from "react-router-last-location"
import useLogin from "hooks/useLogin"

type Props = {|
  +onSetPhoneNumber: any
|}

export type ComponentProps = {|
  ...Props,
  +googleClientId: ?string,
  +onFailure: (error: Error) => void,
  +onSuccess: (data: any) => void
|}

// eslint-disable-next-line no-process-env
const GOOGLE_LOGIN_CLIENT_ID = process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID

const usePrepareComponent = ({ onSetPhoneNumber }: Props): ComponentProps => {
  const [login, { data: loginData }] = useMutation(LOGIN)
  const [getStripeKeys, { data: stripeKeysData }] =
    useLazyQuery(GET_STRIPE_KEYS)
  const history = useHistory()
  const lastLocation = useLastLocation()

  const onFailure = (error) => {
    // eslint-disable-next-line no-console
    console.log(error)
  }

  const onSuccess = (data) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "signInWithGoogle"
      }
    })

    login({ variables: { provider: "google", token: data.tokenId } })
  }

  useLogin({
    getStripeKeys,
    history,
    lastLocation,
    loginData,
    stripeKeysData
  })

  return {
    googleClientId: GOOGLE_LOGIN_CLIENT_ID,
    onFailure,
    onSetPhoneNumber,
    onSuccess
  }
}

export default prepareComponent(usePrepareComponent)(Login)

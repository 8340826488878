// @flow

import gql from "graphql-tag"

const GET_PLACES_QUERY = gql`
  query getPlaces($input: PlacesInput!) {
    places(input: $input) {
      address
      placeType
      distance
      hasMenu
      id
      image
      isPartner
      noServiceFee
      name
      reducedServiceFee
      slug
    }
  }
`

export default GET_PLACES_QUERY

// @flow

import usePickUpAddress, {
  DEFAULT_PICK_UP_ADDRESS
} from "hooks/usePickUpAddress"
import type { Address } from "@fetch/frontend"
import Header from "./Header"
import prepareComponent from "hocs/prepareComponent"
import useDropOffAddress from "hooks/useDropOffAddress"
import useUser from "hooks/useUser"

export type ComponentProps = {|
  +dropOffAddress?: Address,
  +isLoggedIn: boolean,
  +onAddressChange: any,
  +requestOptions: any
|}

// Default radius for location biasing in meters
const DEFAULT_RADIUS = 2000

export const usePrepareComponent = (): ComponentProps => {
  const { dropOffAddress, onSetDropOffAddress } = useDropOffAddress()
  const { onSetPickUpAddress } = usePickUpAddress()
  const { user } = useUser()

  let requestOptions = {}

  // to avoid error in unit test
  if (
    process.env.NODE_ENV !== "test" &&
    dropOffAddress.coordinates.lat &&
    dropOffAddress.coordinates.lng
  )
    requestOptions = {
      location: new window.google.maps.LatLng({
        lat: dropOffAddress.coordinates.lat,
        lng: dropOffAddress.coordinates.lng
      }),
      radius: DEFAULT_RADIUS
    }

  const onAddressChange = (newDropOffAddress: any) => {
    onSetDropOffAddress(newDropOffAddress)
    onSetPickUpAddress(DEFAULT_PICK_UP_ADDRESS)
  }

  return {
    dropOffAddress,
    isLoggedIn: Boolean(user),
    onAddressChange,
    requestOptions
  }
}

export default prepareComponent(usePrepareComponent)(Header)

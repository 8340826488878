// @flow
/* eslint-disable max-lines-per-function, react/jsx-max-depth, no-ternary */

import {
  Card,
  CardTitle,
  ItemWrapper,
  ItemsWrapper,
  OrderSummaryItem,
  TotalEstimation
} from "./styled"
import { type ComponentProps } from "."
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"

const FIXED_BASE = 2
const BASE_PRICE = 100

const OrderSummary = ({
  estimates: {
    deliveryFee,
    estimatedTotalCharge,
    estimatedSubtotal,
    serviceFee
  }
}: ComponentProps) => (
  <Card>
    <CardTitle>{"Order Summary"}</CardTitle>

    <ItemsWrapper>
      <OrderSummaryItem>
        <ItemWrapper>
          {"Estimated Subtotal"}

          <FontAwesomeIcon icon={faInfoCircle} />
        </ItemWrapper>

        {estimatedSubtotal
          ? `$${(estimatedSubtotal / BASE_PRICE)?.toFixed(FIXED_BASE)}`
          : "Pending"}
      </OrderSummaryItem>

      <OrderSummaryItem>
        <ItemWrapper>
          {"Delivery Fee"}

          <FontAwesomeIcon icon={faInfoCircle} />
        </ItemWrapper>

        {deliveryFee
          ? `$${(deliveryFee / BASE_PRICE)?.toFixed(FIXED_BASE)}`
          : "Pending"}
      </OrderSummaryItem>

      <OrderSummaryItem>
        <ItemWrapper>
          {"Service Fee"}

          <FontAwesomeIcon icon={faInfoCircle} />
        </ItemWrapper>

        {serviceFee
          ? `$${(serviceFee / BASE_PRICE)?.toFixed(FIXED_BASE)}`
          : "Pending"}
      </OrderSummaryItem>
    </ItemsWrapper>

    <TotalEstimation>
      <ItemWrapper>
        {"Estimated Total"}

        <FontAwesomeIcon icon={faInfoCircle} />
      </ItemWrapper>

      {estimatedTotalCharge
        ? `$${(estimatedTotalCharge / BASE_PRICE)?.toFixed(FIXED_BASE)}`
        : "Pending"}
    </TotalEstimation>
  </Card>
)

export default OrderSummary

// @flow

import styled, { type StyledComponent } from "styled-components"
import type { Theme } from "@bluframe/blublocks"

export const Container: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`

export const CrossWrapper: StyledComponent<
  {||},
  Theme,
  HTMLElement
> = styled.div`
  left: 30px;
  position: absolute;
  top: 20px;
`

// @flow

import PromoCode from "./PromoCode"
import TagManager from "react-gtm-module"
import prepareComponent from "hocs/prepareComponent"
import { useParams } from "react-router-dom"
import { useState } from "react"

type Props = {|
  +estimatedCosts: number[],
  +disabled: boolean,
  +loading: boolean,
  +postValidateCoupon: (params: any) => void
|}

export type ComponentProps = {
  +disabled: boolean,
  +couponName: string,
  +loading: boolean,
  +onApplyPromoCode: () => void,
  +onSetCouponName: (event: SyntheticKeyboardEvent<*>) => void
}

const DEFAULT_COUPON_NAME = ""

const usePrepareComponent = ({
  disabled,
  estimatedCosts,
  loading,
  postValidateCoupon
}: Props): ComponentProps => {
  const [couponName, setCouponName] = useState(DEFAULT_COUPON_NAME)
  const { id } = useParams()

  const onApplyPromoCode = () => {
    if (!couponName) return

    TagManager.dataLayer({
      dataLayer: {
        event: "applyPromo"
      }
    })

    postValidateCoupon({
      variables: {
        couponInput: {
          code: couponName,
          stops: [
            {
              items: estimatedCosts.map((estimatedCost) => ({ estimatedCost })),
              placeId: Number(id)
            }
          ]
        }
      }
    })
  }

  const onSetCouponName = (event: SyntheticKeyboardEvent<*>) => {
    setCouponName(event.currentTarget.value)
  }

  return {
    couponName,
    disabled,
    loading,
    onApplyPromoCode,
    onSetCouponName
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  PromoCode
)

// @flow
/* eslint-disable react/jsx-max-depth, no-ternary, max-lines-per-function */

import {
  Label,
  Left,
  Option,
  Price,
  Right,
  Text,
  Title,
  Wrapper
} from "./styled"
import Checkbox from "components/inputs/Checkbox"
import type { ComponentProps } from "."
import MenuItemOptionsGroupRadio from "@fetch/ui/MenuItemOptionsGroupRadio"
import React from "react"

const START = 0
const MAX_NAME_LENGTH = 100
const DIGITS = 2

const Box = ({
  isRadio,
  onRadioChange,
  onSelectAddon,
  options,
  selected,
  radioValue,
  title
}: ComponentProps) => (
  <Wrapper>
    {isRadio ? (
      <MenuItemOptionsGroupRadio
        label={title}
        name={title}
        onChange={onRadioChange}
        options={options}
        selected={radioValue?.[title]}
      />
    ) : (
      <div>
        <Title>
          {title}

          <Text>{` (select up to ${options.length})`}</Text>
        </Title>

        {options.map(({ id, name, price }) => (
          <Option key={name}>
            <Left onClick={onSelectAddon(id, false, title)}>
              <Checkbox isChecked={selected.includes(id)} />

              <Label>
                {name.length > MAX_NAME_LENGTH
                  ? `${name.substr(START, MAX_NAME_LENGTH)}...`
                  : name}
              </Label>
            </Left>

            <Right>
              <Price>{`+ $${price?.toFixed(DIGITS)}`}</Price>
            </Right>
          </Option>
        ))}
      </div>
    )}
  </Wrapper>
)

export default Box

// @flow
/* eslint-disable react/jsx-max-depth, max-lines-per-function */

import {
  ArrowAfter,
  ArrowBefore,
  Divider,
  Header,
  IconWrapper,
  Link,
  Navigation,
  NavigationItem,
  Wrapper
} from "./styled"
import { type ComponentProps } from "."
import DetailsModal from "./DetailsModal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Modal from "./Modal"
import React from "react"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

export const Options = ({
  disabled,
  isDetailsModalOpen,
  isModalOpen,
  isOpen,
  onCancelOrder,
  onClick,
  onToggleModal,
  onToggleDetailsModal
}: ComponentProps) => (
  <Wrapper isOpen={isOpen}>
    <ArrowBefore />

    <ArrowAfter />

    <Header>
      <IconWrapper onClick={onClick}>
        <FontAwesomeIcon icon={faTimes} />
      </IconWrapper>

      {"Fetch Actions"}
    </Header>

    <Divider />

    <Navigation>
      {/*
        <NavigationItem>
        <Link>
          {"View Map"}
        </Link>
        </NavigationItem>
      */}

      <NavigationItem>
        <Link onClick={onToggleDetailsModal({})}>{"View Details"}</Link>
      </NavigationItem>

      <NavigationItem>
        <Link $isDestructive disabled={!disabled} onClick={onCancelOrder}>
          {"Cancel Order"}
        </Link>

        <Modal isModalOpen={isModalOpen} onToggleModal={onToggleModal} />

        <DetailsModal
          isDetailsModalOpen={isDetailsModalOpen}
          onToggleDetailsModal={onToggleDetailsModal}
        />
      </NavigationItem>
    </Navigation>
  </Wrapper>
)

export default Options

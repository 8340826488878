// @flow

import styled, { type StyledComponent } from "styled-components"

export const Wrapper: StyledComponent<any, mixed, HTMLElement> = styled.div`
  display: flex;
  height: calc(100vh - 73px);
  flex-direction: column;
  align-items: center;
`

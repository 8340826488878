// @flow

import styled, { type StyledComponent } from "styled-components"
import colors from "styles/colors"
import mediaQueries from "styles/mediaQueries"

export const Wrapper: StyledComponent<any, mixed, HTMLElement> = styled.div`
  color: ${colors.white};
  font-family: Roboto, Arial, open-sans;
  width: 85%;
  padding-top: 70px;

  ${mediaQueries.tablet} {
    width: 600px;
  }
`

export const Container: StyledComponent<any, mixed, HTMLElement> = styled.div`
  color: #2e3333;
  margin: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: flex;
  flex-direction: column;
  padding: 32px;
  border-radius: 4px;
  position: relative;
  z-index: 102;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08);
  margin: 0 auto;
  padding-top: 0;

  ${mediaQueries.tablet} {
    width: 450px;
  }
`

export const Content: StyledComponent<any, mixed, HTMLElement> = styled.div`
  color: #2e3333;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: flex;
  align-items: flex-end;
`

export const InnerWrapper: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  color: #2e3333;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: flex;
  flex: 1;
  position: relative;
`

export const InputWrapper: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  color: #2e3333;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  flex: 1;
`

export const Label: StyledComponent<any, mixed, HTMLElement> = styled.label`
  visibility: hidden;
`

export const SearchBox: StyledComponent<any, mixed, HTMLElement> = styled.form`
  color: #2e3333;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: flex;
  position: relative;
`

export const SearchBoxWrapper: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  color: #2e3333;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  flex: 1;
  display: flex;
  position: relative;
`

export const Svg: StyledComponent<any, mixed, any> = styled.svg`
  cursor: pointer;
  font-size: 100%;
  font: inherit;
  width: 24px;
  height: 24px;
  color: #00ccbc;
`

export const SearchButtonWrapper: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  color: #2e3333;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  margin-left: 16px;
  position: absolute;
  bottom: 3px;
  right: 5px;
`

export const SearchButtonSpanWrapper: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.span`
  color: #2e3333;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
  width: 100%;
  box-sizing: border-box;
`

export const SearchButton: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.button`
  border: none;
  text-decoration: none;
  appearance: none;
  border-radius: 4px;
  font-weight: 600;
  font-family: plex-sans, sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  cursor: pointer;
  position: relative;
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 12px 30px;
  color: #fff;
  transition-property: background, box-shadow, -webkit-box-shadow;
  transition-duration: 0.15s;
  transition-timing-function: ease-out;
  background: ${colors.driftwood};
  border-radius: 50%;
  padding: 12px;

  &:hover {
    opacity: 0.8;
  }
`

export const SearchInnerSpan: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.span`
  cursor: pointer;
  color: #fff;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

export const Subtitle: StyledComponent<any, mixed, HTMLElement> = styled.h4`
  font-size: 26px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
`

export const IconWrapper: StyledComponent<any, mixed, HTMLElement> = styled.div`
  position: absolute;
  font-size: 21px;
  color: ${colors.charcoal};
  left: 17px;
  bottom: 10px;
`

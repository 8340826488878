// @flow

import DeliveryInstructions from "./DeliveryInstructions"

type Props = {|
  +deliveryInstructions?: string,
  +disabled: boolean,
  +onSetDeliveryInstructions: (ev: SyntheticInputEvent<EventTarget>) => void
|}

export type ComponentProps = {|
  ...Props
|}

export default DeliveryInstructions

// @flow
/* eslint-disable max-lines-per-function */

import { useEffect, useMemo } from "react"
import DetailsModal from "./DetailsModal"
import GET_FETCH from "queries/getFetch"
import prepareComponent from "hocs/prepareComponent"
import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"

type Props = {
  +isDetailsModalOpen: boolean,
  +onToggleDetailsModal: (options: any) => () => void
}

export type ComponentProps = {
  +estimates: {
    deliveryFee?: number | null,
    estimatedSubtotal?: number | null,
    estimatedTotalCharge?: number | null,
    serviceFee?: number | null
  },
  +isDetailsModalOpen: boolean,
  +lastStopIndex: number,
  +driverName?: string,
  +onToggleDetailsModal: (options: any) => () => void,
  +purchases: any[],
  +state?: string,
  +stops?: {
    [key: string]: {
      +name: string,
      +state?: string | null,
      +items: any[] | null,
      +instructions: string | null
    }
  }[]
}

const FETCH_INTERVAL = 10000
const DEFAULT_FETCH = {}
const ONE = 1

const usePrepareComponent = ({
  isDetailsModalOpen,
  onToggleDetailsModal
}: Props): ComponentProps => {
  const { id } = useParams()
  const {
    data,
    error,
    loading,
    refetch: fetchRefetch
  } = useQuery(GET_FETCH, {
    variables: { id: Number(id) }
  })

  useEffect(() => {
    // Get Fetch data on interval
    const intervalId = setInterval(() => {
      fetchRefetch()
    }, FETCH_INTERVAL)

    return () => {
      clearInterval(intervalId)
    }
  }, [fetchRefetch])

  const {
    deliveryFee,
    estimatedItemCost,
    estimatedTotalCharge,
    serviceFee,
    driver,
    purchases,
    stops
  } = useMemo(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.log(error.message)
      return DEFAULT_FETCH
    }

    if (loading || !data) {
      return DEFAULT_FETCH
    }

    return data.fetch
  }, [data, error, loading])

  return {
    driverName: driver?.user.firstName,
    estimates: {
      deliveryFee,
      estimatedSubtotal: estimatedItemCost,
      estimatedTotalCharge,
      serviceFee
    },
    isDetailsModalOpen,
    lastStopIndex: stops?.length - ONE || ONE,
    onToggleDetailsModal,
    purchases,
    stops
  }
}

export default prepareComponent(usePrepareComponent)(DetailsModal)

// @flow

import Modal from "./Modal"

export type ComponentProps = {
  +isModalOpen: boolean,
  +onSubmit: (confirmed: boolean) => void,
  +onToggleModal: (options: any) => () => void
}

export default Modal

// @flow

import { useCallback, useEffect, useMemo } from "react"
import ImageSearch from "./ImageSearch"
import SEARCH_IMAGES from "queries/searchImages"
import type { WebImage } from "@fetch/frontend"
import prepareComponent from "hocs/prepareComponent"
import { useLazyQuery } from "@apollo/client"

export type ComponentProps = {
  +imageSearch: string,
  +images: WebImage[],
  +onChange: (event: SyntheticInputEvent<EventTarget>) => void,
  +onClickImage: (thumbnailUrl: string) => void,
  +setImageSearch: (imageSearch: string) => void
}

const DEFAULT_IMAGES = []

const usePrepareComponent = ({
  imageSearch,
  onCloseModal,
  onSelectImage,
  setImageSearch
}) => {
  const [getImages, { data, error, loading }] = useLazyQuery(SEARCH_IMAGES)

  useEffect(() => {
    if (imageSearch) {
      getImages({
        variables: { search: imageSearch }
      })
    }
  }, [imageSearch, getImages])

  const images = useMemo(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.log(error.message)
      return DEFAULT_IMAGES
    }

    if (loading || !data) {
      return DEFAULT_IMAGES
    }

    return data.searchImages
  }, [data, loading, error])

  const onClickImage = useCallback(
    (image: string) => () => {
      onSelectImage(image)
      onCloseModal()
    },
    [onCloseModal, onSelectImage]
  )

  const onChange = useCallback(
    (event) => {
      setImageSearch(event.currentTarget.value)
    },
    [setImageSearch]
  )

  return { imageSearch, images, onChange, onClickImage, setImageSearch }
}

export default prepareComponent(usePrepareComponent)(ImageSearch)

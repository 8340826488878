// @flow

import GET_LAST_4 from "queries/getLast4"
import { useMemo } from "react"
import { useQuery } from "@apollo/client"

const DEFAULT_LAST_4 = ""

const useLast4 = (): {|
  +called: boolean,
  +data?: {|
    last4: string
  |},
  +error: Error,
  +last4: string,
  +loading: boolean
|} => {
  const { called, data, error, loading } = useQuery(GET_LAST_4, {
    fetchPolicy: "network-only"
  })

  const last4 = useMemo(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.log(error.message)
      return DEFAULT_LAST_4
    }

    if (loading || !data) {
      return DEFAULT_LAST_4
    }

    return data.last4
  }, [data, error, loading])

  if (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }

  return {
    called,
    data,
    error,
    last4,
    loading
  }
}

export default useLast4

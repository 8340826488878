// @flow

import { Circle, Wrapper } from "./styled"
import { type ComponentProps } from "."
import Options from "./Options"
import React from "react"

const Menu = ({ disabled, onClick, isOpen }: ComponentProps) => (
  <>
    <Wrapper onClick={onClick}>
      <Circle />

      <Circle />

      <Circle />
    </Wrapper>

    <Options disabled={disabled} isOpen={isOpen} onClick={onClick} />
  </>
)

export default Menu

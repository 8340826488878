// @flow
/* eslint-disable max-statements, max-lines-per-function */

import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useMutation, useQuery } from "@apollo/client"
import COMPLETE_ORDER from "mutations/completeOrder"
import GET_FETCH from "queries/getFetch"
import TagManager from "react-gtm-module"
import Tip from "./Tip"
import compose from "ramda/src/compose"
import map from "ramda/src/map"
import prepareComponent from "hocs/prepareComponent"
import useUser from "hooks/useUser"

type TipType = {|
  +label: string,
  +value: number
|}

export type ComponentProps = {|
  +activeTip: number,
  +customTip: number,
  +driverName: string,
  +id: string,
  +isModalOpen: boolean,
  +onChangeActiveTip: any,
  +onChangeRating: any,
  +onChangeCustomTip: any,
  +onCloseTipBox: any,
  +onSubmit: any,
  +onToggleModal: any,
  +rating: number,
  +tips: TipType[],
  +total: string
|}

const DEFAULT_IS_MODAL_OPEN = false
const DEFAULT_ACTIVE_TIP = 0
const DEFAULT_RATING = 0
const DEFAULT_ESTIMATED_TOTAL_CHARGE = 0
const ZERO = 0

const BASE_PRICE = 100
const DEFAULT_CUSTOM_TIP = null
const MINIMAL_TIP = 300

// eslint-disable-next-line no-magic-numbers
const DEFAULT_TIPS = [300, 700, 1500]

const usePrepareComponent = () => {
  const [activeTip, setActiveTip] = useState(DEFAULT_ACTIVE_TIP)
  const [rating, setRating] = useState(DEFAULT_RATING)
  const { id } = useParams()
  const history = useHistory()
  const [completeOrder, { data: completeOrderData }] =
    useMutation(COMPLETE_ORDER)
  const { data: fetchData } = useQuery(GET_FETCH, {
    variables: { id: Number(id) }
  })
  const [customTip, setCustomTip] = useState(DEFAULT_CUSTOM_TIP)
  const [isModalOpen, setIsModalOpen] = useState(DEFAULT_IS_MODAL_OPEN)
  const { user } = useUser()

  const DECIMAL_POINT = 2

  const tips = useMemo((): TipType[] => {
    if (!fetchData) {
      return []
    }

    // eslint-disable-next-line no-magic-numbers
    let percentages = [5, 10, 15]

    if (fetchData.fetch.estimatedItemCost) {
      // eslint-disable-next-line no-magic-numbers
      percentages = [10, 15, 20]
    }

    let mapFunction = map((percentage) =>
      Math.ceil(
        (fetchData.fetch.estimatedTotalCharge * percentage) / BASE_PRICE
      )
    )

    if (
      // eslint-disable-next-line no-magic-numbers
      (fetchData.fetch.estimatedTotalCharge * percentages[0]) / BASE_PRICE <
      MINIMAL_TIP
    ) {
      // eslint-disable-next-line no-magic-numbers
      mapFunction = () => DEFAULT_TIPS
    }

    return compose(
      // eslint-disable-next-line no-magic-numbers
      map((value) => ({ label: `$${(value / BASE_PRICE).toFixed(2)}`, value })),
      mapFunction
    )(percentages)
  }, [fetchData])

  const driverName = useMemo(
    () => fetchData?.fetch.driver.user.firstName ?? "",
    [fetchData]
  )

  const estimatedTotalCharge = useMemo(
    () =>
      fetchData?.fetch.estimatedTotalCharge ?? DEFAULT_ESTIMATED_TOTAL_CHARGE,
    [fetchData]
  )

  const onChangeActiveTip = (tip: number) => () => {
    if (tip === activeTip && !customTip) {
      setActiveTip(DEFAULT_ACTIVE_TIP)
      return
    }

    setActiveTip(tip)
    setCustomTip(DEFAULT_CUSTOM_TIP)
  }

  const onChangeCustomTip = (ev) => {
    ev.preventDefault()
    setCustomTip(ev.target.value)
  }

  const onCloseTipBox = (setIsOpen: any) => (ev: SyntheticEvent<any>) => {
    ev.preventDefault()
    setCustomTip(DEFAULT_CUSTOM_TIP)
    setActiveTip(DEFAULT_ACTIVE_TIP)
    setIsOpen(false)
  }

  const onChangeRating = (newRating: number) => () => {
    setRating(newRating)
  }

  // 'confirmed' argument differentiate onSubmit from page and from modal
  const onSubmit =
    (confirmed = false) =>
    (event: SyntheticEvent<any>) => {
      event.preventDefault()

      if (activeTip === ZERO && confirmed === false) {
        setIsModalOpen(true)
        return
      }

      if (activeTip) {
        TagManager.dataLayer({
          dataLayer: {
            event: "tip",
            user
          }
        })
      }

      if (rating) {
        TagManager.dataLayer({
          dataLayer: {
            event: "rate",
            user
          }
        })
      }

      completeOrder({
        variables: { completeOrderInput: { id, tip: activeTip } }
      })
    }

  useEffect(() => {
    if (completeOrderData) {
      history.push("/")
    }
  }, [completeOrderData, history])

  const onToggleModal = useCallback(
    () => () => {
      setIsModalOpen(!isModalOpen)
    },
    [isModalOpen, setIsModalOpen]
  )

  return {
    activeTip,
    customTip,
    driverName,
    id,
    isModalOpen,
    onChangeActiveTip,
    onChangeCustomTip,
    onChangeRating,
    onCloseTipBox,
    onSubmit,
    onToggleModal,
    rating,
    tips,
    total: ((estimatedTotalCharge + activeTip) / BASE_PRICE).toFixed(
      DECIMAL_POINT
    )
  }
}

export default prepareComponent(usePrepareComponent)(Tip)

// @flow

const recurring = {
  LABELS_MAP: {
    every2Weeks: "Every 2 Weeks",
    every4Weeks: "Every 4 Weeks",
    everyDay: "Every Day",
    everyWeek: "Every Week"
  }
}

export default recurring

// @flow

import OrderSummary from "./OrderSummary"

export type ComponentProps = {|
  +estimates: {
    deliveryFee?: number | null,
    estimatedSubtotal?: number | null,
    estimatedTotalCharge?: number | null,
    serviceFee?: number | null
  }
|}

export default OrderSummary

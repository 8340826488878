// @flow

import gql from "graphql-tag"

const SEARCH_IMAGES = gql`
  query searchImages($search: String!) {
    searchImages(search: $search) {
      thumbnailUrl
      url
    }
  }
`

export default SEARCH_IMAGES

// @flow

import styled, { type StyledComponent } from "styled-components"
import colors from "styles/colors"
import mediaQueries from "styles/mediaQueries"

export const Wrapper: StyledComponent<any, mixed, HTMLElement> = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding: 10px;
  position: absolute;
  right: 30px;
  top: 40px;

  &:hover {
    opacity: 0.8;
  }

  ${mediaQueries.desktop} {
    right: 50px;
    top: 30px;
  }
`

export const Circle: StyledComponent<any, mixed, HTMLElement> = styled.div`
  width: 6px;
  height: 6px;
  margin: 1.5px;
  background: ${colors.black};
  border-radius: 50%;
  display: block;
`

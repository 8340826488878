// @flow

import gql from "graphql-tag"

const SUBSCRIBE = gql`
  mutation subscribe {
    subscribe {
      id
      subscribedOn
      subscriptionId
      unsubscribedOn
      userId
    }
  }
`

export default SUBSCRIBE

// @flow
/* eslint-disable max-lines-per-function, react/jsx-max-depth, no-ternary */

import {
  Box,
  BoxPromoCode,
  ButtonWrapper,
  Cards,
  Container,
  CrossWrapper,
  OrderHeader,
  ShowSidebar,
  ShowSidebarButton,
  SidebarWrapper,
  Wrapper
} from "./styled"
import Button from "@fetch/ui/Button"
import type { ComponentProps } from "."
import Cross from "components/icons/Cross"
import DeliveryInstructions from "./DeliveryInstructions"
import DeliveryRoute from "./DeliveryRoute"
import Items from "./Items"
import { ReactComponent as LeftCaret } from "assets/icons/LeftCaret.svg"
import Modal from "./Modal"
import OrderSummary from "./OrderSummary"
import Payment from "./Payment"
import PromoCode from "./PromoCode"
import React from "react"

const Sidebar = ({
  checkoutLabel,
  couponEstimatedCosts,
  deliveryInstructions,
  estimatedSubtotal,
  estimates,
  isButtonsDisabled,
  isCheckoutButtonDisabled,
  isFetchLoading,
  isFetchScheduled,
  isLater,
  isLoggedIn,
  isModalOpen,
  isScheduledFetchLoading,
  onAddStop,
  onDeleteItem,
  onDeleteStop,
  onLogin,
  onPostFetch,
  onResetFetchState,
  onSetDeliveryInstructions,
  onToggleModal,
  onToggleSidebar,
  postValidateCoupon,
  selectDeliveryTimeLabel,
  showSidebar,
  stops,
  validateCouponLoading,
  validateFetchLoading
}: ComponentProps) => (
  <Wrapper showSidebar={showSidebar}>
    <Container>
      <ShowSidebar onClick={onToggleSidebar} showSidebar={showSidebar}>
        <ShowSidebarButton>
          <LeftCaret />
          {"Your order"}
        </ShowSidebarButton>
      </ShowSidebar>

      <Cards showSidebar={showSidebar}>
        <SidebarWrapper>
          {isLoggedIn && (
            <OrderHeader>
              <CrossWrapper>
                <Cross onClick={onToggleSidebar} />
              </CrossWrapper>
              {"Your order"}
            </OrderHeader>
          )}

          {isLoggedIn && <DeliveryRoute stops={stops} />}

          {isLoggedIn && (
            <Items
              onDeleteItem={onDeleteItem}
              onDeleteStop={onDeleteStop}
              stops={stops}
            />
          )}

          {isLoggedIn && (
            <OrderSummary
              estimatedSubtotal={estimatedSubtotal}
              estimates={estimates}
              loading={validateFetchLoading}
            />
          )}

          {isLoggedIn && (
            <DeliveryInstructions
              deliveryInstructions={deliveryInstructions}
              disabled={isButtonsDisabled}
              onSetDeliveryInstructions={onSetDeliveryInstructions}
            />
          )}

          {isLoggedIn && (
            <Box>
              <Button
                disabled={isButtonsDisabled}
                expanded
                label="Add a stop"
                onClick={onAddStop}
              />
            </Box>
          )}

          {isLoggedIn && (
            <BoxPromoCode>
              <PromoCode
                disabled={isButtonsDisabled}
                estimatedCosts={couponEstimatedCosts}
                loading={validateCouponLoading}
                postValidateCoupon={postValidateCoupon}
              />
            </BoxPromoCode>
          )}

          {isLoggedIn && (
            <Box>
              <Payment />
            </Box>
          )}
        </SidebarWrapper>

        <ButtonWrapper>
          <Button
            bold
            disabled={isCheckoutButtonDisabled || isButtonsDisabled}
            expanded
            label={checkoutLabel}
            loading={isFetchLoading || isScheduledFetchLoading}
            onClick={isLoggedIn ? onPostFetch : onLogin}
            small={isLater}
          />
          {isLoggedIn && (
            <Button
              disabled={isButtonsDisabled}
              expanded
              label={selectDeliveryTimeLabel}
              onClick={onToggleModal}
              outlined
            />
          )}
        </ButtonWrapper>

        <Modal
          checkoutButton={
            <Button
              bold
              disabled={isButtonsDisabled}
              expanded
              label={checkoutLabel}
              loading={isFetchLoading || isScheduledFetchLoading}
              onClick={isLoggedIn ? onPostFetch : onLogin}
              small={isLater}
            />
          }
          isFetchScheduled={isFetchScheduled}
          isModalOpen={isModalOpen}
          onResetFetchState={onResetFetchState}
          onToggleModal={onToggleModal}
        />
      </Cards>
    </Container>
  </Wrapper>
)

export default Sidebar

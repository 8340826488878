// @flow

import type { FetchStop } from "@fetch/frontend"
import Items from "./Items"
import prepareComponent from "@bluframe/grapple/prepareComponent"
import { useParams } from "react-router-dom"

export type Props = {
  +onDeleteItem: (index: number, id: string) => () => void,
  +onDeleteStop: (index: number) => () => void,
  +stops: FetchStop[]
}

export type ComponentProps = {
  ...Props,
  +placeId: ?string
}

const usePrepareComponent = (props: Props): ComponentProps => {
  const { id } = useParams()

  return {
    ...props,
    placeId: id
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Items
)

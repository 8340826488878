// @flow

import styled, { type StyledComponent } from "styled-components"
import mediaQueries from "styles/mediaQueries"

export const Grid: StyledComponent<any, mixed, HTMLElement> = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  ${mediaQueries.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 40px;
  }

  @media only screen and (min-width: 1024px) {
  }
`

export const Form: StyledComponent<any, mixed, HTMLElement> = styled.form``

export const NotFound: StyledComponent<any, mixed, HTMLElement> = styled.h2`
  font-size: 20px;
  margin-top: 40px;
  text-align: center;
`

export const LoadingWrapper: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  display: flex;
  justify-content: center;
`

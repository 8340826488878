// @flow
/* eslint-disable no-ternary */

import styled, { type StyledComponent } from "styled-components"
import colors from "styles/colors"

export const MenuItem: StyledComponent<any, mixed, HTMLElement> = styled.div`
  border-top: 1px solid ${colors.bonJour};
  color: ${colors.doveGray};
  font-family: Roboto, Arial, open-sans;
  font-size: 14px;
  letter-spacing: 0px;
  padding: 10px 0;
  text-align: left;

  svg {
    padding-right: 5px;
  }
`

export const MenuItemName: StyledComponent<any, mixed, HTMLElement> = styled.p`
  margin: 0;
`

export const MenuItemPrice: StyledComponent<any, mixed, HTMLElement> = styled.p`
  color: ${colors.outerSpace};
  margin: 0;
`

export const Addons: StyledComponent<any, mixed, HTMLElement> = styled.ul`
  font-size: 12px;
  margin-top: 5px;
  padding-inline-start: 20px;
  margin-right: 45px;
`

export const AddonItem: StyledComponent<any, mixed, HTMLElement> = styled.div``

export const ListItem: StyledComponent<any, mixed, HTMLElement> = styled.li`
  list-style: none;
  display: flex;
  justify-content: space-between;
`

export const Container: StyledComponent<any, mixed, HTMLElement> = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Instructions: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  font-size: 12px;
  padding-left: 10px;
  font-style: italic;
`

export const IconsWrapper: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  font-size: 16px;
  position: absolute;
  right: -11px;
  top: 0;
  margin-right: 5px;

  & button {
    &:hover {
      cursor: pointer;
    }
  }

  & svg {
    color: ${colors.boulder};
    height: 18px;
    width: 18px !important;

    &:hover {
      cursor: pointer;
      color: ${colors.charcoal};
    }

    &:nth-of-type(1) {
      margin-right: 5px;
    }
  }
`

export const RightSide: StyledComponent<any, mixed, HTMLElement> = styled.div`
  display: flex;
`

export const Icons: StyledComponent<any, mixed, HTMLElement> = styled.div`
  position: relative;
  width: 45px;
`

export const Wrapper: StyledComponent<any, mixed, HTMLElement> = styled.div`
  background: ${colors.white} 0% 0% no-repeat padding-box;
  padding: 20px;
  box-shadow: 0px 12px 16px ${colors.blackRussian};
`

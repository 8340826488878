// @flow
/* eslint-disable no-ternary */

import styled, { type StyledComponent } from "styled-components"
import type { Theme } from "@bluframe/blublocks"
import colors from "styles/colors"
import mediaQueries from "@bluframe/blublocks/mediaQueries"

export const CategoryName: StyledComponent<
  {||},
  Theme,
  HTMLElement
> = styled.h2`
  color: ${colors.thunder};
  font-family: Roboto, Arial, open-sans;
  font-weight: 600;
  margin: 20px 0;
  padding: 0;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin-left: 10px;
  }

  ${mediaQueries.desktop} {
    font-size: 20px;
    padding: 0;
    text-align: left;
  }
`

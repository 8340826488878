// @flow
/* eslint-disable react/destructuring-assignment */

import React, { type ElementConfig } from "react"
import { Link } from "react-scroll"
import { components } from "react-select"

export const Option = (props: ElementConfig<typeof components.Option>) => (
  <Link duration={1000} smooth to={props.data.value}>
    <components.Option {...props}>{props.data.label}</components.Option>
  </Link>
)

export default Option

// @flow

import {
  Button,
  Form,
  MiddleText,
  PhoneInput,
  Subtitle,
  Text,
  Wrapper
} from "./styled"
import { type ComponentProps } from "."
import React from "react"

const PhoneLogin = ({ register, handleSubmit, onSubmit }: ComponentProps) => (
  <Wrapper>
    <Subtitle>{`What's your phone number?`}</Subtitle>

    <Text>{`We'll text a code to verify your phone`}</Text>

    <Form onSubmit={handleSubmit(onSubmit)}>
      <PhoneInput
        name="phoneNumber"
        placeholder="(555) 555-5555"
        ref={register}
        type="tel"
      />

      <Button type="submit">{`Submit`}</Button>
    </Form>

    <MiddleText>{`or `}</MiddleText>
  </Wrapper>
)

export default PhoneLogin

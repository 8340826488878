// @flow
/* eslint-disable max-lines-per-function, max-statements, react/hook-use-state */

import type {
  DeliveryTimeNowOrLater,
  DeliveryTimeOneOffOrRecurring,
  DeliveryTimeRecurring
} from "@fetch/frontend"
import type { Option, OptionValue } from "@bluframe/blublocks"
import { useMemo, useState } from "react"
import SelectADeliveryTime from "./SelectADeliveryTime"
import { prepareComponent } from "@bluframe/grapple"
import useDeliveryTime from "hooks/useDeliveryTime"
import useIsScheduledOrderOnly from "hooks/useIsScheduledOrderOnly"

type Props = {|
  +checkoutButton: React$Node,
  +onCancel: () => void,
  +onSelect: () => void
|}

export type ComponentProps = {|
  ...Props,
  +date: string | null,
  +isActionDisabled: boolean,
  +isScheduledOrderOnly: boolean,
  +nowOrLater: DeliveryTimeNowOrLater,
  +nowOrLaterOptions: Option[],
  +onChangeNowOrLater: (value: OptionValue) => void,
  +onChangeOneOffOrRecurring: (value: OptionValue) => void,
  +onChangeRecurring: (value: OptionValue) => void,
  +onSelect: () => void,
  +onSelectDate: (date: number | string | null) => void,
  +onSelectTime: (time: number | string | null) => void,
  +oneOffOrRecurring: DeliveryTimeOneOffOrRecurring,
  +oneOffOrRecurringOptions: Option[],
  +recurring: DeliveryTimeRecurring,
  +recurringOptions: Option[],
  +time: string | null
|}

/**
 * `usePrepareComponent` is a custom hook that prepares the props for the `SelectADeliveryTime` component.
 * It provides the values and handlers for SelectADeliveryTime component for managing the state of deliveryTime.
 *
 * @param {Props} props - The props provided to the component
 * @returns {ComponentProps} - The prepared component props including values and handlers for different states
 */
const usePrepareComponent = (props: Props): ComponentProps => {
  const {
    deliveryTime: state,
    onSetDeliveryDate,
    onSetDeliveryTime,
    onSetNowOrLater,
    onSetOneOffOrRecurring,
    onSetRecurring
  } = useDeliveryTime()
  const { isScheduledOrderOnly } = useIsScheduledOrderOnly()

  // State to store the previous values of deliveryDate, deliveryTime,
  // nowOrLater, oneOffOrRecurring and recurring
  const [previousDate] = useState<string | null>(state.deliveryDate)
  const [previousTime] = useState<string | null>(state.deliveryTime)
  const [previousNowOrLater] = useState<DeliveryTimeNowOrLater>(
    state.nowOrLater
  )
  const [previousOneOffOrRecurring] = useState<DeliveryTimeOneOffOrRecurring>(
    state.oneOffOrRecurring
  )
  const [previousRecurring] = useState<DeliveryTimeRecurring>(state.recurring)

  // Handler for when a delivery time is selected
  const onSelect = () => {
    props.onSelect()
  }

  // Handler for when the selection is cancelled
  const onCancel = () => {
    // Restore the previous values of deliveryDate, deliveryTime and nowOrLater
    onSetDeliveryDate(previousDate)
    onSetDeliveryTime(previousTime)
    onSetNowOrLater(previousNowOrLater)
    onSetOneOffOrRecurring(previousOneOffOrRecurring)
    onSetRecurring(previousRecurring)

    // Call the provided onCancel prop
    props.onCancel()
  }

  // Array containing options for 'now' or 'later' delivery time
  const nowOrLaterOptions = [
    { label: "Now", value: "now" },
    { label: "Later", value: "later" }
  ]

  // Array containing options for 'one-off' or 'recurring' delivery time
  const oneOffOrRecurringOptions = [
    { label: "One-off", value: "oneOff" },
    { label: "Recurring", value: "recurring" }
  ]

  // Array containing options for 'recurring' delivery times
  const recurringOptions = [
    { label: "Every day", value: "everyDay" },
    { label: "Every week", value: "everyWeek" },
    { label: "Every 2 weeks", value: "every2Weeks" },
    { label: "Every 4 weeks", value: "every4Weeks" }
  ]

  // Handler for when a delivery date is selected
  const onSelectDate = (value: number | string | null) => {
    onSetDeliveryDate(value)
  }

  // Handler for when a time for delivery is selected
  const onSelectTime = (value: number | string | null) => {
    onSetDeliveryTime(value)
  }

  // Handler for when the 'now' or 'later' option is changed
  const onChangeNowOrLater = (value: OptionValue) => {
    if (value === "now" || value === "later") {
      onSetNowOrLater(value)
    }
  }

  // Handler for when the 'one-off' or 'recurring' option is changed
  const onChangeOneOffOrRecurring = (value: OptionValue) => {
    if (value === "oneOff" || value === "recurring") {
      onSetOneOffOrRecurring(value)
    }
  }

  // Handler for when the 'recurring' option is changed
  const onChangeRecurring = (value: OptionValue) => {
    if (
      value === "everyDay" ||
      value === "everyWeek" ||
      value === "every2Weeks" ||
      value === "every4Weeks"
    ) {
      onSetRecurring(value)
    }
  }

  // Compute whether the action is disabled based on the state values
  const isActionDisabled = useMemo(() => {
    if (state.nowOrLater === "now") {
      return false
    }

    return !state.deliveryDate || !state.deliveryTime
  }, [state])

  return {
    checkoutButton: props.checkoutButton,
    date: state.deliveryDate,
    isActionDisabled,
    isScheduledOrderOnly,
    nowOrLater: state.nowOrLater,
    nowOrLaterOptions,
    onCancel,
    onChangeNowOrLater,
    onChangeOneOffOrRecurring,
    onChangeRecurring,
    onSelect,
    onSelectDate,
    onSelectTime,
    oneOffOrRecurring: state.oneOffOrRecurring,
    oneOffOrRecurringOptions,
    recurring: state.recurring,
    recurringOptions,
    time: state.deliveryTime
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  SelectADeliveryTime
)

// @flow

import { Indicator, InnerWrapper, Wrapper } from "./styled"
import { ReactComponent as Caret } from "assets/icons/LeftCaret.svg"
import { type ComponentProps } from "."
import React from "react"

const Carousel = ({
  carouselRef,
  children,
  onLeftClick,
  onRightClick
}: ComponentProps) => (
  <Wrapper>
    <Indicator onClick={onLeftClick}>
      <Caret />
    </Indicator>

    <InnerWrapper ref={carouselRef}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { width: "100%" })
      )}
    </InnerWrapper>

    <Indicator onClick={onRightClick} right>
      <Caret />
    </Indicator>
  </Wrapper>
)

export default Carousel

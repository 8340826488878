// @flow

const googleTagManager = {
  ID: "GTM-WNBXBRF",
  environments: {
    development: {
      GTM_AUTH_DEVELOPMENT: "6IoyTBp4_JiglUvU9105oQ",
      GTM_PREVIEW_DEVELOPMENT: "env-6"
    },
    test: {
      GTM_AUTH_DEVELOPMENT: "UZ-atkKnAfkBI5m932tdwg",
      GTM_PREVIEW_DEVELOPMENT: "env-7"
    }
  }
}

export default googleTagManager

// @flow

import type { ComponentProps } from "."
import React from "react"
import { Navigation as UINavigation } from "@fetch/ui"

const Navigation = ({
  dropOffAddress,
  onLogin,
  onLogout,
  onSetUser,
  user
}: ComponentProps) => (
  <UINavigation
    dropOffAddress={dropOffAddress}
    onBeforeLogin={onLogin}
    onBeforeLogout={onLogout}
    onSetUser={onSetUser}
    user={user}
  />
)

export default Navigation

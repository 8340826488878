// @flow
/* eslint-disable no-ternary */

import styled, { type StyledComponent } from "styled-components"
import colors from "styles/colors"

export const Wrapper: StyledComponent<any, mixed, HTMLElement> = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 40px 0;
`

export const Title: StyledComponent<any, mixed, HTMLElement> = styled.h2`
  color: ${colors.mineShaft};
  font-family: Roboto, Arial, open-sans;
  font-size: 28px;
  margin: 0;
  text-align: center;
`

export const TipsBox: StyledComponent<any, mixed, HTMLElement> = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-top: 40px;
`

export const TipItem: StyledComponent<any, mixed, HTMLElement> = styled.div`
  align-items: center;
  background: ${({ isActive }) => (isActive ? colors.driftwood : colors.white)};
  border: 2px solid ${colors.driftwood};
  color: ${({ isActive }) => (isActive ? colors.white : colors.driftwood)};
  cursor: pointer;
  display: flex;
  font-family: Roboto, Arial, open-sans;
  font-size: 20px;
  font-weight: 700;
  justify-content: center;
  padding: 20px 30px;

  &:first-of-type {
    border-right: 0;
  }

  &:last-of-type {
    border-left: 0;
  }
`

export const Form: StyledComponent<any, mixed, HTMLElement> = styled.form`
  align-items: center;
  background: ${colors.white};
  border-top: 1px solid ${colors.bonJour};
  bottom: 0;
  display: flex;
  height: 60px;
  left: 0;
  padding: 0 40px;
  position: fixed;
  width: calc(100vw - 80px);
`

export const Custom: StyledComponent<any, mixed, HTMLElement> = styled.p`
  color: ${colors.driftwood};
  font-family: Roboto, Arial, open-sans;
`

export const CustomSubtitle: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.p`
  color: ${colors.codGray};
  font-family: Roboto, Arial, open-sans;
  margin: 0;
`

export const RatingSubtitle: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.p`
  color: ${colors.codGray};
  font-family: Roboto, Arial, open-sans;
  margin: 0;
`

export const InputTipWrapper: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  display: flex;
  font-family: Roboto;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 40px;
`

export const InputTip: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.input.attrs({ type: "text" })`
  border: 0;
  font-family: Roboto;
  font-size: 30px;
  font-weight: 700;
  outline: none;
  text-align: center;
  width: 200px;
`

export const EstimatedTotal: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.p`
  font-family: Roboto;
  font-size: 30px;
  font-weight: 700;
  outline: none;
  text-align: center;
`

export const EstimatedTotalCaption: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.p`
  font-family: Roboto;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  outline: none;
  text-align: center;
  margin-top: -20px;
  margin-bottom: 20px;
`

export const ThankYou: StyledComponent<any, mixed, HTMLElement> = styled.p`
  font-family: Roboto;
  font-size: 40px;
  font-weight: 700;
`

const Button = styled.input.attrs({ type: "submit" })`
  background: ${colors.thunder} 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 3px;
  color: ${colors.white};
  cursor: pointer;
  font-family: Roboto, Arial, open-sans;
  font-size: 20px;
  height: 45px;
  letter-spacing: 0.27px;
  text-align: center;
`

export const SubmitButton: StyledComponent<
  any,
  mixed,
  StyledComponent<any, mixed, HTMLElement>
> = styled(Button)`
  background: ${colors.thunder} 0% 0% no-repeat;
  flex: 1 0 auto;
  width: 300px;
`

export const Rating: StyledComponent<any, mixed, HTMLElement> = styled.div`
  display: flex;
  margin: 40px 0;

  & svg {
    color: ${colors.driftwood};
    cursor: pointer;
    font-size: 40px;
  }
`

// @flow
/* eslint-disable no-constant-binary-expression */

import { Card, CardTitle, ItemsWrapper, TextArea } from "./styled"
import { type ComponentProps } from "."
import React from "react"

const DeliveryInstructions = ({
  deliveryInstructions,
  disabled,
  onSetDeliveryInstructions
}: ComponentProps) => (
  <Card>
    {false && <CardTitle>{"Delivery Instructions"}</CardTitle>}

    <ItemsWrapper>
      <TextArea
        disabled={disabled}
        onChange={onSetDeliveryInstructions}
        placeholder="Additional instructions or needs?"
        value={deliveryInstructions}
      />
    </ItemsWrapper>
  </Card>
)

export default DeliveryInstructions

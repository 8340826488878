// @flow

import errorModalButtons, { ERROR_PAYMENT } from "data/errorModalButtons"
import Payment from "./Payment"
import prepareComponent from "hocs/prepareComponent"
import useLast4 from "hooks/useLast4"
import useModal from "hooks/useModal"
import useUser from "hooks/useUser"

export type ComponentProps = {|
  +last4?: string
|}

const usePrepareComponent = (): ComponentProps => {
  const { called, data, last4, loading } = useLast4()
  const { user } = useUser()

  // Global Modal
  const { onSetModal } = useModal()

  if (user && user.phoneNumber && called && !loading && !data?.last4) {
    onSetModal({
      content: "",
      isOpen: true,
      title: "Please select a payment method",
      ...errorModalButtons[ERROR_PAYMENT]
    })
  }

  return {
    last4
  }
}

export default prepareComponent<any, ComponentProps>(usePrepareComponent)(
  Payment
)

// @flow

import styled, { type StyledComponent } from "styled-components"
import colors from "styles/colors"

export const Wrapper: StyledComponent<any, mixed, HTMLElement> = styled.div`
  display: flex;
  margin: 40px auto 0;
  justify-content: center;
`

export const Display: StyledComponent<any, mixed, HTMLElement> = styled.span`
  display: flex;
  justify-content: center;
  width: 70px;
  max-height: 40px;
  background-size: cover;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
`

export const LeftButton: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.button`
  width: 40px;
  height: 40px;
  border: 1px solid ${colors.charcoal};
  background-color: ${colors.charcoal};
  background-size: cover;
  cursor: pointer;
  transition: 0.5s;
  border-radius: 20%;
  color: ${colors.white};

  &:focus {
    outline: none;
  }
`

export const RightButton: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 20%;
  border: 1px solid ${colors.charcoal};
  background-color: ${colors.charcoal};
  background-size: cover;
  cursor: pointer;
  transition: 0.5s;
  color: ${colors.white};

  &:focus {
    outline: none;
  }
`

// @flow
/* eslint-disable no-ternary */

import styled, { type StyledComponent } from "styled-components"
import colors from "styles/colors"
import mediaQueries from "styles/mediaQueries"
import { rgba } from "polished"

const ALPHA = 0.3

export const Wrapper: StyledComponent<any, mixed, HTMLElement> = styled.div`
  position: relative;
  border-radius: 7px;
  width: 100%;

  & svg {
    color: ${colors.doveGray};
    left: 14px;
    position: absolute;
    top: 12px;
  }

  ${mediaQueries.desktop} {
    margin-right: 15px;
  }
`

export const Input: StyledComponent<any, mixed, HTMLElement> = styled.input`
  border: 0.5px solid ${colors.mercury};
  border-radius: 3px;
  box-shadow: 0px 0px 3px ${rgba(colors.black, ALPHA)};
  height: 41px;
  color: ${colors.doveGray};
  width: 100%;
  padding: 0 30px 0 40px;
  margin-bottom: 0;

  &::placeholder {
    color: ${colors.doveGray};
  }
`

// @flow

import gql from "graphql-tag"

const GET_STRIPE_KEYS = gql`
  query getStripeKeys {
    stripeKeys {
      customerId
      key {
        id
        secret
      }
    }
  }
`

export default GET_STRIPE_KEYS

// @flow

import gql from "graphql-tag"

const LOGIN = gql`
  mutation login($phoneNumber: String, $provider: String!, $token: String!) {
    login(phoneNumber: $phoneNumber, provider: $provider, token: $token) {
      jwt
      user {
        id
        firstName
        lastName
        email
        phoneNumber
      }
    }
  }
`

export default LOGIN

// @flow
/* eslint-disable max-lines-per-function */

import type { FetchSummary, ScheduledFetchSummary } from "@fetch/graphql"
import { useMemo, useState } from "react"
import GET_FETCH_SCHEDULED from "queries/getFetchScheduled"
import HISTORY from "queries/history"
import type { OrdersTab } from "@fetch/frontend"
import YourOrders from "./YourOrders"
import prepareComponent from "@bluframe/grapple/prepareComponent"
import { useQuery } from "@apollo/client"

type Props = {||}

export type ComponentProps = {|
  +activeTab: OrdersTab,
  +emptyMessage: string,
  +items: FetchSummary[] | ScheduledFetchSummary[],
  +onTabClick: (tab: OrdersTab) => void
|}

const DEFAULT_ITEMS: FetchSummary[] = []
const DEFAULT_TAB = "scheduled"

const usePrepareComponent = (): ComponentProps => {
  const {
    data: dataHistory,
    error: errorHistory,
    loading: loadingHistory
  } = useQuery(HISTORY, {
    fetchPolicy: "network-only"
  })
  const {
    data: dataFetchScheduled,
    error: errorFetchScheduled,
    loading: loadingFetchScheduled
  } = useQuery(GET_FETCH_SCHEDULED, {
    fetchPolicy: "network-only"
  })
  const [activeTab, setActiveTab] = useState<OrdersTab>(DEFAULT_TAB)

  const onTabClick = (tab: OrdersTab) => {
    setActiveTab(tab)
  }

  const items = useMemo(() => {
    if (
      (activeTab === "history" && !dataHistory) ||
      (activeTab === "scheduled" && !dataFetchScheduled) ||
      errorHistory ||
      errorFetchScheduled ||
      loadingHistory ||
      loadingFetchScheduled
    ) {
      return DEFAULT_ITEMS
    }

    if (activeTab === "scheduled") {
      return dataFetchScheduled.fetchScheduled
    }

    if (activeTab === "history") {
      return dataHistory.history
    }

    return DEFAULT_ITEMS
  }, [
    activeTab,
    dataHistory,
    errorHistory,
    loadingHistory,
    dataFetchScheduled,
    errorFetchScheduled,
    loadingFetchScheduled
  ])

  const emptyMessage = useMemo(() => {
    if (activeTab === "scheduled") {
      return "You have no scheduled orders."
    }

    if (activeTab === "history") {
      return "You have no past orders."
    }

    return ""
  }, [activeTab])

  return {
    activeTab,
    emptyMessage,
    items,
    onTabClick
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  YourOrders
)

// @flow
/* eslint-disable no-ternary */

import styled, { type StyledComponent } from "styled-components"
import ResetButton from "components/buttons/Reset"
import type { Theme } from "@bluframe/blublocks"
import colors from "styles/colors"
import mediaQueries from "styles/mediaQueries"

export const Wrapper: StyledComponent<any, Theme, HTMLElement> = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
`

export const Indicator: StyledComponent<
  any,
  Theme,
  StyledComponent<any, Theme, HTMLElement>
> = styled(ResetButton)`
  box-shadow: 0px 3px 6px ${colors.blackRussian};
  ${({ right }) => right && "transform: rotate(180deg)"};
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  padding: 16px;
  cursor: pointer;
  margin-right: ${({ right }) => (right ? "0" : "16px")};

  ${({ right }) => right && "margin-left: 16px"};
`

export const InnerWrapper: StyledComponent<
  any,
  Theme,
  HTMLElement
> = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  transition: transform 0.3s;
  scroll-behavior: smooth;
  overflow-x: scroll;

  ${mediaQueries.tablet} {
    overflow: hidden;
  }
`

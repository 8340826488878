// @flow
/* eslint-disable no-ternary */

import { type ComponentProps } from "."
import Option from "./Option"
import React from "react"
import Select from "react-select"
import { Wrapper } from "./styled"
import defaultStyles from "./reactSelectStyles"

const AddressDropdown = ({
  options,
  onChange,
  onInputChange,
  placeholder,
  styles,
  value
}: ComponentProps) => (
  <Wrapper>
    <Select
      blurInputOnSelect
      components={{ DropdownIndicator: null, Option }}
      onChange={onChange}
      onInputChange={onInputChange}
      options={options}
      placeholder={placeholder}
      styles={{ ...defaultStyles, ...styles }}
      value={value?.value ? value : null}
    />
  </Wrapper>
)

export default AddressDropdown

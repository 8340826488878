// @flow
/* eslint-disable no-ternary, max-lines-per-function, react/no-array-index-key  */

import { Form, LoadingWrapper } from "./styled"
import Carousel from "./Carousel"
import CarouselWheel from "./CarouselWheel"
import type { Category } from "@fetch/frontend"
import CategorySection from "./CategorySection"
import { type ComponentProps } from "."
import Favourites from "screens/Categories/Favourites"
import { Helmet } from "react-helmet-async"
import Loading from "components/Loading"
import React from "react"
import SearchBar from "components/SearchBar"
import Wrapper from "components/layout/Wrapper"
import { generateLink } from "./helpers"

const categoriesHomeSlugs = [
  "restaurants",
  "groceries",
  "shopping",
  "convenience",
  "pizza",
  "home-hardware"
]

const Categories = ({
  categories,
  loading,
  onChange,
  onSubmit,
  value
}: ComponentProps) =>
  loading ? (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  ) : (
    <>
      <Helmet>
        <title>{`Fetch Delivery`}</title>
      </Helmet>
      <Wrapper>
        <Form onSubmit={onSubmit}>
          <SearchBar
            onChange={onChange}
            placeholder="Search business, category, or address"
            value={value}
          />
        </Form>

        <Carousel>
          {categories?.map(
            (
              { image, isSearch, label, slug, title }: Category,
              index: number
            ) => (
              <CarouselWheel
                image={image}
                key={`${slug}-${index}`}
                label={label}
                link={generateLink(slug, label, isSearch)}
                slug={slug}
                title={title}
              />
            )
          )}
        </Carousel>

        <Favourites />

        {categoriesHomeSlugs.map((slug: string, index: number) => {
          const category = categories?.find(
            (item: Category) => item.slug === slug
          )

          return (
            category && (
              <CategorySection
                key={`${slug}Section-${index}`}
                label={category.label}
                slug={slug}
              />
            )
          )
        })}
      </Wrapper>
    </>
  )

export default Categories

// @flow

import gql from "graphql-tag"

const SEND_IMAGE = gql`
  mutation sendImage($id: Int!, $image: String!, $name: String!) {
    sendImage(id: $id, image: $image, name: $name) {
      message
    }
  }
`

export default SEND_IMAGE

// @flow

import gql from "graphql-tag"

const ATTACH_PAYMENT_METHOD = gql`
  mutation attachPaymentMethod($sourceId: String!) {
    attachPaymentMethod(sourceId: $sourceId)
  }
`

export default ATTACH_PAYMENT_METHOD

// @flow
/* eslint-disable no-ternary */

import styled, { type StyledComponent } from "styled-components"
import BaseButton from "components/buttons/Base"
import colors from "styles/colors"
import { rgba } from "polished"

const ITEM_ALPHA = 0.3

export const Container: StyledComponent<any, mixed, HTMLElement> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ItemsContainer: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div``

export const HeadLine: StyledComponent<any, mixed, HTMLElement> = styled.h2`
  font-family: "Roboto", Arial, open-sans, serif;
  text-align: left;
  font-size: 28px;
  margin-bottom: 20px;
  margin-top: 20px;

  &:after {
    content: "—";
    display: block;
    width: 25%;
    margin: -5px auto 0;
  }
`

export const Subtitle: StyledComponent<any, mixed, HTMLElement> = styled.h3`
  font-family: "Roboto", Arial, open-sans, serif;
  font-size: 22px;
  text-align: center;
  margin: 0 0 14px;
`

export const Content: StyledComponent<any, mixed, HTMLElement> = styled.span`
  font-family: "Roboto", Arial, open-sans, serif;
  font-size: 22px;
  text-align: center;
  font-weight: 700;
`

export const IconWrapper: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.span`
  margin-right: 10px;
`

export const CancelButton: StyledComponent<any, mixed, any> = styled(
  BaseButton
)`
  display: inline-block;
  text-decoration: none;
  color: ${colors.white};
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  line-height: 38px;
  border-radius: 100px;
  padding: 0 22px;
  transition: all 0.3s;
  background: ${colors.charcoal};
  width: 170px;
  margin-bottom: 10px;
`

export const MenuItem: StyledComponent<any, mixed, HTMLElement> = styled.div`
  border-top: 1px solid ${colors.bonJour};
  color: ${colors.doveGray};
  font-family: Roboto, Arial, open-sans;
  font-size: 14px;
  letter-spacing: 0px;
  padding: 10px 0;
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const MenuItemName: StyledComponent<any, mixed, HTMLElement> = styled.p`
  margin: 0;
`

export const MenuItemPrice: StyledComponent<any, mixed, HTMLElement> = styled.p`
  color: ${colors.charcoal};
  margin: 0;
  font-family: Roboto, Arial, open-sans;
  font-size: 14px;
`

export const ProgressButton: StyledComponent<
  any,
  mixed,
  StyledComponent<any, mixed, HTMLElement>
> = styled(CancelButton)`
  background: ${({ status }) => (status ? colors.seance : colors.doveGray)};
  border-radius: 5px;
  width: 100%;
  cursor: auto;

  &:nth-child(2) {
    margin-left: 5%;
  }

  &:hover {
    opacity: 1;
  }
`

export const ButtonsWrapper: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  display: flex;
  padding: 20px 0 0;
  border-top: 1px solid ${colors.bonJour};
`

export const Box: StyledComponent<any, mixed, HTMLElement> = styled.div`
  background-color: ${colors.white};
  box-shadow: 1px 1px 10px ${rgba(colors.black, ITEM_ALPHA)};
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 0 auto 20px;
  width: 80%;

  @media only screen and (min-width: 768px) {
    flex: 0 0 auto;
    margin: 0 0 20px;
    padding: 25px;
  }
`

export const PurchaseSubtotal: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;
  font-family: Roboto, Arial, open-sans;
  font-size: 16px;
  letter-spacing: 0px;
  color: ${colors.doveGray};
  margin-top: 5px;
  border-top: 1px solid ${colors.bonJour};
  padding-top: 14px;
`

export const ItemsWrapper: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  display: flex;
  flex-direction: column;
`

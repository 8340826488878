// @flow
/* eslint-disable max-lines-per-function, no-ternary */

import { Action, Bottom, Box, Top } from "./styled"
import { Button } from "@fetch/ui"
import type { ComponentProps } from "."
import H2 from "components/typography/H2"
import H3 from "components/typography/H3"
import H4 from "components/typography/H4"
import Paragraph from "components/typography/Paragraph"
import React from "react"
import Wrapper from "components/layout/Wrapper"
import { format } from "date-fns"

const DATE_FORMAT = "MMMM do yyyy"
const ONE_HUNDRED = 100

const FetchUnlimited = ({
  amount,
  hasSubscription,
  last4,
  onSubscribe,
  onUnsubscribe,
  renewsOn,
  subscribedOn
}: ComponentProps) => (
  <Wrapper>
    <Box>
      <H2>{"Fetch Unlimited"}</H2>
      <Top>
        {hasSubscription && subscribedOn ? (
          <>
            {subscribedOn && (
              <H4>{`Member since: ${format(
                new Date(subscribedOn),
                DATE_FORMAT
              )}`}</H4>
            )}
            <H4>{"Billing Plan"}</H4>
            <Paragraph>{"Monthly"}</Paragraph>
            {renewsOn && Boolean(amount) && (
              <Paragraph>{`Renews on ${format(
                new Date(renewsOn),
                DATE_FORMAT
              )} for $${amount / ONE_HUNDRED}`}</Paragraph>
            )}
          </>
        ) : (
          <>
            <H3>{"What is Fetch Unlimited?"}</H3>
            <Paragraph>{"Free Delivery or $5.99 off every order"}</Paragraph>
          </>
        )}
      </Top>

      <Bottom>
        {hasSubscription ? (
          <>
            <H4>{"Payment Details"}</H4>
            <Paragraph>{"Payment method"}</Paragraph>
            {Boolean(last4) && (
              <Paragraph>{`Card ending in ${last4}`}</Paragraph>
            )}
          </>
        ) : (
          <Paragraph>
            {
              "By joining Fetch Unlimited, you authorize Fetch Delivery to charge $9.99 every month. Cancel up to 48 hours before trial ends"
            }
          </Paragraph>
        )}
      </Bottom>

      <Action>
        {hasSubscription ? (
          <Button
            label="Cancel Membership"
            onClick={onUnsubscribe}
            outlined
            secondary
          />
        ) : (
          <Button
            expanded
            label="Join Free for One Month"
            onClick={onSubscribe}
          />
        )}
      </Action>
    </Box>
  </Wrapper>
)

export default FetchUnlimited

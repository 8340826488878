// @flow

import { Image, Images, Wrapper } from "./styled"
import { type ComponentProps } from "."
import React from "react"
import SearchBar from "components/SearchBar"
import type { WebImage } from "@fetch/frontend"

const ImageSearch = ({
  imageSearch,
  images,
  onChange,
  onClickImage
}: ComponentProps) => (
  <Wrapper>
    <h2>{`Image Search Results`}</h2>

    <SearchBar
      onChange={onChange}
      placeholder="Search for your favorite product"
      value={imageSearch}
    />

    <Images>
      {images.map(({ thumbnailUrl }: WebImage) => (
        <Image
          key={thumbnailUrl}
          onClick={onClickImage(thumbnailUrl)}
          src={thumbnailUrl}
        />
      ))}
    </Images>
  </Wrapper>
)

export default ImageSearch

// @flow

import gql from "graphql-tag"

const GET_FETCH_IN_PROGRESS = gql`
  query fetchInProgress {
    fetchInProgress {
      id
      state
    }
  }
`

export default GET_FETCH_IN_PROGRESS

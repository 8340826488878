// @flow

import styled, { type StyledComponent } from "styled-components"
import type { Theme } from "@bluframe/blublocks"
import colors from "styles/colors"

export const Wrapper: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  background: ${colors.white};
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 40px;
`

export const Heading: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  display: flex;
`

export const Name: StyledComponent<{||}, Theme, HTMLElement> = styled.h3`
  color: ${colors.thunder};
  font-family: Roboto, Arial, open-sans;
  font-size: 20px;
  margin: 10px 0;
  text-align: center;
  width: 100%;
`

export const Description: StyledComponent<{||}, Theme, HTMLElement> = styled.p`
  color: ${colors.codGray};
  font-family: Roboto, Arial, open-sans;
  font-size: 14px;
  margin: 30px 0;
  text-align: justify;
`

export const Price: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  color: ${colors.driftwood};
  font-family: Roboto, Arial, open-sans;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
`

export const Image: StyledComponent<{||}, Theme, HTMLElement> = styled.img`
  height: 150px;
  object-fit: contain;
`

export const CrossWrapper: StyledComponent<
  {||},
  Theme,
  HTMLElement
> = styled.div`
  left: 15px;
  position: absolute;
  top: 20px;
`

// @flow
/* eslint-disable camelcase, max-lines-per-function, max-statements */

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { useMutation, useQuery } from "@apollo/client"
import ATTACH_PAYMENT_METHOD from "mutations/attachPaymentMethod"
import GET_LAST_4 from "queries/getLast4"
import StripeElement from "./StripeElement"
import prepareComponent from "hocs/prepareComponent"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useLastLocation } from "react-router-last-location"

type Props = {
  +onSetCardUpdateLoading: (isLoading: boolean) => void
}

export type ComponentProps = {
  +onSubmit: (event: Event) => Promise<void>
}

const usePrepareComponent = ({
  onSetCardUpdateLoading
}: Props): ComponentProps => {
  const history = useHistory()
  const lastLocation = useLastLocation()
  const params = new URLSearchParams(history.location.search)

  const onUpdateCompleted = () => {
    if (params.get("lastLocation") && lastLocation?.pathname) {
      history.push(lastLocation.pathname)
    } else {
      history.push("/")
    }
  }

  const stripe = useStripe()
  const elements = useElements()
  const [attachPaymentMethod, { data, error, loading }] = useMutation(
    ATTACH_PAYMENT_METHOD,
    { onCompleted: onUpdateCompleted }
  )
  const { refetch, loading: refetchLoading } = useQuery(GET_LAST_4)

  if (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }

  useEffect(() => {
    onSetCardUpdateLoading(Boolean(loading || refetchLoading))
  }, [loading, onSetCardUpdateLoading, refetchLoading])

  useEffect(() => {
    if (data) {
      refetch()
    }
  }, [data, loading, refetch])

  const onSubmit = async (event: Event) => {
    // Block native form submission.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement)

    const {
      source: { id }
    } = await stripe.createSource(cardElement)

    if (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    } else {
      attachPaymentMethod({ variables: { sourceId: id } })
      elements.getElement(CardElement).clear()
    }
  }

  return { onSubmit }
}

export default prepareComponent(usePrepareComponent)(StripeElement)

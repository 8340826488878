// @flow

import styled, { type StyledComponent } from "styled-components"
import type { Theme } from "@bluframe/blublocks"
import colors from "styles/colors"

export const Wrapper: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  justify-content: space-evenly;
  width: 100%;
`

export const InstructionsWrapper: StyledComponent<
  {||},
  Theme,
  HTMLElement
> = styled.div`
  background: ${colors.white};
  box-shadow: 1px 1px 10px #0000004d;
  display: flex;
  flex-direction: column;
  margin: 20px 15px 0;
  padding: 15px;
`

export const Instructions: StyledComponent<
  {||},
  Theme,
  HTMLElement
> = styled.textarea`
  padding: 10px;

  &::placeholder {
    color: ${colors.doveGray};
    font-family: "Roboto", Arial, open-sans;
    font-size: 14px;
  }
`

export const Add: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  margin: 20px 15px 0;
`

export const Container: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 300px);
`

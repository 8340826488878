// @flow
/* eslint-disable react/jsx-max-depth*/

import { Span, Wrapper } from "./styled"
import { type ComponentProps } from "."
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Modal from "./Modal"
import React from "react"
import { faPhone } from "@fortawesome/free-solid-svg-icons"

const CallDriver = ({
  disabled,
  isModalOpen,
  onToggleModal,
  phoneNumber
}: ComponentProps) => (
  <Wrapper>
    <Span disabled={disabled} onClick={onToggleModal(true)}>
      <FontAwesomeIcon icon={faPhone} size="2x" />
    </Span>

    <Modal
      isModalOpen={isModalOpen}
      onToggleModal={onToggleModal}
      phoneNumber={phoneNumber}
    />
  </Wrapper>
)

export default CallDriver

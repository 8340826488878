// @flow
/* eslint-disable  react/jsx-max-depth, no-ternary, no-nested-ternary */

import {
  Card,
  Container,
  Item,
  ItemsWrapper,
  LoadingWrapper,
  Subtitle,
  Title,
  Wrapper
} from "./styled"
import { type ComponentProps } from "."
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Loading from "components/Loading"
import React from "react"
import StripeElement from "./StripeElement"
import { faCreditCard } from "@fortawesome/free-regular-svg-icons"

const Payment = ({
  cardUpdateLoading,
  last4,
  loading,
  onSetCardUpdateLoading
}: ComponentProps) =>
  loading ? (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  ) : (
    <Wrapper>
      <Card>
        <Title>{"Payment Method"}</Title>

        <ItemsWrapper>
          {cardUpdateLoading ? (
            <LoadingWrapper noMarginTop>
              <Loading />
            </LoadingWrapper>
          ) : (
            last4 && (
              <>
                <Subtitle>{"Current Card"}</Subtitle>

                <Item>
                  <FontAwesomeIcon icon={faCreditCard} />

                  {`  Card ending in `}

                  {last4}
                </Item>
              </>
            )
          )}

          <Item>
            <Subtitle>{"Enter a new card"}</Subtitle>
          </Item>

          <Container>
            <StripeElement onSetCardUpdateLoading={onSetCardUpdateLoading} />
          </Container>
        </ItemsWrapper>
      </Card>
    </Wrapper>
  )

export default Payment

// @flow

import styled, { type StyledComponent } from "styled-components"
import BaseButton from "components/buttons/Base"
import colors from "styles/colors"

export const Button: StyledComponent<any, mixed, any> = styled(
  BaseButton
).attrs({
  type: "submit"
})`
  background: ${colors.seance};
  border-radius: 3px;
  color: ${colors.white};
  font-size: 18px;
  height: 45px;
  letter-spacing: 0.27px;
  margin-top: 20px;
  width: 100%;

  &:disabled {
    background: ${colors.dustyGray};
    cursor: not-allowed;

    &:hover {
      opacity: 1;
    }
  }
`

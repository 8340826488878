// @flow
/* eslint-disable react/jsx-max-depth */

import {
  Buttons,
  CancelButton,
  Container,
  HeadLine,
  SubmitButton
} from "./styled"
import { type ComponentProps } from "."
import React from "react"
import ReactModal from "react-modal"

ReactModal.setAppElement("#root")

const Modal = ({ isModalOpen, onSubmit, onToggleModal }: ComponentProps) => (
  <ReactModal
    className="fetch-modal tip-modal"
    contentLabel="Tip Alert Modal"
    isOpen={isModalOpen}
    onRequestClose={onToggleModal({})}
  >
    <Container>
      <HeadLine>{`Are you sure you want to continue without a tip?`}</HeadLine>

      <Buttons>
        <SubmitButton
          onClick={onSubmit(true)}
          value="Yes - I don't want to tip today"
        />

        <CancelButton onClick={onToggleModal({})}>
          {"Let me go back and tip!"}
        </CancelButton>
      </Buttons>
    </Container>
  </ReactModal>
)

export default Modal

// @flow

import type { Address, SavedAddress } from "@fetch/frontend"
import Item from "./Item"
import { prepareComponent } from "@bluframe/grapple"

type Props = {|
  +editingAddress: SavedAddress,
  +isEditing: boolean,
  +isEditingOthers: boolean,
  +onDeleteAddress: () => void,
  +onSaveEditingAddress: () => void,
  +onSetEditingAddress: (key: string, value: Address | string) => void,
  +onSetEditingIndex: () => void,
  +savedAddress: SavedAddress
|}

export type ComponentProps = {|
  +editingAddress: SavedAddress,
  +isEditing: boolean,
  +isEditingOthers: boolean,
  +onAddressChange: (address: Address) => void,
  +onDeleteAddress: () => void,
  +onDeliveryInstructionsChange: (
    event: SyntheticEvent<HTMLInputElement>
  ) => void,
  +onNameChange: (event: SyntheticEvent<HTMLInputElement>) => void,
  +onSaveEditingAddress: () => void,
  +onSetEditingIndex: () => void,
  +savedAddress: SavedAddress
|}

const usePrepareComponent = ({
  editingAddress,
  isEditing,
  isEditingOthers,
  onDeleteAddress,
  onSaveEditingAddress,
  onSetEditingAddress,
  onSetEditingIndex,
  savedAddress
}: Props): ComponentProps => {
  const onNameChange = (event: SyntheticEvent<HTMLInputElement>) => {
    onSetEditingAddress("name", event.currentTarget.value)
  }

  const onAddressChange = (address: Address) => {
    onSetEditingAddress("address", address)
  }

  const onDeliveryInstructionsChange = (
    event: SyntheticEvent<HTMLInputElement>
  ) => {
    onSetEditingAddress("deliveryInstructions", event.currentTarget.value)
  }

  return {
    editingAddress,
    isEditing,
    isEditingOthers,
    onAddressChange,
    onDeleteAddress,
    onDeliveryInstructionsChange,
    onNameChange,
    onSaveEditingAddress,
    onSetEditingIndex,
    savedAddress
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Item
)

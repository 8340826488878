// @flow
/* eslint-disable max-statements */

import { useHistory, useParams } from "react-router-dom"
import { useMemo, useState } from "react"
import GET_SUBCATEGORIES from "queries/getSubCategories"
import SubCategories from "./SubCategories"
import type { SubCategory } from "@fetch/frontend"
import prepareComponent from "hocs/prepareComponent"
import { useQuery } from "@apollo/client"

export type ComponentProps = {|
  +category: string,
  +loading: boolean,
  +onChange: (ev: SyntheticInputEvent<EventTarget>) => void,
  +onSubmit: (ev: SyntheticEvent<any>) => void,
  +subCategories: SubCategory[],
  +value: string
|}

type SetValue = (value: string) => void

const DEFAULT_VALUE = ""

const handleChange =
  (setValue: SetValue) => (ev: SyntheticInputEvent<EventTarget>) => {
    setValue(ev.target.value)
  }

const handleSubmit =
  (history: any, value: string) => (ev: SyntheticEvent<any>) => {
    ev.preventDefault()

    if (!value) {
      return
    }

    history.push(`/categories/search/subcategories/${value}/places`)
  }

const usePrepareComponent = (): ComponentProps => {
  const { category } = useParams()
  const { data, error, loading } = useQuery(GET_SUBCATEGORIES, {
    variables: { category }
  })
  const [value, setValue] = useState(DEFAULT_VALUE)
  const history = useHistory()

  const categories = useMemo(() => {
    if (!category) {
      return { category: "", subCategories: [] }
    }

    if (error) {
      // eslint-disable-next-line no-console
      console.log(error.message)
      return { category: "", subCategories: [] }
    }

    if (loading || !data) {
      return { category: "", subCategories: [] }
    }

    return {
      category,
      subCategories: data.subCategories || []
    }
  }, [category, data, loading, error])

  return {
    category: categories.category,
    loading,
    onChange: handleChange(setValue),
    onSubmit: handleSubmit(history, value),
    subCategories: categories.subCategories,
    value
  }
}

export default prepareComponent(usePrepareComponent)(SubCategories)

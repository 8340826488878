// @flow

import styled, { type StyledComponent } from "styled-components"
import colors from "styles/colors"
import mediaQueries from "styles/mediaQueries"

export const Wrapper: StyledComponent<any, mixed, HTMLElement> = styled.div`
  background-color: ${colors.white};
  min-height: calc(100vh - 75px);
`

export const Main: StyledComponent<any, mixed, HTMLElement> = styled.div`
  margin: 0 auto;
  padding: 20px 20px 0;

  ${mediaQueries.tablet} {
    max-width: 1920px;
    padding: 40px 60px 0;
  }
`

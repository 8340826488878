// @flow

import gql from "graphql-tag"

const UPDATE_PROFILE = gql`
  mutation updateProfile($user: UserInput!) {
    updateProfile(user: $user) {
      email
      firstName
      id
      lastName
      phoneNumber
    }
  }
`

export default UPDATE_PROFILE

// @flow

import Menu from "./Menu"
import prepareComponent from "hocs/prepareComponent"
import { useState } from "react"

type SetIsOpen = (isOpen: boolean) => void

type Props = {
  +disabled: boolean
}

export type ComponentProps = {
  +disabled: boolean,
  +isOpen: boolean,
  +onClick: () => void
}

const handleClick = (isOpen: boolean, setIsOpen: SetIsOpen) => (): void => {
  setIsOpen(!isOpen)
}

const DEFAULT_IS_OPEN = false

export const usePrepareComponent = ({ disabled }: Props): ComponentProps => {
  const [isOpen, setIsOpen] = useState(DEFAULT_IS_OPEN)

  return { disabled, isOpen, onClick: handleClick(isOpen, setIsOpen) }
}

export default prepareComponent<*, ComponentProps>(usePrepareComponent)(Menu)

// @flow

import POST_VALIDATE_PHONE from "mutations/postValidatePhone"
import PhoneLogin from "./PhoneLogin"
import TagManager from "react-gtm-module"
import prepareComponent from "hocs/prepareComponent"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { useMutation } from "@apollo/client"

type Props = {
  +onSetPhoneNumber: any
}

export type ComponentProps = {
  +handleSubmit: any,
  +onSubmit: any,
  +register: any
}

const usePrepareComponent = ({ onSetPhoneNumber }: Props): ComponentProps => {
  const { getValues, register, handleSubmit } = useForm()
  const [postValidatePhone, { data }] = useMutation(POST_VALIDATE_PHONE)

  const history = useHistory()

  const onSubmit = ({ phoneNumber }) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "signInWithPhoneNumber"
      }
    })

    postValidatePhone({ variables: { phoneNumber } })
  }

  useEffect(() => {
    if (data) {
      onSetPhoneNumber(getValues("phoneNumber"))
      history.push("/login/verifyPhone")
    }
  }, [data, getValues, history, onSetPhoneNumber])

  return { handleSubmit, onSubmit, register }
}

export default prepareComponent(usePrepareComponent)(PhoneLogin)

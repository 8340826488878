// @flow

import gql from "graphql-tag"

const SEND_MESSAGE = gql`
  mutation sendMessage($id: Int!, $message: String!) {
    sendMessage(id: $id, message: $message) {
      message
    }
  }
`

export default SEND_MESSAGE

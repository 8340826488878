// @flow
/* eslint-disable max-statements, max-lines-per-function */

import { useCallback, useLayoutEffect, useState } from "react"
import { useMutation, useQuery } from "@apollo/client"
import Chat from "./Chat"
import type { ChatMessage } from "@fetch/frontend"
import GET_ACTIONS from "queries/getActions"
import SEND_IMAGE from "mutations/sendImage"
import SEND_MESSAGE from "mutations/sendMessage"
import prepareComponent from "hocs/prepareComponent"
import useActions from "./hooks/useActions"
import useMessages from "./hooks/useMessages"
import { useParams } from "react-router-dom"

export type ComponentProps = {|
  +disabled: boolean,
  +driverId: number,
  +fetchId: number,
  +imageSearch: string,
  +isModalOpen: boolean,
  +messages: ChatMessage[],
  +newMessage: string,
  +onChange: any,
  +onInitiateCall: any,
  +onUploadImage: any,
  +setImageSearch: (image: string) => void,
  +onToggleModal: (options: any) => () => void,
  +onSelectImage: (image: string) => void,
  +onSubmit: any
|}

const DEFAULT_NEW_MESSAGE = ""
const DEFAULT_TIMEOUT = 500
const DEFAULT_IS_MODAL_OPEN = false
const ZERO = 0
const DEFAULT_IMAGE_SEARCH = ""

const usePrepareComponent = () => {
  const { id } = useParams()
  const [newMessage, setNewMessage] = useState(DEFAULT_NEW_MESSAGE)
  const { data: actionsData, refetch: actionsRefetch } = useQuery(GET_ACTIONS, {
    variables: { id: Number(id) }
  })
  const [sendImage] = useMutation(SEND_IMAGE)
  const [sendMessage] = useMutation(SEND_MESSAGE)
  const { disabled, driverName, driverPhoto } = useActions()
  const [isModalOpen, setIsModalOpen] = useState(DEFAULT_IS_MODAL_OPEN)
  const [imageSearch, setImageSearch] = useState(DEFAULT_IMAGE_SEARCH)

  const messages: ChatMessage[] = useMessages(
    actionsData,
    driverName,
    driverPhoto
  )

  const onChange = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    setNewMessage(event.currentTarget.value)
  }

  const onInitiateCall = () => {
    // eslint-disable-next-line no-console
    console.log("Calling the driver")
  }

  const onSubmit = (event: SyntheticEvent<any>) => {
    event.preventDefault()
    sendMessage({
      variables: { id: Number(id), message: newMessage }
    })
    setNewMessage(DEFAULT_NEW_MESSAGE)
    setTimeout(() => {
      actionsRefetch()
    }, DEFAULT_TIMEOUT)
  }

  const onUploadImage = (event: SyntheticInputEvent<any>) => {
    const image = event.target.files[ZERO]
    const fileReader = new FileReader()

    fileReader.onload = () => {
      sendImage({
        variables: {
          id: Number(id),
          image: fileReader.result,
          name: image.name
        }
      })
    }

    fileReader.readAsDataURL(image)
  }

  useLayoutEffect(() => {
    window.scrollTo(ZERO, document.body?.scrollHeight)
  })

  const onToggleModal = useCallback(
    () => () => {
      setIsModalOpen(!isModalOpen)
    },
    [isModalOpen, setIsModalOpen]
  )

  const onSelectImage = useCallback(
    async (image: string) => {
      const data = await fetch(image)
      const blob = await data.blob()
      const fileReader = new FileReader()

      fileReader.onload = () => {
        sendImage({
          variables: {
            id: Number(id),
            image: fileReader.result,
            name: image
          }
        })
      }

      fileReader.readAsDataURL(blob)
    },
    [id, sendImage]
  )

  return {
    disabled,
    fetchId: id,
    imageSearch,
    isModalOpen,
    messages: messages || [],
    newMessage,
    onChange,
    onInitiateCall,
    onSelectImage,
    onSubmit,
    onToggleModal,
    onUploadImage,
    setImageSearch
  }
}

export default prepareComponent(usePrepareComponent)(Chat)

// @flow

import {
  HiddenCheckbox,
  Icon,
  Polyline,
  StyledCheckbox,
  Wrapper
} from "./styled"
import type { ComponentProps } from "."
import React from "react"

const Checkbox = ({ isChecked }: ComponentProps): React$Node => (
  <Wrapper>
    <HiddenCheckbox defaultChecked={isChecked} />

    <StyledCheckbox checked={isChecked}>
      <Icon viewBox="0 0 24 24">
        <Polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </Wrapper>
)

export default Checkbox

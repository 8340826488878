// @flow

import type { Address, User } from "@fetch/frontend"
import Navigation from "./Navigation"
import prepareComponent from "@bluframe/grapple/prepareComponent"
import useDropOffAddress from "hooks/useDropOffAddress"
import { useHistory } from "react-router-dom"
import useUser from "hooks/useUser"

type Props = {||}

export type ComponentProps = {|
  ...Props,
  +dropOffAddress: Address,
  +onLogin: () => void,
  +onLogout: () => void,
  +onSetUser: (newUser: ?User) => void,
  +user: ?User
|}

const usePrepareComponent = (): ComponentProps => {
  const history = useHistory()
  const { onSetUser, user } = useUser()
  const { dropOffAddress } = useDropOffAddress()

  const onLogout = () => {
    localStorage.removeItem("fetchToken")
    localStorage.removeItem("stripeKeys")
    onSetUser(null)

    history.push("/login")
  }

  const onLogin = () => {
    history.push("/login")
  }

  return {
    dropOffAddress,
    onLogin,
    onLogout,
    onSetUser,
    user
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Navigation
)

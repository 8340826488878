// @flow

import PhotoUpload from "./PhotoUpload"

export type ComponentProps = {
  +disabled: boolean,
  +onUploadImage: any
}

export default PhotoUpload

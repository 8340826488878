// @flow

import gql from "graphql-tag"

const COMPLETE_ORDER = gql`
  mutation completeOrder($completeOrderInput: CompleteOrderInput!) {
    completeOrder(completeOrderInput: $completeOrderInput) {
      fetchId
    }
  }
`

export default COMPLETE_ORDER

// @flow

import styled, { type StyledComponent } from "styled-components"
import type { Theme } from "@bluframe/blublocks"
import colors from "styles/colors"
import mediaQueries from "styles/mediaQueries"

const H4: StyledComponent<any, Theme, HTMLElement> = styled.h4`
  color: ${colors.thunder};
  font-size: 16px;

  ${mediaQueries.desktop} {
    font-size: 16px;
  }
`

export default H4

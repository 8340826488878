// @flow

import Options from "./Options"

export type ComponentProps = {
  +customTip: string,
  +isOpen: boolean,
  +onChangeCustomTip: (ev: SyntheticEvent<any>) => void,
  +onChangeActiveTipBox: (ev: SyntheticEvent<any>) => void,
  +onClick: (ev: SyntheticEvent<any>) => void,
  +onCloseTipBox: (ev: SyntheticEvent<any>) => void
}

export default Options

// @flow
/* eslint-disable no-ternary */

const styles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: 5,
    fontFamily: "Roboto, Arial, open-sans",
    fontWeight: 700,
    height: "auto"
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    paddingLeft: 7
  })
}

export default styles

// @flow
/* eslint-disable max-lines-per-function, react/jsx-max-depth, no-ternary */

import {
  CustomSubtitle,
  EstimatedTotal,
  EstimatedTotalCaption,
  Rating,
  RatingSubtitle,
  SubmitButton,
  ThankYou,
  TipItem,
  TipsBox,
  Title,
  Wrapper
} from "./styled"
import { type ComponentProps } from "."
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Menu from "./Menu"
import Modal from "./Modal"
import React from "react"
import { faStar } from "@fortawesome/free-solid-svg-icons"
import { faStar as faStarEmpty } from "@fortawesome/free-regular-svg-icons"

const ZERO = 0
const ONE = 1
const TWO = 2
const THREE = 3
const FOUR = 4
const FIVE = 5

const Tip = ({
  activeTip,
  customTip,
  driverName,
  isModalOpen,
  onChangeActiveTip,
  onChangeCustomTip,
  onChangeRating,
  onCloseTipBox,
  onSubmit,
  onToggleModal,
  rating,
  tips,
  total
}: ComponentProps) => (
  <Wrapper onSubmit={onSubmit()}>
    <Title>{`Select a tip for ${driverName}`}</Title>

    <TipsBox>
      {tips.map(({ label, value }) => (
        <TipItem
          isActive={activeTip === value}
          key={value}
          onClick={onChangeActiveTip(value)}
        >
          {label}
        </TipItem>
      ))}
    </TipsBox>

    <Menu
      customTip={customTip}
      onChangeActiveTip={onChangeActiveTip}
      onChangeCustomTip={onChangeCustomTip}
      onCloseTipBox={onCloseTipBox}
    />

    <CustomSubtitle>{`100% of your tip goes to ${driverName}`}</CustomSubtitle>

    <EstimatedTotal>{total && `$${total}`}</EstimatedTotal>

    <EstimatedTotalCaption>{`Estimated Order Total`}</EstimatedTotalCaption>

    <Title>{`How did ${driverName} do?`}</Title>

    <Rating>
      <FontAwesomeIcon
        icon={rating > ZERO ? faStar : faStarEmpty}
        onClick={onChangeRating(ONE)}
      />

      <FontAwesomeIcon
        icon={rating > ONE ? faStar : faStarEmpty}
        onClick={onChangeRating(TWO)}
      />

      <FontAwesomeIcon
        icon={rating > TWO ? faStar : faStarEmpty}
        onClick={onChangeRating(THREE)}
      />

      <FontAwesomeIcon
        icon={rating > THREE ? faStar : faStarEmpty}
        onClick={onChangeRating(FOUR)}
      />

      <FontAwesomeIcon
        icon={rating > FOUR ? faStar : faStarEmpty}
        onClick={onChangeRating(FIVE)}
      />
    </Rating>

    <RatingSubtitle>{"Choose one"}</RatingSubtitle>

    <RatingSubtitle>{"(Your driver will not see your rating)"}</RatingSubtitle>

    <ThankYou>{"Thank You!"}</ThankYou>

    <SubmitButton />

    <Modal
      isModalOpen={isModalOpen}
      onSubmit={onSubmit}
      onToggleModal={onToggleModal}
    />
  </Wrapper>
)

export default Tip

// @flow

import { atom, useRecoilState } from "recoil"
import type { Address } from "@fetch/frontend"
import localStorageEffect from "effects/localStorage"

export const DEFAULT_DROP_OFF_ADDRESS = {
  coordinates: { lat: 0, lng: 0 },
  label: "",
  value: ""
}

const dropOffAddressState = atom<Address>({
  default: DEFAULT_DROP_OFF_ADDRESS,
  // eslint-disable-next-line camelcase
  effects_UNSTABLE: [localStorageEffect("dropOffAddress")],
  key: "dropOffAddress"
})

const useDropOffAddress = (): {|
  +dropOffAddress: Address,
  onSetDropOffAddress: any
|} => {
  const [dropOffAddress, setDropOffAddress] =
    useRecoilState(dropOffAddressState)

  const onSetDropOffAddress = (newDropOffAddress: any) => {
    setDropOffAddress(newDropOffAddress)
  }

  return { dropOffAddress, onSetDropOffAddress }
}

export default useDropOffAddress

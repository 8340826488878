// @flow
/* eslint-disable max-lines-per-function, no-ternary, react/jsx-max-depth */

import AnimateHeight from "react-animate-height"
import { ReactComponent as ArrowDown } from "assets/icons/arrowDown.svg"
import { ReactComponent as ArrowUp } from "assets/icons/arrowUp.svg"
import { Category } from "../styled"
import { CategoryName } from "./styled"
import type { ComponentProps } from "."
import type { MenuItem } from "@fetch/frontend"
import MenuItemComponent from "../MenuItem"
import React from "react"

const ZERO = 0

const CollapsibleBox = ({
  name,
  isLoggedIn,
  isToggled,
  items,
  onLogin,
  onToggleBox,
  onToggleModal
}: ComponentProps) => (
  <>
    <CategoryName onClick={onToggleBox}>
      {name} {isToggled ? <ArrowDown /> : <ArrowUp />}
    </CategoryName>

    <AnimateHeight
      animateOpacity
      duration={1000}
      height={isToggled ? "auto" : ZERO}
    >
      <Category>
        {items.map((item: MenuItem) => (
          <MenuItemComponent
            detail={item.detail}
            image={item.image}
            key={item.id}
            name={item.name}
            onClick={isLoggedIn ? onToggleModal({ item }) : onLogin}
            price={item.price}
          />
        ))}
      </Category>
    </AnimateHeight>
  </>
)

export default CollapsibleBox

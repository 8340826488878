// @flow

import { CustomTip, Wrapper } from "./styled"
import { type ComponentProps } from "."
import Options from "./Options"
import React from "react"

const Menu = ({
  customTip,
  onChangeActiveTipBox,
  onChangeCustomTip,
  onClick,
  onCloseTipBox,
  isOpen
}: ComponentProps) => (
  <Wrapper>
    <CustomTip onClick={onClick}>{`Custom`}</CustomTip>

    <Options
      customTip={customTip}
      isOpen={isOpen}
      onChangeActiveTipBox={onChangeActiveTipBox}
      onChangeCustomTip={onChangeCustomTip}
      onClick={onClick}
      onCloseTipBox={onCloseTipBox}
    />
  </Wrapper>
)

export default Menu

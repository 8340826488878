// @flow
/* eslint-disable no-ternary */

import { Circle, Number, Text, Wrapper } from "./styled"
import { type ComponentProps } from "."
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { faShoppingBasket } from "@fortawesome/free-solid-svg-icons"

const InProgress = ({ link }: ComponentProps) => (
  <Wrapper to={link}>
    {link && (
      <>
        <Text>{"In Progress"}</Text>

        <FontAwesomeIcon icon={faShoppingBasket} />

        <Circle>
          <Number>{"1"}</Number>
        </Circle>
      </>
    )}
  </Wrapper>
)

export default InProgress

// @flow

import gql from "graphql-tag"

const GET_PHONE = gql`
  query getPhone($id: Int!, $fetchId: Int!) {
    phone(id: $id, fetchId: $fetchId) {
      phoneNumber
    }
  }
`

export default GET_PHONE

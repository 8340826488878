// @flow

import styled, { type StyledComponent } from "styled-components"
import type { Theme } from "@bluframe/blublocks"

const Cross: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  cursor: pointer;
  height: 20px;
  width: 20px;

  &:before,
  &:after {
    background: ${({ theme }) => theme.palette.primary.main};
    content: " ";
    height: 20px;
    left: 15px;
    position: absolute;
    width: 2.5px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

export default Cross

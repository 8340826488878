// @flow

import styled, { type StyledComponent } from "styled-components"
import colors from "styles/colors"
import mediaQueries from "styles/mediaQueries"

const H2: StyledComponent<any, mixed, HTMLElement> = styled.h2`
  color: ${colors.thunder};
  font-size: 18px;

  ${mediaQueries.desktop} {
    font-size: 24px;
  }
`

export default H2

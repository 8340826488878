// @flow
/* eslint-disable react/destructuring-assignment */

import { type ComponentProps } from "."
import { Link } from "react-scroll"
import React from "react"
import { components } from "react-select"

export const Option = (props: ComponentProps) => (
  <Link duration={1000} smooth to={props.data.value}>
    <components.Option {...props}>{props.data.label}</components.Option>
  </Link>
)

export default Option

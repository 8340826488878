// @flow

import Place from "./Place"
import type { PlaceType } from "@fetch/frontend"

export type ComponentProps = PlaceType & {
  +link: string
}

export default Place

// @flow
/* eslint-disable react/jsx-max-depth, max-lines-per-function */

import {
  Button,
  Category,
  Container,
  CustomOrder,
  DesktopImage,
  Heading,
  Image,
  Main,
  Top,
  Wrapper
} from "./styled"
import { type ComponentProps } from "."
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Modal from "components/ImageModal"
import React from "react"
import Sidebar from "components/Sidebar"
import { faPlus } from "@fortawesome/free-solid-svg-icons"

const CustomPlace = ({
  imageSearch,
  isCustomOrderOpen,
  isModalOpen,
  items,
  onAddCustomOrder,
  onDeleteItem,
  onDeleteStop,
  onSelectImage,
  onSetItems,
  onToggleModal,
  selectedImage,
  setImageSearch,
  selectedItem
}: ComponentProps) => (
  <Wrapper>
    <Container>
      <Main>
        <Heading>
          <Top>
            <Image src="/images/personalConcierge.jpg" />
          </Top>

          <DesktopImage src="/images/personalConcierge.jpg" />
        </Heading>

        <Category>
          <CustomOrder onClick={onToggleModal({ customOrderOpen: true })}>
            <Button type="button">
              <FontAwesomeIcon icon={faPlus} />
            </Button>
            {"Add Custom Item"}
          </CustomOrder>
        </Category>
      </Main>

      <Sidebar onDeleteItem={onDeleteItem} onDeleteStop={onDeleteStop} />

      <Modal
        customOrderItems={items}
        imageSearch={imageSearch}
        isCustomOrderOpen={isCustomOrderOpen}
        isModalOpen={isModalOpen}
        onAddCustomOrder={onAddCustomOrder}
        onAddToOrder={onAddCustomOrder}
        onSelectImage={onSelectImage}
        onSetCustomOrderItems={onSetItems}
        onToggleModal={onToggleModal}
        selectedImage={selectedImage}
        selectedItem={selectedItem}
        setImageSearch={setImageSearch}
      />
    </Container>
  </Wrapper>
)

export default CustomPlace

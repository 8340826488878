// @flow

import styled, { type StyledComponent } from "styled-components"
import colors from "styles/colors"

export const Wrapper: StyledComponent<any, mixed, HTMLElement> = styled.div`
  background-color: ${colors.black};
  display: flex;
  height: calc(100vh - 76px);
  flex-direction: column;
  align-items: center;
  color: ${colors.white};
`

export const Subtitle: StyledComponent<any, mixed, HTMLElement> = styled.h2`
  margin-top: 30px;
  margin-bottom: 15px;
`

export const H3: StyledComponent<any, mixed, HTMLElement> = styled.h3``

export const Text: StyledComponent<any, mixed, HTMLElement> = styled.p``

export const CodeInput: StyledComponent<any, mixed, HTMLElement> = styled.input`
  border: 0.5px solid ${colors.mercury};
  border-radius: 3px;
  box-shadow: 0px 0px 4px #0000004d;
  color: ${colors.doveGray};
  max-width: 180px;
  padding: 0 20px;
  height: 45px;
  font-size: 16px;
  margin: 0;
  margin: 10px;

  &::placeholder {
    font-size: 16px;
  }
`

export const Form: StyledComponent<any, mixed, HTMLElement> = styled.form`
  display: flex;
`

export const Button: StyledComponent<any, mixed, HTMLElement> = styled.button`
  background: ${colors.driftwood};
  border: none;
  border-radius: 3px;
  color: ${colors.white};
  cursor: pointer;
  font-family: Roboto, Arial, open-sans;
  font-size: 20px;
  height: 45px;
  letter-spacing: 0.27px;
  text-align: center;
  width: 100%;
  outline: none;
  margin: 10px;
  padding: 0 15px;
`

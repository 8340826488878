// @flow
/* eslint-disable no-ternary */

import styled, { type StyledComponent } from "styled-components"
import ResetButton from "components/buttons/Reset"
import type { Theme } from "@bluframe/blublocks"
import colors from "styles/colors"
import mediaQueries from "styles/mediaQueries"

export const Title: StyledComponent<any, Theme, HTMLElement> = styled.h2`
  color: ${colors.mineShaft};
  font-family: Roboto, Arial, open-sans;
  font-size: 24px;

  ${mediaQueries.desktop} {
    position: relative;
  }
`

export const Messages: StyledComponent<any, Theme, HTMLElement> = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
  max-width: 100%;
  max-height: 100%;

  ${mediaQueries.desktop} {
    min-width: 1024px;
  }
`

export const Message: StyledComponent<any, Theme, HTMLElement> = styled.p`
  background: ${({ isMe }) => (isMe ? colors.thunder : colors.cararra)};
  border-radius: 10px;
  color: ${({ isMe }) => (isMe ? colors.white : colors.thunder)};
  flex: 1 0 auto;
  font-family: Roboto, Arial, open-sans;
  font-size: 14px;
  max-width: 200px;
  padding: 10px 20px;

  ${mediaQueries.tablet} {
    font-size: 20px;
    max-width: 400px;
  }
`

export const MessageWrapper: StyledComponent<
  any,
  Theme,
  HTMLElement
> = styled.span`
  align-self: ${({ isMe }) => (isMe ? "flex-end" : "flex-start")};
  display: flex;
`

export const Form: StyledComponent<any, Theme, HTMLElement> = styled.form`
  align-items: center;
  background: ${colors.charcoal};
  border-top: 1px solid ${colors.bonJour};
  bottom: 0;
  display: flex;
  height: 60px;
  left: 0;
  position: fixed;
  width: 100vw;
  padding: 20px 10px;

  ${mediaQueries.tablet} {
    padding: 40px;
  }
`

export const Input: StyledComponent<
  any,
  Theme,
  HTMLElement
> = styled.input.attrs({ type: "text" })`
  border: 0;
  border-radius: 3px;
  color: ${colors.doveGray};
  font-size: 20px;
  height: 44px;
  margin-bottom: 0;
  margin-left: 5px;
  margin-right: 5px;
  outline: none;
  padding: 10px 20px;
  width: 100%;

  ${mediaQueries.tablet} {
    flex: 10 0 auto;
    width: auto;
  }
`

export const Send: StyledComponent<any, Theme, HTMLElement> = styled.div`
  padding: 0;

  ${mediaQueries.tablet} {
    flex: 1 0 auto;
    width: 300px;
    margin-left: 10px;
  }
`

export const Image: StyledComponent<any, Theme, HTMLElement> = styled.img`
  border-radius: 5px;
  height: auto;
  margin-bottom: 20px;
  object-fit: cover;
  width: 200px;
`

export const Photo: StyledComponent<
  any,
  Theme,
  StyledComponent<any, Theme, HTMLElement>
> = styled(Image)`
  border-radius: 50%;
  height: 50px;
  margin-right: 20px;
  margin-top: 5px;
  width: 50px;
`

export const Top: StyledComponent<any, Theme, HTMLElement> = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
`

export const Timestamp: StyledComponent<any, Theme, HTMLElement> = styled.p`
  align-self: ${({ isMe }) => (isMe ? "flex-end" : "flex-start")};
  color: ${colors.dustyGray};
  flex: 1 0 auto;
  font-size: 12px;
  margin-bottom: 10px;
  ${({ isMe }) => !isMe && "margin-left: 5px"};
  ${({ isMe }) => isMe && "margin-right: 5px"};
  margin-top: -10px;
`

export const PhotoSearch: StyledComponent<
  any,
  Theme,
  StyledComponent<any, Theme, HTMLElement>
> = styled(ResetButton)`
  cursor: pointer;
  padding-right: 20px;
  margin-top: -5px;

  &:disabled {
    cursor: default;
    opacity: 60%;
  }

  & svg {
    color: ${colors.white};
    height: 29px;
  }
`

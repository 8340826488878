// @flow

import styled, { type StyledComponent, keyframes } from "styled-components"
import type { Theme } from "@bluframe/blublocks"

const rippleAnimation = keyframes`
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
`

export const RippleInner: StyledComponent<
  {||},
  Theme,
  HTMLElement
> = styled.div`
  position: absolute;
  border: 4px solid ${({ theme }) => theme.palette.secondary.main};
  opacity: 1;
  border-radius: 50%;
  animation: ${rippleAnimation} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

  &:nth-child(2) {
    animation-delay: -0.5s;
  }
`

export const Ripple: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
`

// @flow

import { Container, CrossWrapper, HeadLine, PhoneLink } from "./styled"
import type { ComponentProps } from "."
import Cross from "components/icons/Cross"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import ReactModal from "react-modal"
import { faPhone } from "@fortawesome/free-solid-svg-icons"

// to avoid error in unit test
if (process.env.NODE_ENV !== "test") ReactModal.setAppElement("#root")

const Modal = ({ isModalOpen, onToggleModal }: ComponentProps) => (
  <ReactModal
    className="fetch-modal driver-modal"
    contentLabel="Place Modal"
    isOpen={isModalOpen}
    onRequestClose={onToggleModal({})}
  >
    <Container>
      <CrossWrapper>
        <Cross onClick={onToggleModal({})} />
      </CrossWrapper>

      <HeadLine>{`Customer Service`}</HeadLine>

      <PhoneLink href="tel:+1(505)4633824">
        <FontAwesomeIcon icon={faPhone} />

        {`505-GO-FETCH`}
      </PhoneLink>
    </Container>
  </ReactModal>
)

export default Modal

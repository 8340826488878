// @flow
/* eslint-disable max-lines-per-function */

import CustomOrder from "./CustomOrder"
import prepareComponent from "hocs/prepareComponent"
import { useEffect } from "react"

type CustomOrderItem = {|
  +image: string,
  +name: string
|}

type Props = {|
  +items: CustomOrderItem[],
  +onAddCustomOrder: (params: any) => () => void,
  +onSelectImage: (image: string) => void,
  +onSetItems: (items: CustomOrderItem[]) => void,
  +onToggleModal: (options: any) => () => void,
  +selectedImage: string
|}

export type ComponentProps = {|
  +items: CustomOrderItem[],
  +onAddItem: () => void,
  +onChangeItem: (index: number) => (event: SyntheticInputEvent<*>) => void,
  +onConfirm: () => void,
  +onKeyDown: (event: SyntheticKeyboardEvent<*>) => void,
  +onSelectImage: (image: string) => void,
  +onToggleModal: (options: any) => () => void,
  +selectedImage: string
|}

const DEFAULT_ITEM = { image: "", name: "" }
const ONE = 1
const ENTER_KEY = "Enter"

export const usePrepareComponent = ({
  items,
  onAddCustomOrder,
  onSelectImage,
  onSetItems,
  onToggleModal,
  selectedImage
}: Props): ComponentProps => {
  useEffect(() => {
    const newItems = [...items]
    const index = items.length - ONE
    newItems.splice(index, ONE, { ...items[index], image: selectedImage })

    onSetItems(newItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImage])

  const onAddItem = () => {
    const item = items[items.length - ONE]

    if (item.name) {
      onSetItems([...items, DEFAULT_ITEM])

      onAddCustomOrder(item)
    }
  }

  const onChangeItem =
    (index: number) =>
    ({ currentTarget: { value } }: SyntheticInputEvent<*>) => {
      const newItems = [...items]
      newItems.splice(index, ONE, { image: "", name: value })

      onSetItems(newItems)
    }

  const onKeyDown = ({ key }: SyntheticKeyboardEvent<*>) => {
    if (key === ENTER_KEY) {
      onAddItem()
    }
  }

  const onConfirm = () => {
    onAddItem()
    onToggleModal({})()
  }

  return {
    items,
    onAddItem,
    onChangeItem,
    onConfirm,
    onKeyDown,
    onSelectImage,
    onToggleModal,
    selectedImage
  }
}

export default prepareComponent(usePrepareComponent)(CustomOrder)

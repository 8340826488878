// @flow

import { Email, Item, Phone, Wrapper } from "./styled"
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import H1 from "components/typography/H1"
import React from "react"

const ContactUs = () => (
  <Wrapper>
    <H1>{"Contact Us"}</H1>

    <Item>
      <FontAwesomeIcon icon={faPhone} />

      <Phone href="tel:+15054633824">{"Call 505-GO-FETCH"}</Phone>
    </Item>

    <Item>
      <FontAwesomeIcon icon={faEnvelope} />

      <Email href="mailto:support@fetchdelivery.com">
        {"support@fetchdelivery.com"}
      </Email>
    </Item>
  </Wrapper>
)

export default ContactUs

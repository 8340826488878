// @flow
/* eslint-disable no-ternary */

import type { ChatMessage } from "@fetch/frontend"

type MessagesData = {
  +[key: string]: ChatMessage
}

const BASE_PRICE = 100
const DIGITS = 2

const getMessagesData = (
  driverName: string,
  stopName?: string,
  value?: number
): MessagesData => ({
  arrive: {
    id: "0",
    text: "I have arrived with your items."
  },
  driversAvailable: {
    id: "1",
    text: "There are drivers in your area. Waiting for one to accept your order",
    type: "sendMessage"
  },
  hi: {
    id: "2",
    text: `Hi, I am ${driverName} and I will be fetching your order`
  },
  purchase: {
    id: "3",
    text: `Your driver has made a purchase${
      value ? ` of $${(value / BASE_PRICE).toFixed(DIGITS)}` : ""
    }${stopName ? ` at ${stopName}` : ""}`
  },
  thankYou: {
    id: "4",
    text: "Thank you for using Fetch! We have received your order. A driver will contact you soon. You may receive a call or text message",
    type: "sendMessage"
  }
})

export default getMessagesData

// @flow

import { atom, selector, useRecoilValue, useSetRecoilState } from "recoil"

type ScheduledOrderOnly = {|
  +isNoDriversAvailable: boolean
|}

/**
 * The default state for checking if there are no drivers available.
 */
const DEFAULT_IS_SCHEDULED_ORDER_ONLY = {
  isNoDriversAvailable: false
}

/**
 * The atom or changeable/recoil state for checking if there are no drivers available.
 */
const scheduledOrderOnlyState = atom<ScheduledOrderOnly>({
  default: DEFAULT_IS_SCHEDULED_ORDER_ONLY,
  key: "scheduledOrderOnly"
})

/**
 * The selector or derived state for checking if there are no drivers available.
 */
const isScheduledOrderOnlyState = selector<boolean>({
  get: ({ get }) => {
    const { isNoDriversAvailable } = get(scheduledOrderOnlyState)

    return isNoDriversAvailable
  },
  key: "isScheduledOrderOnly"
})

/**
 * The hook for components to use the state.
 *
 * This custom hook allows components to access and modify the state through
 * the returned object.
 */
const useIsScheduledOrderOnly = (): {|
  +isScheduledOrderOnly: boolean,
  +onSetIsNoDriversAvailable: any
|} => {
  const setScheduledOrderOnly = useSetRecoilState<ScheduledOrderOnly>(
    scheduledOrderOnlyState
  )

  const isScheduledOrderOnly = useRecoilValue(isScheduledOrderOnlyState)

  // function to set the isNoDriversAvailable property of the state
  const onSetIsNoDriversAvailable = (newIsNoDriversAvailable: boolean) => {
    // update the state
    setScheduledOrderOnly((previousState) => ({
      ...previousState,
      isNoDriversAvailable: newIsNoDriversAvailable
    }))
  }

  // return the state value and the function to update it.
  return { isScheduledOrderOnly, onSetIsNoDriversAvailable }
}

export default useIsScheduledOrderOnly

// @flow
/* eslint-disable no-ternary */

import type { SavedAddress } from "@fetch/frontend"
import { useState } from "react"

const useSavedAddresses = (): ({
  onSetSavedAddresses: (savedAddresses: SavedAddress[]) => void,
  savedAddresses: SavedAddress[]
}) => {
  const savedAddressesLocalStorage = localStorage.getItem("savedAddresses")
  const [savedAddresses, setSavedAddresses] = useState(
    typeof savedAddressesLocalStorage === "string"
      ? JSON.parse(savedAddressesLocalStorage)
      : []
  )

  const onSetSavedAddresses = (newSavedAddresses: SavedAddress[]) => {
    localStorage.setItem("savedAddresses", JSON.stringify(newSavedAddresses))
    setSavedAddresses(newSavedAddresses)
  }

  return {
    onSetSavedAddresses,
    savedAddresses
  }
}

export default useSavedAddresses

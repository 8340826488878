// @flow

import styled, { type StyledComponent } from "styled-components"
import type { Theme } from "@bluframe/blublocks"
import mediaQueries from "@bluframe/blublocks/mediaQueries"

export const Wrapper: StyledComponent<{||}, Theme, HTMLElement> = styled.div``

export const Container: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  ${mediaQueries.tablet} {
    max-width: 1920px;
    padding: 0 60px;
  }
`

export const SubContainer: StyledComponent<
  {||},
  Theme,
  HTMLElement
> = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
  width: 100%;

  ${mediaQueries.tablet} {
    column-gap: 40px;
    grid-template-columns: 1fr auto;
  }
`

export const LoadingWrapper: StyledComponent<
  {||},
  Theme,
  HTMLElement
> = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
`

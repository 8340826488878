// @flow

import CollapsibleBox from "./CollapsibleBox"
import prepareComponent from "hocs/prepareComponent"
import { useState } from "react"

type Props = {
  +isLoggedIn: boolean,
  +items: any[],
  +name: string,
  +onLogin: () => void,
  +onToggleModal: () => void
}

export type ComponentProps = Props & {
  +items: any[],
  +isLoggedIn: boolean,
  +isToggled: boolean,
  +name: string,
  +onLogin: () => void,
  +onToggleBox: () => void,
  +onToggleModal: () => void
}

const usePrepareComponent = ({
  isLoggedIn,
  items,
  name,
  onLogin,
  onToggleModal
}: Props): ComponentProps => {
  const [isToggled, setIsToggled] = useState(true)

  const onToggleBox = () => {
    setIsToggled(!isToggled)
  }

  return {
    isLoggedIn,
    isToggled,
    items,
    name,
    onLogin,
    onToggleBox,
    onToggleModal
  }
}

export default prepareComponent(usePrepareComponent)(CollapsibleBox)

// @flow

import gql from "graphql-tag"

const CANCEL_SCHEDULED_FETCH = gql`
  mutation cancelScheduledFetch($id: ID!) {
    cancelScheduledFetch(id: $id) {
      fetchId
    }
  }
`

export default CANCEL_SCHEDULED_FETCH

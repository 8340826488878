// @flow

import SearchBar from "./SearchBar"

export type ComponentProps = {
  +onChange?: (ev: SyntheticInputEvent<EventTarget>) => void,
  +placeholder: string,
  +value?: any
}

export default SearchBar

// @flow

import Buttons from "./Buttons"

export type ComponentProps = {
  +noServiceFee?: boolean,
  +reducedServiceFee?: boolean
}

export default Buttons

// @flow
/* eslint-disable max-lines-per-function, max-statements */

import { useCallback, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import CANCEL_ORDER from "mutations/cancelOrder"
import Options from "./Options"
import TagManager from "react-gtm-module"
import prepareComponent from "hocs/prepareComponent"
import { useMutation } from "@apollo/client"

export type Props = {
  +disabled: boolean,
  +isOpen: boolean,
  +onClick: () => void,
  +onConfirmModal?: () => void
}

export type ComponentProps = Props & {
  +disabled: boolean,
  +onCancelOrder: () => void,
  +isDetailsModalOpen: boolean,
  +isModalOpen: boolean,
  +onToggleModal: (options: any) => () => void,
  +onToggleDetailsModal: (options: any) => () => void
}

const DEFAULT_IS_MODAL_OPEN = false
const DEFAULT_IS_DETAILS_MODAL_OPEN = false

const usePrepareComponent = ({
  disabled,
  isOpen,
  onClick,
  onConfirmModal
}: Props): ComponentProps => {
  const [isModalOpen, setIsModalOpen] = useState(DEFAULT_IS_MODAL_OPEN)
  const { id } = useParams()
  const history = useHistory()
  const [cancelOrder, { data, error }] = useMutation(CANCEL_ORDER)
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(
    DEFAULT_IS_DETAILS_MODAL_OPEN
  )

  if (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }

  const onToggleModal = useCallback(
    () => () => {
      setIsModalOpen(!isModalOpen)
    },
    [isModalOpen, setIsModalOpen]
  )

  const onToggleDetailsModal = useCallback(
    () => () => {
      setIsDetailsModalOpen(!isDetailsModalOpen)
    },
    [isDetailsModalOpen, setIsDetailsModalOpen]
  )

  const onCancelOrder = () => {
    if (disabled) {
      TagManager.dataLayer({
        dataLayer: {
          event: "cancelOrder"
        }
      })

      cancelOrder({ variables: { id } })
    }
  }

  useEffect(() => {
    if (data) {
      history.push("/")
    }
  }, [data, history])

  return {
    disabled,
    isDetailsModalOpen,
    isModalOpen,
    isOpen,
    onCancelOrder,
    onClick,
    onConfirmModal,
    onToggleDetailsModal,
    onToggleModal
  }
}

export default prepareComponent(usePrepareComponent)(Options)

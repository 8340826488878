// @flow

import gql from "graphql-tag"

const CANCEL_ORDER = gql`
  mutation cancelOrder($id: ID!) {
    cancelOrder(id: $id) {
      fetchId
    }
  }
`

export default CANCEL_ORDER

// @flow
/* eslint-disable react/no-array-index-key, react/jsx-max-depth, max-lines-per-function, no-ternary, no-constant-binary-expression */

import {
  Box,
  ButtonsWrapper,
  CancelButton,
  Container,
  Content,
  HeadLine,
  IconWrapper,
  ItemsContainer,
  ItemsWrapper,
  MenuItem,
  MenuItemName,
  MenuItemPrice,
  ProgressButton,
  PurchaseSubtotal,
  Subtitle
} from "./styled"
import {
  faDollarSign,
  faMapMarkerAlt,
  faStore
} from "@fortawesome/free-solid-svg-icons"
import CollapsibleBox from "./CollapsibleBox"
import { type ComponentProps } from "."
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import OrderSummary from "./OrderSummary"
import React from "react"
import ReactModal from "react-modal"

// To avoid error in unit tests
if (process.env.NODE_ENV !== "test") ReactModal.setAppElement("#root")

const STATUSES = Object.freeze({
  ARRIVED: "arrived",
  FINISHED: "finished"
})

const DetailsModal = ({
  driverName,
  estimates,
  isDetailsModalOpen,
  lastStopIndex,
  onToggleDetailsModal,
  purchases,
  stops
}: ComponentProps) => (
  <ReactModal
    className="fetch-modal place-modal detail-modal"
    contentLabel="Cancel Order"
    isOpen={isDetailsModalOpen}
    onRequestClose={onToggleDetailsModal({})}
  >
    <Container>
      <HeadLine>{`Details`}</HeadLine>

      {driverName && (
        <Box>
          <Content>
            {/* can insert photo here but first need to search it by imageId */}

            {driverName}
          </Content>
        </Box>
      )}

      {/* Put Purchases back in when we understand them */}
      {Boolean(purchases?.length) && false && (
        <Box>
          <Subtitle>
            <IconWrapper>
              <FontAwesomeIcon icon={faDollarSign} />
            </IconWrapper>

            {`Purchases`}
          </Subtitle>

          <ItemsWrapper>
            <ItemsContainer>
              {purchases.map((purchase) => (
                <MenuItem key={purchase.id}>
                  <MenuItemName>{""}</MenuItemName>

                  <MenuItemPrice>{`${purchase.amount}`}</MenuItemPrice>
                </MenuItem>
              ))}
            </ItemsContainer>
          </ItemsWrapper>

          <PurchaseSubtotal>
            <ItemsWrapper>{"Purchase Subtotal"}</ItemsWrapper>

            {"$0.00"}
          </PurchaseSubtotal>
        </Box>
      )}

      {stops?.map(({ name, items, state }, index) =>
        index < lastStopIndex ? (
          <CollapsibleBox
            icon={faStore}
            instructions={name}
            items={items}
            key={index}
            status={state}
            title={name}
          />
        ) : null
      )}

      <Box>
        <Subtitle>
          <IconWrapper>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
          </IconWrapper>

          {stops?.[lastStopIndex].name}
        </Subtitle>

        <ButtonsWrapper>
          <ProgressButton
            status={
              stops?.[lastStopIndex].state === STATUSES.ARRIVED ||
              stops?.[lastStopIndex].state === STATUSES.FINISHED
            }
          >
            {`Arrived`}
          </ProgressButton>

          <ProgressButton
            status={stops?.[lastStopIndex].state === STATUSES.FINISHED}
          >
            {`Delivered`}
          </ProgressButton>
        </ButtonsWrapper>
      </Box>

      <OrderSummary estimates={estimates} />

      <CancelButton onClick={onToggleDetailsModal({})}>{`Ok`}</CancelButton>
    </Container>
  </ReactModal>
)

export default DetailsModal

// @flow
/* eslint-disable no-ternary, max-lines-per-function, max-statements */

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng
} from "use-places-autocomplete"
import type { Address } from "@fetch/frontend"
import AddressDropdown from "./AddressDropdown"
import { prepareComponent } from "@bluframe/grapple"
import { useMemo } from "react"
import useSavedAddresses from "hooks/useSavedAddresses"

type Props = {|
  +onChange?: any,
  +requestOptions?: any,
  +searchPrefix?: string,
  +placeholder?: string,
  +styles?: any,
  +value?: Address
|}

export type ComponentProps = {|
  +onInputChange: (inputValue: string) => void,
  +onChange?: any,
  +options: any[],
  +placeholder?: string,
  +styles?: any,
  +value?: Address
|}

const FIRST = 0

const INCLUDED_CITIES =
  process.env.NODE_ENV === "development"
    ? [""]
    : ["Santa Fe, NM, USA", "Boulder, CO, USA"]

const COMPONENT_RESTRICTIONS =
  process.env.NODE_ENV === "development" ? ["us", "uk"] : "us"

// eslint-disable-next-line
const defaultBounds = new window.google.maps.LatLngBounds(
  // eslint-disable-next-line
  new window.google.maps.LatLng(35.575371, -106.566494),
  // eslint-disable-next-line
  new window.google.maps.LatLng(40.143837, -104.75375)
)

const usePrepareComponent = ({
  onChange,
  requestOptions,
  placeholder,
  searchPrefix = "",
  styles,
  value
}: Props): ComponentProps => {
  const {
    clearSuggestions,
    ready,
    suggestions: { status, data, loading },
    setValue
  } = usePlacesAutocomplete({
    debounce: 300,
    requestOptions: {
      ...requestOptions,
      bounds: defaultBounds,
      componentRestrictions: { country: COMPONENT_RESTRICTIONS },
      strictBounds: true
    }
  })
  const { savedAddresses } = useSavedAddresses()

  const options: Address[] = useMemo(() => {
    const parsedSavedAddresses = savedAddresses.map((item) => item.address)

    if (status !== "OK") {
      return parsedSavedAddresses
    }

    if (loading || !data || !ready) {
      return parsedSavedAddresses
    }

    const suggestions = data
      .filter(({ description }) =>
        INCLUDED_CITIES.some((city) => description.includes(city))
      )
      .map((item) => ({
        label: item.description,
        value: item.place_id
      }))

    return suggestions
  }, [data, loading, ready, savedAddresses, status])

  const onInputChange = (inputValue: string): void => {
    setValue(`${searchPrefix} ${inputValue}`)
  }

  const handleChange = async (selected: Address) => {
    setValue(selected.label, false)
    clearSuggestions()
    let coordinates = null

    try {
      const results = await getGeocode({ address: selected.label })
      coordinates = await getLatLng(results[FIRST])
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("Error: ", error)
    }

    if (onChange) {
      onChange({
        coordinates,
        label: selected.label,
        value: selected.value
      })
    }
  }

  return {
    onChange: handleChange,
    onInputChange,
    options,
    placeholder,
    styles,
    value
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  AddressDropdown
)

// @flow

import styled, { type StyledComponent } from "styled-components"

export const Form: StyledComponent<any, mixed, HTMLElement> = styled.form``

export const LoadingWrapper: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
`

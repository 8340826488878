// @flow
/* eslint-disable no-ternary */

import mediaQueries from "styles/mediaQueries"

const styles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: 32,
    fontFamily: "Roboto, Arial, open-sans",
    fontWeight: 700,
    height: 45
  }),
  input: (provided: any) => ({
    ...provided,
    "& div": {
      "& input": {
        boxShadow: "none !important"
      }
    },
    "& input": {
      height: "auto",
      margin: 0
    }
  }),
  option: (provided: any) => ({
    ...provided,
    fontFamily: "Roboto, Arial, open-sans",
    fontWeight: 700
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontFamily: "Roboto, Arial, open-sans",
    fontWeight: 300
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    display: state.selectProps.menuIsOpen ? "none" : "block",
    paddingRight: 95,
    [mediaQueries.tablet]: {
      paddingRight: 0
    }
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    paddingLeft: 50
  })
}

export default styles

// @flow
/* eslint-disable sort-imports, max-lines-per-function, max-statements, */

import { useCallback, useEffect, useMemo, useState } from "react"
import { useLazyQuery } from "@apollo/client"
import CallDriver from "./CallDriver"
import GET_FETCH from "queries/getFetch"
import GET_PHONE from "queries/getPhone"
import prepareComponent from "hocs/prepareComponent"

export type Props = {
  +disabled: boolean,
  +fetchId: number
}

export type ComponentProps = {
  +disabled: boolean,
  +isModalOpen: boolean,
  +onToggleModal: any,
  +phoneNumber: string
}

const DEFAULT_IS_MODAL_OPEN = false
const DEFAULT_PHONE_NUMBER = ""

const usePrepareComponent = ({ disabled, fetchId }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(DEFAULT_IS_MODAL_OPEN)
  const [getFetch, { data: fetchData, loading: fetchLoading }] =
    useLazyQuery(GET_FETCH)

  useEffect(() => {
    if (fetchId) {
      getFetch({
        variables: { id: Number(fetchId) }
      })
    }
  }, [getFetch, fetchId, fetchLoading])

  const driverId = useMemo(
    () => fetchData?.fetch.driver?.user.id ?? "",
    [fetchData]
  )

  const [getPhone, { data, error, loading }] = useLazyQuery(GET_PHONE)

  const onToggleModal = useCallback(
    () => () => {
      if (driverId && fetchId) {
        getPhone({
          variables: {
            fetchId: Number(fetchId),
            id: Number(driverId)
          }
        })
      }

      setIsModalOpen(!isModalOpen)
    },
    [getPhone, driverId, fetchId, isModalOpen, setIsModalOpen]
  )

  if (error) {
    return {
      disabled,
      isModalOpen,
      onToggleModal,
      phoneNumber: DEFAULT_PHONE_NUMBER
    }
  }

  if (loading || !data) {
    return {
      disabled,
      isModalOpen,
      onToggleModal,
      phoneNumber: DEFAULT_PHONE_NUMBER
    }
  }

  return {
    disabled,
    isModalOpen,
    onToggleModal,
    phoneNumber: data.phone.phoneNumber
  }
}

export default prepareComponent(usePrepareComponent)(CallDriver)

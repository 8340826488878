// @flow

import { type ComponentProps } from "."
import { Grid } from "./styled"
import Place from "components/Place"
import type { PlaceType } from "@fetch/frontend"
import React from "react"
import SearchBar from "components/SearchBar"
import Wrapper from "components/layout/Wrapper"

const Favourites = ({ partnerPlaces, query }: ComponentProps) => (
  <Wrapper>
    <SearchBar placeholder="Search place, category, or address" value={query} />

    {Boolean(partnerPlaces.length) && (
      <Grid>
        {partnerPlaces.map((place: PlaceType) => (
          <Place {...place} key={place.id} link={`/places/${place.id || ""}`} />
        ))}
      </Grid>
    )}
  </Wrapper>
)

export default Favourites

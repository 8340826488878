// @flow

import styled, { type StyledComponent } from "styled-components"
import BaseButton from "components/buttons/Base"
import colors from "styles/colors"

export const Container: StyledComponent<any, mixed, HTMLElement> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const HeadLine: StyledComponent<any, mixed, HTMLElement> = styled.h2`
  font-family: "Roboto", Arial, open-sans, serif;
  text-align: left;
  font-size: 34px;
  margin-bottom: 20px;

  &:after {
    content: "—";
    display: block;
    width: 10%;
    margin: 0 auto;
    margin-top: -5px;
  }
`

export const Subtitle: StyledComponent<any, mixed, HTMLElement> = styled.h3`
  font-family: "Roboto", Arial, open-sans, serif;
  text-align: left;
  font-size: 28px;
`

export const CancelButton: StyledComponent<any, mixed, any> = styled(
  BaseButton
)`
  display: inline-block;
  text-decoration: none;
  color: ${colors.white};
  cursor: pointer;
  font-size: 13px;
  text-align: center;
  line-height: 38px;
  border-radius: 100px;
  padding: 0 22px;
  transition: all 0.3s;
  background: ${colors.charcoal};
  width: 170px;
  margin-right: 40px;
`

export const CancelOrderButton: StyledComponent<
  any,
  mixed,
  StyledComponent<any, mixed, HTMLElement>
> = styled(CancelButton)`
  background: ${colors.sunsetOrange};
  cursor: pointer;
  margin-right: 10px;
`

export const Buttons: StyledComponent<any, mixed, HTMLElement> = styled.div`
  display: flex;
`

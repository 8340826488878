// @flow

import { Main, Wrapper as WrapperBase } from "./styled"
import { type ComponentProps } from "."
import React from "react"

const Wrapper = ({ children, className }: ComponentProps) => (
  <WrapperBase className={className}>
    <Main>{children}</Main>
  </WrapperBase>
)

export default Wrapper

// @flow

import FetchUnlimited from "./FetchUnlimited"
import SUBSCRIBE from "mutations/subscribe"
import TagManager from "react-gtm-module"
import UNSUBSCRIBE from "mutations/unsubscribe"
import { prepareComponent } from "@bluframe/grapple"
import useLast4 from "hooks/useLast4"
import { useMutation } from "@apollo/client"
import useUser from "hooks/useUser"

type Props = {||}

const DEFAULT_AMOUNT = 0

export type ComponentProps = {|
  +amount: number,
  +hasSubscription: boolean,
  +last4: string,
  +onSubscribe: () => void,
  +onUnsubscribe: () => void,
  +renewsOn?: string,
  +subscribedOn?: string
|}

const usePrepareComponent = (): ComponentProps => {
  const { user } = useUser()
  const { last4 } = useLast4()
  const [subscribe] = useMutation(SUBSCRIBE, { refetchQueries: ["getMe"] })
  const [unsubscribe] = useMutation(UNSUBSCRIBE, { refetchQueries: ["getMe"] })

  const onSubscribe = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "fetchUnlimitedSubscribe",
        user
      }
    })
    subscribe()
  }

  const onUnsubscribe = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "fetchUnlimitedUnsubscribe",
        user
      }
    })

    unsubscribe()
  }

  return {
    amount: user?.subscription?.amount ?? DEFAULT_AMOUNT,
    hasSubscription: user?.hasSubscription ?? false,
    last4,
    onSubscribe,
    onUnsubscribe,
    renewsOn: user?.subscription?.renewsOn,
    subscribedOn: user?.subscription?.subscribedOn
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  FetchUnlimited
)

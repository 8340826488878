// @flow

import Top from "./Top"

export type ComponentProps = {|
  +detail: string,
  +image: string,
  +name: string
|}

export default Top

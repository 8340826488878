// @flow
/* eslint-disable no-ternary */

import { Button, FlexForm, FlexInput, Form, Input, Title } from "./styled"
import { type ComponentProps } from "."
import React from "react"
import Wrapper from "components/layout/Wrapper"

const Profile = ({
  handleSubmit,
  loading,
  onSubmit,
  register
}: ComponentProps) => (
  <Wrapper>
    <Title>{"Edit profile"}</Title>

    <Form onSubmit={handleSubmit(onSubmit)}>
      <FlexForm>
        <FlexInput name="firstName" placeholder="First Name" ref={register} />

        <FlexInput name="lastName" placeholder="Last Name" ref={register} />
      </FlexForm>

      <Input name="email" placeholder="Email" ref={register} />

      <Input name="phoneNumber" placeholder="Phone" ref={register} />

      <Button disabled={loading}>
        {loading ? "Saving changes..." : "Save changes"}
      </Button>
    </Form>
  </Wrapper>
)

export default Profile

// @flow

import gql from "graphql-tag"

const GET_FETCH = gql`
  query getFetch($id: Int!) {
    fetch(id: $id) {
      deliveryFee
      driver {
        id
        user {
          firstName
          id
          photo
        }
      }
      estimatedItemCost
      estimatedTotalCharge
      id
      purchases {
        amount
        id
      }
      serviceFee
      state
      stops {
        name
        state
        items {
          index
          instructions
          label
        }
        instructions
      }
    }
  }
`

export default GET_FETCH

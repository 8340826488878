// @flow

import CarouselWheel from "./CarouselWheel"

export type ComponentProps = {
  +image: string,
  +label: string,
  +link: string,
  +slug: string,
  +title?: string
}

export default CarouselWheel

// @flow
/* eslint-disable max-lines-per-function, react/jsx-max-depth, no-ternary */

import {
  Button,
  Category,
  CustomOrder,
  DesktopImage,
  DetailItem,
  Details,
  FlexWrapper,
  H1,
  Heading,
  Image,
  ServiceFeeLabel,
  Top,
  Wrapper
} from "./styled"
import CollapsibleBox from "./CollapsibleBox"
import type { ComponentProps } from "."
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { Menu } from "@fetch/frontend"
import Option from "./Option"
import React from "react"
import SearchBar from "components/SearchBar"
import Select from "react-select"
import { ReactComponent as Star } from "assets/icons/star.svg"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import styles from "./reactSelectStyles"

const Main = ({
  categories,
  isLoggedIn,
  onChange,
  onLogin,
  onToggleModal,
  options,
  place: { noServiceFee, image, isPartner, address, distance, name, menus }
}: ComponentProps) => (
  <Wrapper>
    <Heading>
      {noServiceFee && (
        <ServiceFeeLabel
          isThereImage={Boolean(image)}
        >{`$0 Service Fee`}</ServiceFeeLabel>
      )}
      {image && <DesktopImage src={image} />}
      {image && <Image src={image} />}

      <Top>
        <H1>
          {isPartner && <Star />}

          {name}
        </H1>

        <Details>
          <DetailItem>{address}</DetailItem>

          <DetailItem>
            {categories}

            {distance && ` • ${distance}`}
          </DetailItem>
        </Details>
      </Top>
    </Heading>

    {Boolean(menus?.length) && (
      <FlexWrapper>
        {isLoggedIn && (
          <SearchBar
            onChange={onChange}
            placeholder="Search for your favorite items"
          />
        )}

        <Select
          components={{ Option }}
          options={options}
          placeholder="Categories"
          styles={styles}
        />
      </FlexWrapper>
    )}

    {isLoggedIn && (
      <Category>
        <CustomOrder onClick={onToggleModal({ customOrderOpen: true })}>
          <Button type="button">
            <FontAwesomeIcon icon={faPlus} />
          </Button>
          {"Add Custom Item"}
        </CustomOrder>
      </Category>
    )}

    {menus?.map(({ id, items, name: menuName }: Menu) => (
      <CollapsibleBox
        id={id}
        isLoggedIn={isLoggedIn}
        items={items}
        key={id}
        name={menuName}
        onLogin={onLogin}
        onToggleModal={onToggleModal}
      />
    ))}
  </Wrapper>
)

export default Main

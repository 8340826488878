// @flow

import { useLazyQuery, useMutation } from "@apollo/client"
import GET_STRIPE_KEYS from "queries/getStripeKeys"
import LOGIN from "mutations/login"
import TagManager from "react-gtm-module"
import VerifyPhone from "./VerifyPhone"
import prepareComponent from "@bluframe/grapple/prepareComponent"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { useLastLocation } from "react-router-last-location"
import useLogin from "hooks/useLogin"

export type Props = {
  +phoneNumber?: string
}

export type ComponentProps = {
  +register: any,
  +handleSubmit: any
}

const PHONE_PROVIDER = "phone"

const usePrepareComponent = ({ phoneNumber }: Props): ComponentProps => {
  const [login, { data: loginData }] = useMutation(LOGIN)
  const { getValues, register, handleSubmit } = useForm()
  const [getStripeKeys, { data: stripeKeysData }] =
    useLazyQuery(GET_STRIPE_KEYS)
  const history = useHistory()
  const lastLocation = useLastLocation()

  const onSubmit = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "verifyPhoneNumber"
      }
    })

    login({
      variables: {
        phoneNumber,
        provider: PHONE_PROVIDER,
        token: getValues("code")
      }
    })
  }

  useLogin({
    getStripeKeys,
    history,
    lastLocation,
    loginData,
    stripeKeysData
  })

  return {
    handleSubmit: handleSubmit(onSubmit),
    register
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  VerifyPhone
)

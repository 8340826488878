// @flow
/* eslint-disable max-lines-per-function */

import {
  Form,
  Image,
  Input,
  Message,
  MessageWrapper,
  Messages,
  Photo,
  PhotoSearch,
  Send,
  Timestamp,
  Title,
  Top
} from "./styled"
import React, { Fragment } from "react"
import { Button } from "@fetch/ui"
import CallDriver from "./CallDriver"
import { type ComponentProps } from "."
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ImageModal from "components/ImageModal"
import Loading from "components/Loading"
import Menu from "./Menu"
import PhotoUpload from "./PhotoUpload"
import Wrapper from "components/layout/Wrapper"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { format } from "date-fns"

const DATE_FORMAT = "h:mm a"
const ONE = 1

const Chat = ({
  disabled,
  driverId,
  fetchId,
  imageSearch,
  isModalOpen,
  messages,
  newMessage,
  onChange,
  onInitiateCall,
  onToggleModal,
  onSelectImage,
  onSubmit,
  onUploadImage,
  setImageSearch
}: ComponentProps) => (
  <Wrapper>
    <Top>
      <Title>{"Chat with your driver"}</Title>

      <Menu disabled={disabled} />
    </Top>

    <Messages>
      {messages.map(({ createdAt, id, image, isMe, photo, text }, index) => (
        <Fragment key={id}>
          {(text || image) && (
            <>
              <MessageWrapper isMe={isMe}>
                {!isMe && photo && index > ONE && <Photo src={photo} />}

                {text && <Message isMe={isMe}>{text}</Message>}
                {image && <Image src={image} />}
              </MessageWrapper>

              {createdAt && (
                <Timestamp isMe={isMe}>
                  {format(new Date(createdAt), DATE_FORMAT)}
                </Timestamp>
              )}
            </>
          )}
        </Fragment>
      ))}

      {disabled && <Loading />}
    </Messages>

    <Form onSubmit={onSubmit}>
      <PhotoUpload disabled={disabled} onUploadImage={onUploadImage} />

      <CallDriver
        disabled={disabled}
        driverId={driverId}
        fetchId={fetchId}
        onInitiateCall={onInitiateCall}
      />

      <PhotoSearch
        disabled={disabled}
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => {
          onToggleModal({ modalOpen: true })()
        }}
        type="button"
      >
        <FontAwesomeIcon icon={faSearch} size="2x" />
      </PhotoSearch>

      <Input disabled={disabled} onChange={onChange} value={newMessage} />

      <Send>
        <Button
          disabled={disabled}
          expanded
          label="Send"
          secondary
          type="submit"
        />
      </Send>

      <ImageModal
        imageSearch={imageSearch}
        isModalOpen={isModalOpen}
        onSelectImage={onSelectImage}
        onToggleModal={onToggleModal}
        setImageSearch={setImageSearch}
      />
    </Form>
  </Wrapper>
)

export default Chat

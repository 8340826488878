// @flow

import styled, { type StyledComponent } from "styled-components"
import BaseButton from "components/buttons/Base"
import type { Theme } from "@bluframe/blublocks"
import colors from "styles/colors"

export const Header: StyledComponent<any, Theme, HTMLElement> = styled.div`
  text-align: center;
`

export const Form: StyledComponent<any, Theme, HTMLElement> = styled.form`
  display: block;
  max-width: 30rem;
  margin: 2rem auto;
`

export const FlexForm: StyledComponent<any, Theme, HTMLElement> = styled.div`
  display: flex;

  @media (max-width: 768px) {
    display: block;
  }
`

const BasicInput = styled.input.attrs({ type: "text" })`
  padding: 0.7rem;
  border-radius: 0.3rem;
  color: ${colors.mineShaft};
  font-weight: bold;
  font-size: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  &:focus {
    outline: none;
  }
`

export const Button: StyledComponent<
  any,
  Theme,
  StyledComponent<any, Theme, any>
> = styled(BaseButton).attrs({
  type: "submit"
})`
  background: ${colors.seance};
  border-radius: 3px;
  color: ${colors.white};
  font-size: 18px;
  height: 45px;
  letter-spacing: 0.27px;
  width: 100%;

  &:disabled {
    background: ${colors.dustyGray};
    cursor: not-allowed;

    &:hover {
      opacity: 1;
    }
  }
`

export const Input: StyledComponent<
  any,
  Theme,
  StyledComponent<any, Theme, HTMLElement>
> = styled(BasicInput)`
  width: 100%;
`

export const FlexInput: StyledComponent<
  any,
  Theme,
  StyledComponent<any, Theme, HTMLElement>
> = styled(BasicInput)`
  flex: 1;
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 768px) {
    margin: 0.3rem 0;
    width: 100%;
  }
`

export const Title: StyledComponent<any, Theme, HTMLElement> = styled.h1`
  text-align: center;
  font-size: 2rem;
  color: ${colors.charcoal};
`

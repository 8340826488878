// @flow

import { useEffect, useMemo } from "react"
import Box from "./Box"
import type { OptionValue } from "@bluframe/blublocks"
import prepareComponent from "@bluframe/grapple/prepareComponent"

type Option = {|
  +id: string,
  +name: string,
  +price: number
|}

type Props = {|
  +isRadio: boolean,
  +onSetTotals: (total: number) => void,
  +options: Option[],
  +selected: any[],
  +onSelectAddon: (
    addonId: string | number,
    isRadio: boolean,
    name: string
  ) => () => void,
  +radioValue: any,
  +title: string
|}

export type ComponentProps = {|
  +isRadio: boolean,
  +options: Option[],
  +selected: any[],
  +onRadioChange: (value: OptionValue) => void,
  +onSelectAddon: (
    addonId: string | number,
    isRadio: boolean,
    name: string
  ) => () => void,
  +radioValue: any,
  +title: string,
  +total: number
|}

const START_PRICE = 0

const usePrepareComponent = ({
  onSetTotals,
  selected,
  ...props
}: Props): ComponentProps => {
  const total = useMemo(
    () =>
      props.options.reduce((prev, next) => {
        if (selected.includes(next.id)) {
          return prev + next.price
        }

        return prev
      }, START_PRICE),
    [props.options, selected]
  )

  useEffect(() => {
    onSetTotals(total)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total])

  const onRadioChange = (value: OptionValue) => {
    // Make that double call prettier PLEASE
    props.onSelectAddon(value, true, props.title)()
  }

  return {
    ...props,
    onRadioChange,
    selected,
    total
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(Box)

// @flow
/* eslint-disable react/jsx-max-depth */

import * as serviceWorker from "./serviceWorker"
import ApolloProvider from "apollo/ApolloProvider"
import App from "./app"
import { BluBlocksProvider } from "@bluframe/blublocks"
import { Elements } from "@stripe/react-stripe-js"
import { HelmetProvider } from "react-helmet-async"
import React from "react"
import ReactDOM from "react-dom"
import { RecoilRoot } from "recoil"
import { BrowserRouter as Router } from "react-router-dom"
import { loadStripe } from "@stripe/stripe-js"
import stripe from "config/stripe"
import { theme } from "@fetch/ui"

const stripePromise = loadStripe(stripe.STRIPE_PUBLISHABLE_KEY)

ReactDOM.render(
  <RecoilRoot>
    <Router>
      <ApolloProvider>
        <Elements stripe={stripePromise}>
          <HelmetProvider>
            <BluBlocksProvider theme={theme}>
              <App />
            </BluBlocksProvider>
          </HelmetProvider>
        </Elements>
      </ApolloProvider>
    </Router>
  </RecoilRoot>,
  document.getElementById("root")
)

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister()

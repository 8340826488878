// @flow

import { useEffect, useState } from "react"
import App from "./App"
import TagManager from "react-gtm-module"
import googleTagManagerConfig from "config/googleTagManager"
import prepareComponent from "hocs/prepareComponent"

const DEFAULT_PHONE_NUMBER = ""

export type ComponentProps = {|
  +onSetPhoneNumber: (data: string) => void,
  +phoneNumber: string
|}

const { ID, environments } = googleTagManagerConfig

const { REACT_APP_ENVIRONMENT_NAME } = process.env

export const usePrepareComponent = (): ComponentProps => {
  const [phoneNumber, setPhoneNumber] = useState(DEFAULT_PHONE_NUMBER)

  const onSetPhoneNumber = (data: string) => {
    setPhoneNumber(data)
  }

  useEffect(() => {
    const options: any = {
      gtmId: ID
    }

    if (
      REACT_APP_ENVIRONMENT_NAME === "development" ||
      REACT_APP_ENVIRONMENT_NAME === "test"
    ) {
      options.auth =
        environments[REACT_APP_ENVIRONMENT_NAME].GTM_AUTH_DEVELOPMENT
      options.preview =
        environments[REACT_APP_ENVIRONMENT_NAME].GTM_PREVIEW_DEVELOPMENT
    }

    TagManager.initialize(options)
  }, [])

  return {
    onSetPhoneNumber,
    phoneNumber
  }
}

export default prepareComponent(usePrepareComponent)(App)

// @flow
/* eslint-disable no-ternary */

import { Form, Grid, LoadingWrapper } from "./styled"
import Card from "components/Card"
import { type ComponentProps } from "."
import { Helmet } from "react-helmet-async"
import Loading from "components/Loading"
import React from "react"
import SearchBar from "components/SearchBar"
import type { SubCategory } from "@fetch/frontend"
import Wrapper from "components/layout/Wrapper"

const SubCategories = ({
  category,
  loading,
  onChange,
  onSubmit,
  subCategories,
  value
}: ComponentProps) =>
  loading ? (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  ) : (
    <>
      <Helmet>
        <title>{`${category.toUpperCase()} | Fetch Delivery`}</title>
      </Helmet>

      <Wrapper>
        <Form onSubmit={onSubmit}>
          <SearchBar
            onChange={onChange}
            placeholder="Search business, category, or address"
            value={value}
          />
        </Form>

        <Grid>
          {subCategories.map(({ image, label, slug }: SubCategory) => (
            <Card
              image={image}
              key={slug}
              label={label}
              link={`${
                label === "Custom Order" || label === "Anything Delivered"
                  ? `/pickup-address`
                  : `/categories/${category}/subcategories/${slug}/places`
              }`}
              slug={slug}
            />
          ))}
        </Grid>
      </Wrapper>
    </>
  )

export default SubCategories

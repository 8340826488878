// @flow

import styled, { type StyledComponent } from "styled-components"
import type { Theme } from "@bluframe/blublocks"
import colors from "styles/colors"
import mediaQueries from "@bluframe/blublocks/mediaQueries"

export const Wrapper: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  align-items: center;
  color: ${colors.charcoal};
  justify-items: center;
  display: grid;
  padding: 20px;
  row-gap: 8px;

  ${mediaQueries.tablet} {
    padding: 40px;
  }
`

export const Title: StyledComponent<{||}, Theme, HTMLElement> = styled.h4`
  margin: 0;
`

export const Subtitle: StyledComponent<{||}, Theme, HTMLElement> = styled.p`
  margin: 0;
`

export const Content: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  display: grid;
  font-size: 20px;
  justify-items: center;
  line-height: 1.5;
  margin: 0;
  padding: 0 20px;
  width: 100%;
`

// @flow
/* eslint-disable react/jsx-max-depth, max-lines-per-function */

import {
  Button,
  ButtonPlusWrapper,
  Confirm,
  Icons,
  Image,
  Item,
  ItemWrapper,
  Items,
  Name,
  Wrapper
} from "./styled"
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons"
import type { ComponentProps } from "."
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

const ONE = 1

const CustomOrder = ({
  items,
  onAddItem,
  onChangeItem,
  onConfirm,
  onKeyDown,
  onSelectImage,
  onToggleModal
}: ComponentProps) => (
  <Wrapper>
    <Name>{"Item / Task List"}</Name>

    <Items>
      {items.map(({ image, name }, index: number) => (
        <ItemWrapper
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          <Item
            autoFocus
            disabled={index !== items.length - ONE}
            onChange={onChangeItem(index)}
            onKeyDown={onKeyDown}
            placeholder="Add item or task"
            value={name}
          />

          <Icons>
            {image && (
              <Image
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => {
                  onSelectImage("")
                }}
                src={image}
              />
            )}

            <Button
              disabled={!name || index !== items.length - ONE}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => {
                if (name) {
                  onToggleModal({ imageSearch: name, modalOpen: true })()
                }
              }}
            >
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </Icons>
        </ItemWrapper>
      ))}
    </Items>
    <ButtonPlusWrapper>
      <Button
        disabled={!items.length || !items[items.length - ONE].name}
        onClick={onAddItem}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    </ButtonPlusWrapper>
    <Confirm onClick={onConfirm}>{"Confirm"}</Confirm>
  </Wrapper>
)

export default CustomOrder

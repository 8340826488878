// @flow

import styled, { type StyledComponent } from "styled-components"
import type { Theme } from "@bluframe/blublocks"
import colors from "styles/colors"
import mediaQueries from "@bluframe/blublocks/mediaQueries"
import { rgba } from "polished"

const ITEM_ALPHA = 0.3

export const Wrapper: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  background-color: ${colors.white};
  border-radius: 3px;
  box-shadow: 1px 1px 10px ${rgba(colors.black, ITEM_ALPHA)};
  cursor: pointer;
  display: flex;
  height: 120px;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0;
  width: 100%;

  ${mediaQueries.tablet} {
    flex: 0 0 auto;
    height: auto;
    padding: 20px;
  }
`

export const Text: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Left: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 20px 10px 10px;

  ${mediaQueries.tablet} {
    padding: 0 20px 0 0;
  }
`

export const Name: StyledComponent<{||}, Theme, HTMLElement> = styled.h3`
  margin: 0;
  text-align: left;
  font-family: Roboto, Arial, open-sans;
  font-size: 16px;
  letter-spacing: 0px;
  color: ${colors.thunder};
`

export const Description: StyledComponent<{||}, Theme, HTMLElement> = styled.p`
  color: ${colors.doveGray};
  font-family: Roboto, Arial, open-sans;
  font-size: 12px;
  letter-spacing: 0px;
  margin-bottom: 3px;
  text-align: left;

  ${mediaQueries.tablet} {
    font-size: 14px;
    margin-bottom: 1rem;
  }
`

export const Price: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  color: ${colors.thunder};
  font-family: Roboto, Arial, open-sans;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
`

export const Image: StyledComponent<{||}, Theme, HTMLElement> = styled.img`
  height: 120px;
  object-fit: cover;
  width: 120px;
`

// @flow
/* eslint-disable max-lines-per-function, react/jsx-max-depth, react/jsx-indent, indent, no-ternary, no-constant-binary-expression */

import {
  Card,
  CardTitle,
  ItemWrapper,
  ItemsWrapper
} from "components/Sidebar/styled"
import {
  LoadingWrapper,
  OrderSummaryItem,
  TotalEstimation,
  Wrapper
} from "./styled"
import { type ComponentProps } from "."
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Loading from "components/Loading"
import React from "react"
import colors from "styles/colors"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"

const FIXED_BASE = 2
const ZERO = 0

const OrderSummary = ({
  couponCode,
  couponDiscountAmount,
  deliveryFee,
  estimatedCreditCardFee,
  estimatedServiceFee,
  estimatedSubtotal,
  estimatedTotal,
  fetchUnlimitedDiscountAmount,
  loading
}: ComponentProps) => (
  <Wrapper>
    <Card>
      {false && <CardTitle>{"Order Summary"}</CardTitle>}

      {loading ? (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      ) : (
        <>
          <ItemsWrapper>
            <OrderSummaryItem>
              <ItemWrapper>
                {"Estimated Subtotal"}

                <FontAwesomeIcon icon={faInfoCircle} />
              </ItemWrapper>

              {`$${estimatedSubtotal.toFixed(FIXED_BASE)}`}
            </OrderSummaryItem>

            {couponCode && couponDiscountAmount && (
              <OrderSummaryItem>
                <ItemWrapper>
                  {`Coupon (${couponCode})`}

                  <FontAwesomeIcon icon={faInfoCircle} />
                </ItemWrapper>

                {`-$${couponDiscountAmount.toFixed(FIXED_BASE)}`}
              </OrderSummaryItem>
            )}

            {Boolean(fetchUnlimitedDiscountAmount) && (
              <OrderSummaryItem>
                <ItemWrapper>
                  <strong>
                    {`Fetch`}{" "}
                    <span
                      style={{ color: colors.eminence }}
                    >{`Unlimited`}</span>
                  </strong>
                </ItemWrapper>

                {`-$${fetchUnlimitedDiscountAmount?.toFixed(FIXED_BASE) ?? ""}`}
              </OrderSummaryItem>
            )}

            <OrderSummaryItem>
              <ItemWrapper>
                {"Delivery Fee"}

                <FontAwesomeIcon icon={faInfoCircle} />
              </ItemWrapper>

              {`$${deliveryFee || ""}`}
            </OrderSummaryItem>

            <OrderSummaryItem>
              <ItemWrapper>
                {"Estimated Service Fee"}

                <FontAwesomeIcon icon={faInfoCircle} />
              </ItemWrapper>

              {`$${estimatedServiceFee || ""}`}
            </OrderSummaryItem>

            <OrderSummaryItem>
              <ItemWrapper>{"Estimated Credit Card Fee"}</ItemWrapper>

              {`$${estimatedCreditCardFee || "0"}`}
            </OrderSummaryItem>
          </ItemsWrapper>

          <TotalEstimation>
            <ItemWrapper>
              {"Estimated Total"}

              <FontAwesomeIcon icon={faInfoCircle} />
            </ItemWrapper>

            {`$${
              estimatedTotal <= ZERO ? "0" : estimatedTotal.toFixed(FIXED_BASE)
            }`}
          </TotalEstimation>
        </>
      )}
    </Card>
  </Wrapper>
)

export default OrderSummary

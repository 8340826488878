// @flow

import { Grid, SeeAll, Top, Wrapper } from "./styled"
import { ReactComponent as ArrowRight } from "assets/icons/arrowRight.svg"
import { type ComponentProps } from "."
import Place from "components/Place"
import type { PlaceType } from "@fetch/frontend"
import React from "react"
import { ReactComponent as Star } from "assets/icons/star.svg"

const Favourites = ({ partnerPlaces }: ComponentProps) => (
  <Wrapper>
    {Boolean(partnerPlaces.length) && (
      <>
        <Top>
          <Star />
          {`Fetch Favorites`}
          <SeeAll to="/categories/no-service-fee/subcategories/no-service-fee/places">
            {`See all`} <ArrowRight />
          </SeeAll>
        </Top>

        <Grid>
          {partnerPlaces.map((place: PlaceType) => (
            <Place
              {...place}
              key={place.id}
              link={`/places/${place.id || ""}`}
            />
          ))}
        </Grid>
      </>
    )}
  </Wrapper>
)

export default Favourites

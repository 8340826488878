// @flow
/* eslint-disable no-ternary */

import { Box, type BoxComponent } from "@fetch/ui"
import styled, { type StyledComponent } from "styled-components"
import H3 from "components/typography/H3"
import H4 from "components/typography/H4"
import type { Theme } from "@bluframe/blublocks"
import colors from "styles/colors"

export const Wrapper: StyledComponent<{||}, Theme, BoxComponent> = styled(Box)`
  grid-template-rows: auto auto 1fr;
`

export const Top: StyledComponent<
  {| +isShowState: boolean |},
  Theme,
  HTMLElement
> = styled.div`
  align-self: flex-start;
  border-bottom: 1px solid ${colors.alto};
  column-gap: 20px;
  display: grid;
  grid-template-columns: ${({ isShowState }) =>
    isShowState ? "1fr auto" : "1fr"};
  width: 100%;
`

export const Main: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  align-self: flex-start;
  display: grid;
  row-gap: 15px;
`

export const Bottom: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  align-self: flex-end;
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`

export const DateAndTime: StyledComponent<
  {||},
  Theme,
  StyledComponent<{||}, Theme, HTMLElement>
> = styled(H3)``

export const State: StyledComponent<
  {||},
  Theme,
  StyledComponent<{||}, Theme, HTMLElement>
> = styled(H3)`
  color: ${colors.sunsetOrange};
  font-weight: 400;
  justify-self: flex-end;
`

export const Order: StyledComponent<
  {||},
  Theme,
  StyledComponent<{||}, Theme, HTMLElement>
> = styled(H4)``

export const Recurring: StyledComponent<
  {||},
  Theme,
  StyledComponent<{||}, Theme, HTMLElement>
> = styled(H4)``

export const Stops: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  border-bottom: 1px solid ${colors.alto};
`

export const Stop: StyledComponent<{||}, Theme, HTMLElement> = styled.div``

export const StopName: StyledComponent<{||}, Theme, HTMLElement> = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
`

export const StopInstructions: StyledComponent<
  {||},
  Theme,
  HTMLElement
> = styled.p`
  color: ${colors.sunsetOrange};
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
`

export const StopItem: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  margin-bottom: 5px;
`

export const StopItemLabel: StyledComponent<
  {||},
  Theme,
  HTMLElement
> = styled.p`
  font-size: 15px;
`

export const Section: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  border-bottom: 1px solid ${colors.alto};
`

export const FetchedBy: StyledComponent<{||}, Theme, HTMLElement> = styled.p`
  font-weight: 700;
  margin-bottom: 5px;
`

export const Total: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  display: flex;
`

export const TotalAmount: StyledComponent<{||}, Theme, HTMLElement> = styled.p`
  margin: 0;
`

export const TotalLabel: StyledComponent<{||}, Theme, HTMLElement> = styled.p`
  font-weight: 700;
  margin: 0 5px 0 0;
`

// @flow
/* eslint-disable max-statements, max-lines-per-function */

import type {
  AddItemToOrderFetch,
  DeleteItemFetch,
  FetchRouteState,
  RemovePlaceFetch,
  SetPlaceFetch
} from "@fetch/frontend"
import { atom, useRecoilState, useResetRecoilState } from "recoil"
import { useCallback, useEffect } from "react"
import localStorageEffect from "effects/localStorage"
import { useLocation } from "react-router-dom"
import usePrevious from "hooks/usePrevious"

const FETCH_STATE: FetchRouteState = {
  stops: []
}

const fetchState = atom<FetchRouteState>({
  default: FETCH_STATE,
  // eslint-disable-next-line camelcase
  effects_UNSTABLE: [localStorageEffect("fetchState")],
  key: "fetchState"
})

const START = 0
const ONE = 1
const ZERO = 0

const addItemToOrder = (
  state: FetchRouteState,
  payload: AddItemToOrderFetch
): FetchRouteState => {
  const currentStop = state.stops.find(
    ({ name, placeId }) =>
      placeId === payload.placeId ||
      placeId === Number(payload.placeId) ||
      name === payload.name
  )
  const currentStopIndex = state.stops.findIndex(
    ({ name, placeId }) =>
      placeId === payload.placeId ||
      placeId === Number(payload.placeId) ||
      name === payload.name
  )

  if (!currentStop || currentStopIndex < ZERO) {
    return state
  }

  const newStop = {
    ...currentStop,
    items: [...currentStop.items, ...payload.menuItem]
  }

  return {
    ...state,
    stops: [
      ...state.stops.slice(START, currentStopIndex),
      newStop,
      ...state.stops.slice(currentStopIndex + ONE)
    ]
  }
}

const deleteItem = (
  state: FetchRouteState,
  payload: DeleteItemFetch
): FetchRouteState => {
  const currentStop = state.stops.find(
    ({ name, placeId }) => placeId === payload.placeId || name === payload.name
  )

  const currentStopIndex = state.stops.findIndex(
    ({ name, placeId }) => placeId === payload.placeId || name === payload.name
  )

  if (!currentStop || currentStopIndex < ZERO) {
    return state
  }

  const newStop = {
    ...currentStop,
    items: (currentStop.items.filter(
      (__, index): boolean => index !== payload.itemIndex
    ) ?? []: any[])
  }

  return {
    ...state,
    stops: [
      ...state.stops.slice(START, currentStopIndex),
      newStop,
      ...state.stops.slice(currentStopIndex + ONE)
    ]
  }
}

const setPlace = (
  state: FetchRouteState,
  payload: SetPlaceFetch
): FetchRouteState => ({
  ...state,
  stops: [
    ...state.stops,
    {
      distance: payload.distance,
      index: state.stops.length,
      items: [],
      latitude: payload.latitude,
      longitude: payload.longitude,
      name: payload.name,
      placeId: payload.placeId
    }
  ]
})

const removePlace = (
  state: FetchRouteState,
  payload: RemovePlaceFetch
): FetchRouteState => ({
  ...state,
  stops: [
    ...state.stops.slice(START, payload.index),
    ...state.stops
      .slice(payload.index + ONE)
      // correct indexes so fetch stop is correct
      .map((stop) => ({ ...stop, index: stop.index - ONE }))
  ]
})

const useFetchState = () => {
  const [fetch, setFetch] = useRecoilState(fetchState)
  const onResetFetchState = useResetRecoilState(fetchState)

  const onAddItemToOrder = useCallback(
    (payload: AddItemToOrderFetch) => {
      setFetch(addItemToOrder(fetch, payload))
    },
    [fetch, setFetch]
  )

  const onDeleteItem = (payload: DeleteItemFetch) => {
    setFetch(deleteItem(fetch, payload))
  }

  const onSetPlace = (payload: SetPlaceFetch) => {
    setFetch(setPlace(fetch, payload))
  }

  const onRemovePlace = useCallback(
    (payload: RemovePlaceFetch) => {
      setFetch(removePlace(fetch, payload))
    },
    [fetch, setFetch]
  )

  const location = useLocation()
  const prevLocation = usePrevious(location)

  // If we leave a place without adding items
  // we remove the stop
  useEffect(() => {
    const index = fetch.stops.length - ONE

    return () => {
      if (
        location !== prevLocation &&
        fetch.stops.length &&
        fetch.stops[index]?.items.length === ZERO
      ) {
        onRemovePlace({ index })
      }
    }
  }, [fetch.stops, location, onRemovePlace, prevLocation])

  return {
    fetch,
    onAddItemToOrder,
    onDeleteItem,
    onRemovePlace,
    onResetFetchState,
    onSetPlace
  }
}

export default useFetchState

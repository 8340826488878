// @flow
/* eslint-disable react/jsx-max-depth, max-lines-per-function, react/jsx-no-bind, react/jsx-indent-props, indent */

import "styles/global.scss"
import { GlobalStyle, Wrapper } from "./styled"
import About from "screens/About"
import Categories from "screens/Categories"
import Chat from "screens/Chat"
import { type ComponentProps } from "."
import ContactUs from "screens/ContactUs"
import CustomPlace from "screens/CustomPlace"
import DropOffAddress from "screens/DropOffAddress"
import Favourites from "screens/Favourites"
import FetchUnlimited from "screens/FetchUnlimited"
import Header from "components/Header"
import { LastLocationProvider } from "react-router-last-location"
import Login from "screens/Login"
import Modal from "components/Modal"
import Payment from "screens/Payment"
import PickUpAddress from "screens/PickUpAddress"
import Place from "screens/Place"
import Places from "screens/Places"
import Profile from "screens/Profile"
import React from "react"
import Route from "components/Route"
import SavedAddresses from "screens/SavedAddresses"
import SubCategories from "screens/SubCategories"
import { Switch } from "react-router-dom"
import Tip from "screens/Tip"
import VerifyPhone from "screens/VerifyPhone"
import YourOrders from "screens/YourOrders"

const App = ({ phoneNumber, onSetPhoneNumber }: ComponentProps) => (
  <>
    <GlobalStyle />

    <LastLocationProvider>
      <Header />

      <Wrapper>
        <Switch>
          <Route component={CustomPlace} isLoggedInOnly path="/place/custom" />

          <Route component={Payment} isLoggedInOnly path="/payment" />

          <Route component={PickUpAddress} path="/pickup-address" />

          <Route
            // eslint-disable-next-line
            component={() => <VerifyPhone phoneNumber={phoneNumber} />}
            exact
            path="/login/verifyPhone"
          />

          <Route
            // eslint-disable-next-line
            component={() => <Login onSetPhoneNumber={onSetPhoneNumber} />}
            path="/login"
          />

          <Route component={DropOffAddress} path="/dropoff-address" />

          <Route component={About} path="/about" />

          <Route component={ContactUs} path="/contact-us" />

          <Route component={Profile} isLoggedInOnly path="/profile" />

          <Route component={Place} path="/places/:id" skipDropOffAddress />

          <Route
            component={Favourites}
            path="/categories/no-service-fee/subcategories/no-service-fee/places"
          />

          <Route
            component={Places}
            isLoggedInOnly
            path="/categories/:category/subcategories/:subcategory/places"
          />

          <Route
            component={SubCategories}
            path="/categories/:category/subcategories"
          />

          <Route component={Chat} isLoggedInOnly path="/chat/:id" />

          <Route component={Tip} isLoggedInOnly path="/tip/:id" />

          <Route component={YourOrders} isLoggedInOnly path="/orders" />

          <Route component={Categories} exact isLoggedInOnly path="/" />

          <Route
            component={SavedAddresses}
            isLoggedInOnly
            path="/saved-addresses"
          />

          <Route
            component={FetchUnlimited}
            exact
            isLoggedInOnly
            path="/fetch-unlimited"
          />
        </Switch>
      </Wrapper>

      <Modal />
    </LastLocationProvider>
  </>
)

export default App

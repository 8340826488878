// @flow

import gql from "graphql-tag"

const GET_SUBCATEGORIES = gql`
  query getSubCategories($category: String!) {
    subCategories(category: $category) {
      image
      label
      slug
    }
  }
`

export default GET_SUBCATEGORIES

// @flow
/* eslint-disable no-ternary */

import styled, { type StyledComponent, css } from "styled-components"
import Paragraph from "components/typography/Paragraph"
import type { Theme } from "@bluframe/blublocks"
import colors from "styles/colors"
import mediaQueries from "styles/mediaQueries"

export const Items: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 20px;
  row-gap: 20px;

  ${mediaQueries.tablet} {
    column-gap: 40px;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 40px;
    row-gap: 40px;
  }
`

export const Tabs: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  display: flex;
  margin-bottom: 10px;
`

export const Tab: StyledComponent<
  { active: boolean },
  Theme,
  HTMLElement
> = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: ${({ active }) => (active ? "bold" : "normal")};
  padding: 10px;
  text-align: center;
  transition: font-weight 0.3s;

  &:hover {
    font-weight: bold;
  }

  ${({ active }) =>
    active &&
    css`
      border-bottom: 2px solid currentColor;
    `}
`

export const EmptyMessage: StyledComponent<
  {||},
  Theme,
  StyledComponent<{||}, Theme, HTMLParagraphElement>
> = styled(Paragraph)`
  color: ${colors.alto};
  font-size: 18px;
  font-weight: 700;
`

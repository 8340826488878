// @flow

import styled, { type StyledComponent } from "styled-components"
import colors from "styles/colors"

export const Container: StyledComponent<any, mixed, HTMLElement> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 60px;
`

export const HeadLine: StyledComponent<any, mixed, HTMLElement> = styled.h2`
  font-family: "Roboto", Arial, open-sans, serif;
  font-size: 34px;
  margin-bottom: 20px;
  text-align: center;

  &:after {
    content: "—";
    display: block;
    width: 10%;
    margin: 0 auto;
    margin-top: -5px;
  }
`

export const SubmitButton: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.input.attrs({ type: "submit" })`
  background: ${colors.thunder} 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 3px;
  color: ${colors.white};
  cursor: pointer;
  font-family: Roboto, Arial, open-sans;
  font-size: 20px;
  height: 45px;
  letter-spacing: 0.27px;
  text-align: center;
  background: ${colors.thunder} 0% 0% no-repeat;
  width: 100%;
  padding: 5px 10px;
  margin-right: 20px;
`

export const CancelButton: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.button`
  background: ${colors.thunder} 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 3px;
  color: ${colors.white};
  cursor: pointer;
  font-family: Roboto, Arial, open-sans;
  font-size: 20px;
  height: 45px;
  letter-spacing: 0.27px;
  text-align: center;
  outline: none;
  padding: 5px 10px;
  margin-left: 20px;
  width: 100%;
`

export const Buttons: StyledComponent<any, mixed, HTMLElement> = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 40px;
`

// @flow

import styled, { type StyledComponent } from "styled-components"
import colors from "styles/colors"
import { rgba } from "polished"

const ITEM_ALPHA = 0.3

export const OrderSummaryItem: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;
  font-family: Roboto, Arial, open-sans;
  font-size: 12px;
  letter-spacing: 0px;
  color: ${colors.doveGray};
  padding-top: 5px;
  padding-bottom: 5px;

  &:first-of-type {
    border-top: 1px solid ${colors.bonJour};
    padding-top: 15px;
  }

  &:last-of-type {
    margin-bottom: 5px;
    border-bottom: 1px solid ${colors.bonJour};
  }

  & svg {
    margin-left: 5px;
  }
`

export const TotalEstimation: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;
  font-family: Roboto, Arial, open-sans;
  font-size: 16px;
  letter-spacing: 0px;
  color: ${colors.doveGray};
  margin-top: 5px;

  svg {
    margin-left: 5px;
  }
`

export const Card: StyledComponent<any, mixed, HTMLElement> = styled.div`
  background: ${colors.white} 0% 0% no-repeat padding-box;
  box-shadow: 1px 1px 10px ${rgba(colors.black, ITEM_ALPHA)};
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 20px;
  width: 80%;

  @media only screen and (min-width: 768px) {
    padding: 25px;
  }
`

export const CardTitle: StyledComponent<any, mixed, HTMLElement> = styled.h3`
  font-family: "Roboto", Arial, open-sans, serif;
  text-align: center;
  font-size: 22px;
  letter-spacing: 0px;
  color: ${colors.thunder};
  margin: 0 0 14px;
`

export const ItemWrapper: StyledComponent<any, mixed, HTMLElement> = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`

export const ItemsWrapper: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  display: flex;
  flex-direction: column;
`

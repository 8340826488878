// @flow
/* eslint-disable no-ternary, no-nested-ternary */

import styled, { type StyledComponent } from "styled-components"
import BaseButton from "components/buttons/Base"
import colors from "styles/colors"
import mediaQueries from "styles/mediaQueries"

export const Wrapper: StyledComponent<any, mixed, HTMLElement> = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.alto};
  border-radius: 3px;
  box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.5);
  color: ${colors.mineShaft};
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  padding: 20px 25px;
  position: absolute;
  text-align: left;
  width: 300px;
  font-family: "Roboto Condensed", Arial, open-sans;
  top: 50%;
  right: -215%;

  ${mediaQueries.desktop} {
    top: 25px;
    right: -115px;
  }
`

export const Arrow: StyledComponent<any, mixed, HTMLElement> = styled.i`
  border: 9px solid transparent;
  bottom: 100%;
  font-size: 0;
  height: 0;
  position: absolute;
  right: 135px;
  width: 0;
`

export const ArrowBefore: StyledComponent<
  any,
  mixed,
  StyledComponent<any, mixed, HTMLElement>
> = styled(Arrow)`
  border-bottom-color: ${colors.bonJour};
  margin-right: 0;
`

export const ArrowAfter: StyledComponent<
  any,
  mixed,
  StyledComponent<any, mixed, HTMLElement>
> = styled(Arrow)`
  border-bottom-color: ${colors.white};
  margin-bottom: -0.5px;
  margin-right: 1px;
`

export const Header: StyledComponent<any, mixed, HTMLElement> = styled.div`
  color: ${colors.mineShaft};
  font-size: 18px;
  text-align: center;
  position: relative;
`

export const Divider: StyledComponent<any, mixed, HTMLElement> = styled.hr`
  border: 0;
  border-top: 1px solid ${colors.cararra};
  box-sizing: content-box;
  height: 0;
  margin-bottom: 20px;
  margin-top: 10px;
`

export const Navigation: StyledComponent<any, mixed, HTMLElement> = styled.ul`
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;

  &:before {
    display: table;
    content: " ";
  }
`

export const NavigationItem: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.li`
  position: relative;
  display: flex;
  justify-content: center;
`

export const IconWrapper: StyledComponent<any, mixed, HTMLElement> = styled.div`
  position: absolute;
  top: -7px;
  left: -7px;

  &:hover {
    cursor: pointer;
  }
`

export const Input: StyledComponent<any, mixed, HTMLElement> = styled.input`
  height: 2rem;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  padding: 1rem;
  outline: none;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  -moz-appearance: textfield;
`

export const ButtonsWrapper: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  display: flex;
`

export const CancelButton: StyledComponent<any, mixed, any> = styled(
  BaseButton
)`
  display: inline-block;
  text-decoration: none;
  color: ${colors.white};
  font-size: 14px;
  text-align: center;
  line-height: 38px;
  border-radius: 100px;
  padding: 0 20px;
  transition: all 0.3s;
  background: ${colors.charcoal};
  width: 170px;
  margin-right: 40px;
  margin-top: 10px;
`

export const OkButton: StyledComponent<
  any,
  mixed,
  StyledComponent<any, mixed, HTMLElement>
> = styled(CancelButton)`
  margin-right: 0px;
  background: ${colors.driftwood};
`

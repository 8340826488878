// @flow

import Modal from "./Modal"

export type ComponentProps = {
  +isModalOpen: boolean,
  +onToggleModal: (options: any) => () => void,
  +phoneNumber: string
}

export default Modal

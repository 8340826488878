//  @flow
/* eslint-disable no-ternary */

import styled, { type StyledComponent } from "styled-components"
import colors from "styles/colors"
import mediaQueries from "styles/mediaQueries"

export const Wrapper: StyledComponent<any, mixed, HTMLElement> = styled.div``

export const Card: StyledComponent<any, mixed, HTMLElement> = styled.div`
  background: ${colors.white} 0% 0% no-repeat padding-box;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ItemsWrapper: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${mediaQueries.tablet} {
    width: auto;
  }
`

export const Item: StyledComponent<any, mixed, HTMLElement> = styled.div`
  align-items: center;
  display: flex;
  text-align: left;
  font-family: Roboto, Arial, open-sans;
  font-size: 14px;
  letter-spacing: 0px;
  color: ${colors.doveGray};
  ${({ isClickable }) => isClickable && "cursor: pointer;"};
  padding-top: 5px;
  padding-bottom: 5px;

  svg {
    padding-right: 5px;
    width: 26px !important;
  }

  &:first-of-type {
    border-bottom: 1px solid ${colors.bonJour};
  }

  &:nth-of-type(2) {
    margin-top: 15px;
  }
`

export const Title: StyledComponent<any, mixed, HTMLElement> = styled.h1`
  font-family: Roboto, Arial, open-sans;
  font-size: 26px;
  letter-spacing: 0px;
  color: ${colors.thunder};
  margin: 20px 0;
`

export const Subtitle: StyledComponent<any, mixed, HTMLElement> = styled.h2`
  color: ${colors.thunder};
  font-size: 20px;
`

export const Container: StyledComponent<any, mixed, HTMLElement> = styled.div`
  ${mediaQueries.tablet} {
    width: 400px;
  }
`

export const LoadingWrapper: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: ${({ noMarginTop }) => (noMarginTop ? "0" : "200px")};
  height: 50px;
  width: 100%;
`

// @flow

import styled, { type StyledComponent } from "styled-components"

export const Grid: StyledComponent<any, mixed, HTMLElement> = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-top: 40px;
  grid-gap: 30px;

  @media (max-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 660px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 360px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const Form: StyledComponent<any, mixed, HTMLElement> = styled.form``

export const LoadingWrapper: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
`

// @flow

import { atom, useRecoilState } from "recoil"
import type { ErrorButton } from "@fetch/frontend"
import equals from "ramda/src/equals"
import { useCallback } from "react"

type Modal = {|
  +content: string | React$Node,
  +customButtons?: ErrorButton[],
  +isOpen: boolean,
  +okayLabel?: string,
  +onOkay?: () => void,
  +showCancel?: boolean,
  +showOkay?: boolean,
  +showHome?: boolean,
  +showRefresh?: boolean,
  +title: string
|}

const DEFAULT_MODAL = {
  content: "",
  isOpen: false,
  title: ""
}

const modalState = atom<Modal>({
  default: DEFAULT_MODAL,
  key: "modal"
})

const useModal = () => {
  const [modal, setModal] = useRecoilState(modalState)

  const onSetModal = useCallback(
    (newModal: Modal) => {
      if (!equals(modal, newModal)) {
        setModal(newModal)
      }
    },
    [modal, setModal]
  )

  // Toggle modal open state
  const onToggleModal = useCallback(() => {
    setModal((present) => ({
      ...present,
      isOpen: !modal.isOpen
    }))
  }, [modal.isOpen, setModal])

  return { modal, onSetModal, onToggleModal }
}

export default useModal

// @flow

import { type ComponentProps } from "."
import React from "react"
import { Wrapper } from "./styled"

const Button = ({ buttonType, label }: ComponentProps) => (
  <Wrapper buttonType={buttonType}>{label}</Wrapper>
)

export default Button

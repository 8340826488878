// @flow

import styled, { type StyledComponent } from "styled-components"
import { Link } from "react-router-dom"
import colors from "styles/colors"
import mediaQueries from "styles/mediaQueries"

export const Wrapper: StyledComponent<any, mixed, any> = styled(Link)`
  align-items: center;
  display: flex;
  text-decoration: none;

  & svg {
    color: ${colors.charcoal};
    font-size: 18px;

    ${mediaQueries.tablet} {
      display: none;
    }
  }
`

export const Text: StyledComponent<any, mixed, HTMLElement> = styled.h2`
  display: none;

  ${mediaQueries.tablet} {
    display: block;
    color: ${colors.charcoal};
    font-size: 16px;
    white-space: nowrap;
    margin: 0 0 0 20px;
  }
`

export const Circle: StyledComponent<any, mixed, HTMLElement> = styled.div`
  align-items: center;
  background: ${colors.seance};
  border-radius: 50%;
  display: flex;
  height: 18px;
  justify-content: center;
  margin-left: 5px;
  width: 18px;
`

export const Number: StyledComponent<any, mixed, HTMLElement> = styled.p`
  color: ${colors.white};
  font-size: 12px;
  margin: 0;
`

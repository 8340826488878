// @flow

import gql from "graphql-tag"

const GET_FETCH_SCHEDULED = gql`
  query fetchScheduled {
    fetchScheduled {
      dateTime
      estimatedTotal
      id
      nextFetchDate
      recurring
      state
      stops {
        index
        instructions
        items {
          estimatedCost
          index
          label
        }
        latitude
        longitude
        name
        placeId
      }
    }
  }
`

export default GET_FETCH_SCHEDULED

// @flow
/* eslint-disable max-lines-per-function */

import type {
  DeliveryTimeNowOrLater,
  DeliveryTimeOneOffOrRecurring,
  DeliveryTimeRecurring,
  DeliveryTimeState
} from "@fetch/frontend"
import { atom, useRecoilState, useResetRecoilState } from "recoil"
import { useCallback, useMemo } from "react"
import format from "date-fns/format"
import localStorageEffect from "effects/localStorage"

const DELIVERY_TIME_STATE: DeliveryTimeState = {
  deliveryDate: null,
  deliveryTime: null,
  nowOrLater: "later",
  oneOffOrRecurring: "oneOff",
  recurring: "everyWeek"
}

const deliveryTimeState = atom<DeliveryTimeState>({
  default: DELIVERY_TIME_STATE,
  // eslint-disable-next-line camelcase
  effects_UNSTABLE: [localStorageEffect("deliveryTimeState")],
  key: "deliveryTimeState"
})

const DATE_FORMAT = "MMM do"
const TIME_FORMAT = "h:mm a"

const useDeliveryTime = () => {
  const [deliveryTime, setDeliveryTime] = useRecoilState(deliveryTimeState)
  const onResetDeliveryTimeState = useResetRecoilState(deliveryTimeState)

  const onSetDeliveryDate = useCallback(
    (date: number | string | null) => {
      if (date === null || typeof date === "string") {
        setDeliveryTime((state) => ({ ...state, deliveryDate: date }))
      }
    },
    [setDeliveryTime]
  )

  const onSetDeliveryTime = useCallback(
    (time: number | string | null) => {
      if (time === null || typeof time === "string") {
        setDeliveryTime((state) => ({ ...state, deliveryTime: time }))
      }
    },
    [setDeliveryTime]
  )

  const onSetNowOrLater = useCallback(
    (nowOrLater: DeliveryTimeNowOrLater) => {
      setDeliveryTime((state) => ({ ...state, nowOrLater }))
    },
    [setDeliveryTime]
  )

  const onSetOneOffOrRecurring = useCallback(
    (oneOffOrRecurring: DeliveryTimeOneOffOrRecurring) => {
      setDeliveryTime((state) => ({ ...state, oneOffOrRecurring }))
    },
    [setDeliveryTime]
  )

  const onSetRecurring = useCallback(
    (recurring: DeliveryTimeRecurring) => {
      setDeliveryTime((state) => ({ ...state, recurring }))
    },
    [setDeliveryTime]
  )

  const formattedDeliveryDateTime = useMemo(() => {
    if (deliveryTime.deliveryDate && deliveryTime.deliveryTime) {
      const dateTime = new Date(
        `${deliveryTime.deliveryDate}T${deliveryTime.deliveryTime}:00`
      )
      const date = format(dateTime, DATE_FORMAT)
      const time = format(dateTime, TIME_FORMAT)

      return `on ${date} at ${time}`
    }

    return null
  }, [deliveryTime.deliveryDate, deliveryTime.deliveryTime])

  return {
    deliveryTime,
    formattedDeliveryDateTime,
    onResetDeliveryTimeState,
    onSetDeliveryDate,
    onSetDeliveryTime,
    onSetNowOrLater,
    onSetOneOffOrRecurring,
    onSetRecurring
  }
}

export default useDeliveryTime

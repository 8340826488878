// @flow

import styled, { type StyledComponent } from "styled-components"
import ResetButton from "components/buttons/Reset"
import type { Theme } from "@bluframe/blublocks"
import colors from "styles/colors"
import mediaQueries from "styles/mediaQueries"
import { rgba } from "polished"

const ITEM_ALPHA = 0.3

export const Wrapper: StyledComponent<any, Theme, HTMLElement> = styled.div`
  padding-bottom: 45px;

  ${mediaQueries.tablet} {
    padding-bottom: 0;
  }
`

export const Container: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  ${mediaQueries.tablet} {
    max-width: 1920px;
    padding: 0 60px;
  }
`

export const Heading: StyledComponent<any, Theme, HTMLElement> = styled.div`
  align-items: center;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid ${colors.whiteRock};
  padding-bottom: 20px;

  ${mediaQueries.desktop} {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    margin: 40px 0 20px;
  }
`

export const Details: StyledComponent<any, Theme, HTMLElement> = styled.div`
  font-family: Roboto, Arial, open-sans;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${colors.doveGray};
  text-align: center;

  ${mediaQueries.tablet} {
    text-align: left;
  }

  ${mediaQueries.desktop} {
    font-size: 16px;
  }
`

export const DetailItem: StyledComponent<any, Theme, HTMLElement> = styled.p`
  margin: 10px 0 0;
`

export const Main: StyledComponent<any, Theme, HTMLElement> = styled.div`
  padding: 20px 20px 0;

  ${mediaQueries.tablet} {
    padding: 20px 40px 0;
    width: calc(100% - 350px);
  }
`

export const Top: StyledComponent<any, Theme, HTMLElement> = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaQueries.desktop} {
    display: none;
  }
`

export const Name: StyledComponent<any, Theme, HTMLElement> = styled.h1`
  color: ${colors.thunder};
  font-family: Roboto, Arial, open-sans;
  font-size: 20px;
  font-weight: 600;
  margin-right: 20px;
  text-align: center;

  ${mediaQueries.tablet} {
    text-align: left;
  }

  ${mediaQueries.desktop} {
    font-size: 28px;
    margin: 0;
  }
`

export const Image: StyledComponent<any, Theme, HTMLElement> = styled.img`
  height: 240px;
  object-fit: cover;
  width: 100%;

  ${mediaQueries.desktop} {
    display: none;
  }
`

export const DesktopImage: StyledComponent<
  any,
  Theme,
  HTMLElement
> = styled.img`
  display: none;

  ${mediaQueries.desktop} {
    display: block;
    height: 244px;
    object-fit: cover;
    width: 330px;
  }
`

export const CategoryName: StyledComponent<any, Theme, HTMLElement> = styled.h2`
  color: ${colors.thunder};
  font-family: Roboto, Arial, open-sans;
  font-weight: 600;
  margin: 20px 0;
  padding: 0;

  ${mediaQueries.desktop} {
    padding: 0;
  }

  @media only screen and (max-width: 767px) {
    text-align: center;
  }
`

export const Category: StyledComponent<any, Theme, HTMLElement> = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;

  ${mediaQueries.desktop} {
    padding: 0;
  }
`

export const ImageSearch: StyledComponent<any, Theme, HTMLElement> = styled.div`
  font-family: Roboto, Arial, open-sans;
  margin: 40px;
`

export const Button: StyledComponent<
  any,
  Theme,
  StyledComponent<any, Theme, HTMLElement>
> = styled(ResetButton)`
  cursor: pointer;

  & svg {
    color: ${colors.driftwood};
    font-size: 22px;
  }
`

export const CustomOrder: StyledComponent<any, Theme, HTMLElement> = styled.div`
  align-items: center;
  background-color: ${colors.white};
  box-shadow: 1px 1px 10px ${rgba(colors.black, ITEM_ALPHA)};
  border-radius: 3px;
  color: ${colors.driftwood};
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
  padding: 10px 20px;
  width: 100%;

  & button {
    margin-right: 10px;
  }

  ${mediaQueries.tablet} {
    flex: 0 0 auto;
    width: 330px;
  }
`

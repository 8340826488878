// @flow
/* eslint-disable max-statements, max-lines-per-function */

import { useEffect, useMemo, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import GET_ACTIONS from "queries/getActions"
import GET_FETCH from "queries/getFetch"
import useModal from "hooks/useModal"
import { useQuery } from "@apollo/client"

const ACTIONS_INTERVAL = 5000
const FETCH_INTERVAL = 30000
const DEFAULT_DISABLED = true

const useActions = () => {
  const { id } = useParams()
  const history = useHistory()
  const [disabled, setDisabled] = useState(DEFAULT_DISABLED)
  const { data: actionsData, refetch: actionsRefetch } = useQuery(GET_ACTIONS, {
    variables: { id: Number(id) }
  })

  const { data: fetchData, refetch: fetchRefetch } = useQuery(GET_FETCH, {
    variables: { id: Number(id) }
  })
  const { onSetModal } = useModal()

  useEffect(() => {
    // Get actions on interval
    const intervalId = setInterval(() => {
      actionsRefetch()
    }, ACTIONS_INTERVAL)

    return () => {
      clearInterval(intervalId)
    }
  }, [actionsRefetch])

  useEffect(() => {
    // Get Fetch data on interval
    const intervalId = setInterval(() => {
      fetchRefetch()
    }, FETCH_INTERVAL)

    return () => {
      clearInterval(intervalId)
    }
  }, [fetchRefetch])

  useEffect(() => {
    const types = actionsData?.actions.map((item) => item.type) ?? []
    // On order complete refetch data
    if (types.includes("complete")) {
      fetchRefetch()
    }

    // On order accept refetch data and enable controls
    if (types.includes("accept")) {
      fetchRefetch()
      setDisabled(false)
    }
  }, [actionsData, fetchRefetch])

  useEffect(() => {
    if (fetchData?.fetch.state === "completionPendingForCustomer" && id) {
      history.push(`/tip/${id}`)
    }

    if (fetchData?.fetch.state === "terminated") {
      onSetModal({
        content: "Please start again",
        isOpen: true,
        showRefresh: false,
        title: "Looks like your driver terminated"
      })
    }

    if (fetchData?.fetch.state === "reported") {
      onSetModal({
        content: "Please start again",
        isOpen: true,
        title: "Your driver terminated and reported this interaction"
      })
    }
  }, [history, id, fetchData, onSetModal])

  const driverName = useMemo(
    () => fetchData?.fetch.driver?.user.firstName ?? "",
    [fetchData]
  )

  const driverPhoto = useMemo(
    () => fetchData?.fetch.driver?.user.photo ?? "",
    [fetchData]
  )

  return {
    disabled,
    driverName,
    driverPhoto
  }
}

export default useActions

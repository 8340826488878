// @flow
/* eslint-disable max-lines-per-function, react/jsx-max-depth, no-ternary */

import { Container, LoadingWrapper, SubContainer, Wrapper } from "./styled"
import type { ComponentProps } from "."
import { Helmet } from "react-helmet-async"
import Loading from "components/Loading"
import Main from "./Main"
import Modal from "components/ImageModal"
import React from "react"
import Sidebar from "components/Sidebar"

const Place = ({
  categories,
  imageSearch,
  items,
  isCustomOrderOpen,
  isLoggedIn,
  isModalOpen,
  loading,
  onAddToOrder,
  onAddCustomOrder,
  onChange,
  onDeleteItem,
  onDeleteStop,
  onLogin,
  onSelectImage,
  onSetItems,
  onToggleModal,
  options,
  place,
  selectedImage,
  selectedItem,
  setImageSearch
}: ComponentProps) =>
  loading ? (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  ) : (
    <>
      <Helmet>
        <title>{`${place.name} | Fetch Delivery`}</title>
      </Helmet>

      <Wrapper>
        <Container>
          <SubContainer>
            <Main
              categories={categories}
              isLoggedIn={isLoggedIn}
              onChange={onChange}
              onLogin={onLogin}
              onToggleModal={onToggleModal}
              options={options}
              place={place}
            />

            <Sidebar onDeleteItem={onDeleteItem} onDeleteStop={onDeleteStop} />

            <Modal
              customOrderItems={items}
              imageSearch={imageSearch}
              isCustomOrderOpen={isCustomOrderOpen}
              isModalOpen={isModalOpen}
              onAddCustomOrder={onAddCustomOrder}
              onAddToOrder={onAddToOrder}
              onSelectImage={onSelectImage}
              onSetCustomOrderItems={onSetItems}
              onToggleModal={onToggleModal}
              selectedImage={selectedImage}
              selectedItem={selectedItem}
              setImageSearch={setImageSearch}
            />
          </SubContainer>
        </Container>
      </Wrapper>
    </>
  )

export default Place

// @flow

import { Ripple, RippleInner } from "./styled"
import React from "react"

const Loading = () => (
  <Ripple>
    <RippleInner />
  </Ripple>
)

export default Loading

// @flow

import gql from "graphql-tag"

const GET_ME = gql`
  query getMe {
    me {
      email
      firstName
      hasSubscription
      id
      lastName
      phoneNumber
      photo
      subscription {
        amount
        id
        renewsOn
        subscribedOn
      }
    }
  }
`

export default GET_ME

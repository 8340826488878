// @flow
/* eslint-disable no-process-env */

const DEFAULT_HOST = "http://localhost"
const DEFAULT_PATH = "/graphql"
const DEFAULT_PORT = 3020

const config = {
  HOST: process.env.REACT_APP_GRAPHQL_HOST || DEFAULT_HOST,
  PATH: process.env.REACT_APP_GRAPHQL_PATH || DEFAULT_PATH,
  PORT: process.env.REACT_APP_GRAPHQL_PORT || DEFAULT_PORT
}

module.exports = config

// @flow
/* eslint-disable max-statements */

import type { ChatMessage } from "@fetch/frontend"
import getMessagesData from "./getMessagesData"

const getMessage =
  (driverName: string, userId: string, photo?: string) =>
  (item: any): ChatMessage => {
    const defaultMessages = getMessagesData(
      driverName,
      item.stopName,
      item.value
    )

    let message = item.text

    if (item.type === "accept" && driverName) {
      message = defaultMessages.hi.text
    }

    if (item.type === "arriveAtStop") {
      message = defaultMessages.arrive.text
    }

    if (item.type === "finishPurchase") {
      message = defaultMessages.purchase.text
    }

    return {
      ...item,
      isMe: item.userId === Number(userId),
      photo,
      text: message,
      timestamp: item.createdAt
    }
  }

export default getMessage

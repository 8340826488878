// @flow
/* eslint-disable max-lines-per-function, max-statements */

import type { Address, SavedAddress } from "@fetch/frontend"
import { useEffect, useMemo, useState } from "react"
import SavedAddresses from "./SavedAddresses"
import { prepareComponent } from "@bluframe/grapple"
import useSavedAddresses from "hooks/useSavedAddresses"

type Props = {||}

export type ComponentProps = {|
  +editingAddress: SavedAddress,
  +editingIndex: ?number,
  +items: SavedAddress[],
  +onToggleAdding: () => void,
  +onDeleteAddress: (index: number) => () => void,
  +onSaveEditingAddress: (index: number) => () => void,
  +onSetEditingAddress: (key: string, value: Address | string) => void,
  +onSetEditingIndex: (index: number) => () => void
|}

const DEFAULT_IS_ADDING = false
const DEFAULT_IS_EDITING = null
const DEFAULT_ADDRESS: SavedAddress = {
  address: {
    coordinates: {
      lat: 0,
      lng: 0
    },
    label: "completed",
    value: ""
  },
  name: ""
}
const ONE = 1
const START = 0

const usePrepareComponent = (): ComponentProps => {
  const [isAdding, setIsAdding] = useState(DEFAULT_IS_ADDING)
  const [editingIndex, setEditingIndex] = useState(DEFAULT_IS_EDITING)
  const [editingAddress, setEditingAddress] = useState(DEFAULT_ADDRESS)
  const { onSetSavedAddresses, savedAddresses } = useSavedAddresses()

  // Toggle Adding
  const onToggleAdding = () => {
    setIsAdding(!isAdding)
  }

  // Items with and without additional new address
  const items = useMemo(() => {
    if (isAdding) {
      return [...savedAddresses, editingAddress]
    }

    return savedAddresses
  }, [isAdding, editingAddress, savedAddresses])

  // Set new editing index
  // And cancel editing and add new if already editing
  const onSetEditingIndex = (index: number) => () => {
    if (index === editingIndex) {
      setEditingAddress(DEFAULT_ADDRESS)
      setIsAdding(null)
      setEditingIndex(null)
      return
    }

    setEditingIndex(index)
  }

  // If adding always edit new address
  useEffect(() => {
    if (isAdding) {
      setEditingIndex(items.length - ONE)
    }
  }, [isAdding, items.length, setEditingIndex])

  // Prefill editing address with current address from index
  useEffect(() => {
    if (isAdding || editingIndex === null) {
      return
    }

    setEditingAddress(items[editingIndex])
  }, [editingIndex, isAdding, items, setEditingAddress])

  const onSetEditingAddress = (key: string, value: Address | string) => {
    setEditingAddress({
      ...editingAddress,
      [key]: value
    })
  }

  const onSaveEditingAddress = (index: number) => () => {
    onSetSavedAddresses([
      ...items.slice(START, index),
      editingAddress,
      ...items.slice(index + ONE)
    ])
    setIsAdding(false)
    setEditingAddress(DEFAULT_ADDRESS)
    setEditingIndex(null)
    window.location.reload()
  }

  const onDeleteAddress = (index: number) => () => {
    onSetSavedAddresses([
      ...items.slice(START, index),
      ...items.slice(index + ONE)
    ])
  }

  return {
    editingAddress,
    editingIndex,
    items,
    onDeleteAddress,
    onSaveEditingAddress,
    onSetEditingAddress,
    onSetEditingIndex,
    onToggleAdding
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  SavedAddresses
)

// @flow
/* eslint-disable no-ternary */

import Modal from "./Modal"
import config from "config/recurring"
import prepareComponent from "@bluframe/grapple/prepareComponent"
import useDeliveryTime from "hooks/useDeliveryTime"
import { useHistory } from "react-router-dom"
import { useMemo } from "react"

const { LABELS_MAP } = config

type Props = {|
  +checkoutButton: React$Node,
  +isFetchScheduled: boolean,
  +isModalOpen: boolean,
  +onResetFetchState: () => void,
  +onToggleModal: () => void
|}

export type ComponentProps = {|
  ...Props,
  +onGoHomePage: () => void,
  +subtitle: string
|}

/**
 * `usePrepareComponent` is a custom hook that prepares the props for the `Modal` component.
 * It provides values such as subtitle and handlers for navigating the application.
 *
 * @param {Props} props - The props provided to the component
 * @returns {ComponentProps} - The prepared component props including values and handlers
 */
const usePrepareComponent = (props: Props): ComponentProps => {
  const history = useHistory()
  // Using useDeliveryTime hook to get formattedDeliveryDateTime
  const { deliveryTime, formattedDeliveryDateTime } = useDeliveryTime()

  // Handler for going back to the home page
  const onGoHomePage = () => {
    props.onToggleModal()
    props.onResetFetchState()
    history.push("/")
  }

  // Building a subtitle based on the formattedDeliveryDateTime
  const subtitle = useMemo(() => {
    if (formattedDeliveryDateTime) {
      if (deliveryTime.oneOffOrRecurring === "recurring") {
        return `A driver is going to pick it up ${formattedDeliveryDateTime} and deliver it again ${LABELS_MAP[
          deliveryTime.recurring
        ].toLowerCase()}`
      }
      return `A driver is going to pick it up ${formattedDeliveryDateTime}`
    }
    return ""
  }, [
    formattedDeliveryDateTime,
    deliveryTime.oneOffOrRecurring,
    deliveryTime.recurring
  ])

  return {
    ...props,
    onGoHomePage,
    subtitle
  }
}

/**
 * `ModalContainer` is a higher-order component prepared by `usePrepareComponent`
 * that wraps `Modal`. It provides values such as subtitle and handlers for navigating
 * the application.
 *
 * @param {Props} props - The props provided to the Modal component
 * @returns {React$Node} - The Modal component wrapped with the necessary values and handlers
 */
export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Modal
)

// @flow

import styled, {
  type StyledComponent,
  createGlobalStyle
} from "styled-components"
import colors from "styles/colors"
import mediaQueries from "styles/mediaQueries"
import { rgba } from "polished"

const MODAL_ALPHA = 0.23
const OVERLAY_ALPHA = 0.8

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
    font-family: Roboto, Arial, open-sans
  }

  body {
    margin: 0;

    #root {
      position: relative;
    }

    &.ReactModal__Body--open {
      overflow-y: hidden;
    }
  }


  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
  }

  .ReactModal__Overlay {
    background-color: ${rgba(colors.white, OVERLAY_ALPHA)} !important;
    bottom: 0px;
    left: 0px;
    overflow-y: auto;
    right: 0px;
    top: 0px;
    z-index: 200;
  }

  .ReactModal__Content {
    &.fetch-modal {
      background: ${colors.white};
      bottom: 0;
      height: fit-content;
      left: 0;
      margin-top: 90px;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 200;

      &:focus {
        outline: none;
      }

      ${mediaQueries.tablet} {
        box-shadow: 1px 1px 10px ${rgba(colors.black, MODAL_ALPHA)};
        left: 10%;
        top: 5vh;
        width: 80%;
        margin-top: 55px;
      }

      ${mediaQueries.desktop} {
        left: 20%;
        top: 10vh;
        width: 60%;
      }
    }

    &.place-modal {
      overflow-y: hidden;
      height: calc(88% - 60px);

      ${mediaQueries.tablet}{
        height: 60%;
      }
    }

    &.driver-modal {
      height: 40%;
    }

    &.tip-modal {
      height: 40%;
      overflow: hidden;
    }

    &.confirmation-modal {
      height: 100vh;
      left: 0;
      position: fixed;
      top: 0;
      width: 100vw;

      ${mediaQueries.tablet} {
        height: 80%;
        position: relative;
        width: auto;
      }
    }

    &.detail-modal {
      overflow-y: scroll;
    }

    &.custom-order {
      top: 66px;
    }
  }
`

export const Wrapper: StyledComponent<any, mixed, HTMLElement> = styled.div`
  margin-top: 140px;

  ${mediaQueries.tablet} {
    margin-top: 98px;
  }
`

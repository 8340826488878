// @flow

import Menu from "./Menu"
import prepareComponent from "hocs/prepareComponent"
import { useState } from "react"

type SetIsOpen = (isOpen: boolean) => void

type Props = {
  +customTip: string,
  +isOpen: boolean,
  +onChangeActiveTip: (tip: number) => () => void,
  +onChangeCustomTip: (ev: SyntheticEvent<any>) => void,
  +onCloseTipBox: any,
  +onClick: (ev: SyntheticEvent<any>) => void
}

export type ComponentProps = {
  +customTip: string,
  +isOpen: boolean,
  +onChangeActiveTipBox: (ev: SyntheticEvent<any>) => void,
  +onChangeCustomTip: (ev: SyntheticEvent<any>) => void,
  +onClick: (ev: SyntheticEvent<any>) => void,
  +onCloseTipBox: (ev: SyntheticEvent<any>) => void
}

const handleClick =
  (isOpen: boolean, setIsOpen: SetIsOpen) =>
  (ev: SyntheticEvent<any>): void => {
    ev.preventDefault()
    setIsOpen(!isOpen)
  }

const BASE_PRICE = 100
const DEFAULT_IS_OPEN = false

export const usePrepareComponent = ({
  customTip,
  onChangeActiveTip,
  onChangeCustomTip,
  onCloseTipBox
}: Props): ComponentProps => {
  const [isOpen, setIsOpen] = useState(DEFAULT_IS_OPEN)

  const onChangeActiveTipBox = (ev: SyntheticEvent<any>) => {
    ev.preventDefault()
    onChangeActiveTip(Number(customTip) * BASE_PRICE)()

    setIsOpen(!isOpen)
  }

  return {
    customTip,
    isOpen,
    onChangeActiveTipBox,
    onChangeCustomTip,
    onClick: handleClick(isOpen, setIsOpen),
    onCloseTipBox: onCloseTipBox(setIsOpen)
  }
}

export default prepareComponent<*, ComponentProps>(usePrepareComponent)(Menu)

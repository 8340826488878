// @flow

const curatedCategories = {
  fineDining: "fine_dining",
  hardware: "hardware",
  healthy: "healthy_food",
  indian: "indian",
  italian: "italian",
  liquor: "liquor",
  mall: "home_goods",
  "office supplies": "office_supply",
  pets: "pets",
  sushi: "sushi",
  "yard%2C garden": "yard_and_garden"
}

export default curatedCategories

// @flow

import AddressBox from "./AddressBox"
import { type ComponentProps } from "."
import React from "react"
import { Wrapper } from "./styled"

const DropOffAddress = ({
  dropOffAddress,
  onAddressSubmit,
  onInputChange
}: ComponentProps) => (
  <Wrapper>
    <AddressBox
      deliveryAddress={dropOffAddress}
      onAddressSubmit={onAddressSubmit}
      onInputChange={onInputChange}
      placeholder="Enter Drop Off Address"
    />
  </Wrapper>
)

export default DropOffAddress

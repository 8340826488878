// @flow

import styled, { type StyledComponent } from "styled-components"
import colors from "styles/colors"

export const OrderSummaryItem: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;
  font-family: Roboto, Arial, open-sans;
  font-size: 12px;
  letter-spacing: 0px;
  color: ${colors.doveGray};
  padding-top: 5px;
  padding-bottom: 5px;

  &:last-of-type {
    margin-bottom: 5px;
    border-bottom: 1px solid ${colors.bonJour};
  }

  & svg {
    margin-left: 5px;
  }
`

export const TotalEstimation: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;
  font-family: Roboto, Arial, open-sans;
  font-size: 16px;
  letter-spacing: 0px;
  color: ${colors.doveGray};
  margin-top: 5px;

  svg {
    margin-left: 5px;
  }
`

export const LoadingWrapper: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  display: flex;
  justify-content: center;
`

export const Wrapper: StyledComponent<any, mixed, HTMLElement> = styled.div`
  background: ${colors.white} 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 16px ${colors.blackRussian};
  padding: 10px 20px 20px;
`

// @flow
/* eslint-disable max-lines-per-function, no-ternary, no-nested-ternary */

import { Form, Grid, LoadingWrapper, NotFound } from "./styled"
import { type ComponentProps } from "."
import { Helmet } from "react-helmet-async"
import Loading from "components/Loading"
import Place from "components/Place"
import type { PlaceType } from "@fetch/frontend"
import React from "react"
import SearchBar from "components/SearchBar"
import Wrapper from "components/layout/Wrapper"

const Places = ({
  basicPlaces,
  loading,
  notFound,
  onChange,
  onSubmit,
  premiumPlaces,
  publicNonPartnerPlaces,
  standardPlaces,
  subcategory,
  value
}: ComponentProps) => (
  <Wrapper>
    <Form onSubmit={onSubmit}>
      <SearchBar
        onChange={onChange}
        placeholder="Search place, category, or address"
        value={value}
      />
    </Form>

    {loading ? (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    ) : notFound ? (
      <NotFound>{"We didn't find any results! Try another category?"}</NotFound>
    ) : (
      <>
        <Helmet>
          <title>{`${subcategory.toUpperCase()} | Fetch Delivery`}</title>
        </Helmet>

        {Boolean(premiumPlaces.length) && (
          <Grid>
            {premiumPlaces.map((place: PlaceType) => (
              <Place {...place} key={place.id} link={`/places/${place.id}`} />
            ))}
          </Grid>
        )}

        {Boolean(standardPlaces?.length) && (
          <Grid>
            {standardPlaces.map((place: PlaceType) => (
              <Place {...place} key={place.id} link={`/places/${place.id}`} />
            ))}
          </Grid>
        )}

        {Boolean(basicPlaces?.length) && (
          <Grid>
            {basicPlaces.map((place: PlaceType) => (
              <Place {...place} key={place.id} link={`/places/${place.id}`} />
            ))}
          </Grid>
        )}

        {Boolean(publicNonPartnerPlaces?.length) && (
          <Grid>
            {publicNonPartnerPlaces.map((place: PlaceType) => (
              <Place {...place} key={place.id} link={`/places/${place.id}`} />
            ))}
          </Grid>
        )}
      </>
    )}
  </Wrapper>
)

export default Places

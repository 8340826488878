// @flow

import styled, { type StyledComponent } from "styled-components"

export const Wrapper: StyledComponent<any, mixed, HTMLElement> = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  background-image: url("/images/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 70px;
`

// @flow

import { Button, CodeInput, Form, H3, Subtitle, Text, Wrapper } from "./styled"
import type { ComponentProps } from "."
import React from "react"

const VerifyPhone = ({ handleSubmit, register }: ComponentProps) => (
  <Wrapper>
    <Subtitle>{`Verify phone`}</Subtitle>

    <H3>{`What's the code?`}</H3>

    <Text>{`Sent to your number`}</Text>

    <Form onSubmit={handleSubmit}>
      <CodeInput name="code" placeholder="Enter your code" ref={register} />

      <Button type="submit">{`Submit`}</Button>
    </Form>
  </Wrapper>
)

export default VerifyPhone

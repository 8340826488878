// @flow

import styled, { type StyledComponent } from "styled-components"
import WrapperBase from "components/layout/Wrapper"
import colors from "styles/colors"

export const Wrapper: StyledComponent<any, mixed, any> = styled(WrapperBase)`
  background-color: ${colors.white};
  color: ${colors.charcoal};
`

export const Item: StyledComponent<any, mixed, HTMLElement> = styled.div`
  font-size: 18px;
  padding-bottom: 25px;
`

export const Link: StyledComponent<any, mixed, HTMLElement> = styled.a`
  cursor: pointer;
  text-decoration: none;
  padding: 5px;
  padding-left: 10px;

  &:hover {
    text-decoration: underline;
  }
`

export const Email: StyledComponent<
  any,
  mixed,
  StyledComponent<any, mixed, HTMLElement>
> = styled(Link)``

export const Phone: StyledComponent<
  any,
  mixed,
  StyledComponent<any, mixed, HTMLElement>
> = styled(Link)``

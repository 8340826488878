// @flow

import type { CustomOrderItem, MenuItem } from "@fetch/frontend"
import Modal from "./Modal"
import prepareComponent from "hocs/prepareComponent"

type Props = {|
  +customOrderItems: CustomOrderItem[],
  +imageSearch: string,
  +isCustomOrderOpen: boolean,
  +isModalOpen: boolean,
  +onAddCustomOrder: (params: any) => void,
  +onAddToOrder: (
    id: string,
    addonIds: any[],
    instructions: string,
    counterValue: number
  ) => () => void,
  +onSelectImage: (image: string) => void,
  +onSetCustomOrderItems: (newItems: CustomOrderItem[]) => void,
  +onToggleModal: (options: any) => () => void,
  +selectedImage?: string,
  +selectedItem: ?MenuItem,
  +setImageSearch: (imageSearch: string) => void
|}

export type ComponentProps = {|
  ...Props,
  +onRequestClose: () => void
|}

const usePrepareComponent = (props: Props): ComponentProps => {
  const onRequestClose = () => {
    if (props.isCustomOrderOpen) {
      return
    }

    props.onToggleModal({ modalOpen: true })()
  }

  return {
    ...props,
    onRequestClose
  }
}

export default prepareComponent(usePrepareComponent)(Modal)

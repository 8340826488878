// @flow
/* eslint-disable react/jsx-no-bind, no-ternary */

import { EmptyMessage, Items, Tab, Tabs } from "./styled"
import type { ComponentProps } from "."
import Item from "./Item"
import React from "react"
import Wrapper from "components/layout/Wrapper"

const YourOrders = ({
  activeTab,
  emptyMessage,
  items,
  onTabClick
}: ComponentProps) => (
  <Wrapper>
    <Tabs>
      <Tab
        active={activeTab === "scheduled"}
        onClick={() => onTabClick("scheduled")}
      >
        {"Scheduled"}
      </Tab>
      <Tab
        active={activeTab === "history"}
        onClick={() => onTabClick("history")}
      >
        {"Past"}
      </Tab>
    </Tabs>

    {items.length ? (
      <Items>
        {items.map((item) => (
          // $FlowFixMe
          <Item activeTab={activeTab} key={item.id} {...item} />
        ))}
      </Items>
    ) : (
      <EmptyMessage>{emptyMessage}</EmptyMessage>
    )}
  </Wrapper>
)

export default YourOrders

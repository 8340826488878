// @flow
/* eslint-disable react/jsx-max-depth, max-lines-per-function */

import {
  ArrowAfter,
  ArrowBefore,
  ButtonsWrapper,
  CancelButton,
  Divider,
  Header,
  IconWrapper,
  Input,
  Navigation,
  NavigationItem,
  OkButton,
  Wrapper
} from "./styled"
import { type ComponentProps } from "."
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

export const Options = ({
  customTip,
  isOpen,
  onChangeActiveTipBox,
  onChangeCustomTip,
  onClick,
  onCloseTipBox
}: ComponentProps) => (
  <Wrapper isOpen={isOpen}>
    <ArrowBefore />

    <ArrowAfter />

    <Header>
      <IconWrapper onClick={onClick}>
        <FontAwesomeIcon icon={faTimes} />
      </IconWrapper>

      {"Enter Tip Amount"}
    </Header>

    <Divider />

    <Navigation>
      <NavigationItem>
        <Input
          onChange={onChangeCustomTip}
          placeholder="$0.00"
          type="number"
          value={customTip || ""}
        />
      </NavigationItem>

      <ButtonsWrapper>
        <CancelButton onClick={onCloseTipBox}>{`Cancel`}</CancelButton>

        <OkButton onClick={onChangeActiveTipBox}>{`Ok`}</OkButton>
      </ButtonsWrapper>
    </Navigation>
  </Wrapper>
)

export default Options

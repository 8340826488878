// @flow

import styled, { type StyledComponent } from "styled-components"
import type { Theme } from "@bluframe/blublocks"
import colors from "styles/colors"
import mediaQueries from "@bluframe/blublocks/mediaQueries"

export const Wrapper: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  display: grid;
  row-gap: 16px;

  & [role="radiogroup"] > label {
    color: ${colors.dustyGray};
    font-size: 16px;
    text-align: center;

    ${mediaQueries.tablet} {
      font-size: 18px;
      font-weight: 500;
    }

    & ~ div {
      grid-template-columns: 1fr 1fr;
      justify-items: center;
    }
  }

  & input[type="radio"] ~ label {
    font-weight: 500;
  }

  & #recurring-radiogroup[role="radiogroup"] > label ~ div > div {
    width: 154px;
  }
`

export const Buttons: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  align-items: center;
  column-gap: 20px;
  display: grid;
  padding: 0 20px;
  row-gap: 16px;

  & button:nth-child(1) {
    order: 2;
  }

  & button:nth-child(2) {
    order: 1;
  }

  ${mediaQueries.tablet} {
    grid-template-columns: 1fr 1fr;

    & button:nth-child(1) {
      order: 1;
    }

    & button:nth-child(2) {
      order: 2;
    }
  }
`

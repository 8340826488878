// @flow

import gql from "graphql-tag"

const POST_VALIDATE_FETCH_MUTATION = gql`
  mutation postValidateFetch($fetch: FetchInput!) {
    postValidateFetch(fetch: $fetch) {
      couponCode
      couponDiscountAmount
      deliveryFee
      estimatedServiceFee
      estimatedTransactionFee
      fetchUnlimitedDiscountAmount
      noAvailableDrivers
    }
  }
`

export default POST_VALIDATE_FETCH_MUTATION

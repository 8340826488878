// @flow

import type { RouterHistory } from "react-router-dom"
import type { User } from "@fetch/frontend"
import { useEffect } from "react"
import useUser from "hooks/useUser"

type Props = {|
  +getStripeKeys: () => void,
  +history: RouterHistory,
  +lastLocation: {|
    pathname?: string
  |},
  +loginData: {|
    login: {|
      jwt: string,
      user: User
    |}
  |},
  +stripeKeysData: {|
    stripeKeys: string
  |}
|}

const useLogin = ({
  getStripeKeys,
  history,
  lastLocation,
  loginData,
  stripeKeysData
}: Props) => {
  const { onSetUser, user } = useUser()

  useEffect(() => {
    if (loginData) {
      localStorage.setItem("fetchToken", loginData.login.jwt)
      onSetUser(loginData.login.user)

      getStripeKeys()
    }
  }, [getStripeKeys, loginData, onSetUser, user])

  useEffect(() => {
    if (stripeKeysData) {
      localStorage.setItem(
        "stripeKeys",
        JSON.stringify(stripeKeysData.stripeKeys)
      )

      if (lastLocation?.pathname && lastLocation.pathname !== "/login") {
        history.push(lastLocation.pathname)
        return
      }

      history.push("/")
    }
  }, [history, lastLocation?.pathname, stripeKeysData, user])
}

export default useLogin

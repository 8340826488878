// @flow
/* eslint-disable max-lines-per-function, no-ternary, no-nested-ternary */

import { Grid, SeeAll, Top, Wrapper } from "./styled"
import { ReactComponent as ArrowRight } from "assets/icons/arrowRight.svg"
import { type ComponentProps } from "."
import Place from "components/Place"
import type { PlaceType } from "@fetch/frontend"
import React from "react"

const CategorySection = ({ label, sectionPlaces, slug }: ComponentProps) => (
  <Wrapper>
    {Boolean(sectionPlaces.length) && (
      <>
        <Top>
          {label}
          <SeeAll to={`/categories/${slug}/subcategories/${slug}/places`}>
            {`See all`} <ArrowRight />
          </SeeAll>
        </Top>
        <Grid>
          {sectionPlaces.map((place: PlaceType) => (
            <Place {...place} key={place.id} link={`/places/${place.id}`} />
          ))}
        </Grid>
      </>
    )}
  </Wrapper>
)

export default CategorySection

// @flow

import styled, { type StyledComponent } from "styled-components"
import { Box as UIBox } from "@fetch/ui"
import mediaQueries from "styles/mediaQueries"

export const Box: StyledComponent<any, mixed, any> = styled(UIBox)`
  grid-template-rows: auto 1fr 1fr auto;

  ${mediaQueries.tablet} {
    margin: 0 auto;
    max-width: 500px;
  }
`

export const Top: StyledComponent<any, mixed, HTMLElement> = styled.div`
  align-self: flex-start;
  display: grid;
`

export const Bottom: StyledComponent<any, mixed, HTMLElement> = styled.div`
  align-self: flex-start;
  display: grid;
`

export const Action: StyledComponent<any, mixed, HTMLElement> = styled.div`
  align-self: flex-end;
  column-gap: 20px;
  display: grid;
  width: 100%;
`

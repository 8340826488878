// @flow

import { Label, Main, Title, Wrapper } from "./styled"
import { type ComponentProps } from "."
import React from "react"

const Card = ({ image, label, link, title }: ComponentProps) => (
  <Wrapper to={link}>
    <Main image={image}>{title && <Title>{title}</Title>}</Main>

    <Label>{label}</Label>
  </Wrapper>
)

export default Card

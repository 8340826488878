// @flow
/* eslint-disable react/jsx-max-depth*/

import { Input, Label, Span, Wrapper } from "./styled"
import { type ComponentProps } from "."
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { faCamera } from "@fortawesome/free-solid-svg-icons"

const PhotoUpload = ({ onUploadImage, disabled }: ComponentProps) => (
  <Wrapper>
    <Input
      accept="image/*"
      disabled={disabled}
      id="photo-upload"
      onChange={onUploadImage}
      type="file"
    />

    <Label htmlFor="photo-upload">
      <Span disabled={disabled}>
        <FontAwesomeIcon icon={faCamera} size="2x" />
      </Span>
    </Label>
  </Wrapper>
)

export default PhotoUpload

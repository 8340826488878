// @flow
/* eslint-disable no-ternary, max-lines-per-function, max-statements, max-lines */

import errorModalButtons, {
  ERROR_COMPLETE_PROFILE
} from "data/errorModalButtons"
import { useCallback, useEffect, useMemo, useState } from "react"
import type { FetchStop } from "@fetch/frontend"
import POST_FETCH_MUTATION from "mutations/postFetch"
import POST_SCHEDULED_FETCH_MUTATION from "mutations/postScheduledFetch"
import POST_VALIDATE_COUPON_MUTATION from "mutations/postValidateCoupon"
import POST_VALIDATE_FETCH_MUTATION from "mutations/postValidateFetch"
import Sidebar from "./Sidebar"
import TagManager from "react-gtm-module"
import getErrorByNameFromGraphQLErrors from "helpers/getErrorByNameFromGraphQLErrors"
import prepareComponent from "@bluframe/grapple/prepareComponent"
import useDeliveryTime from "hooks/useDeliveryTime"
import useDropOffAddress from "hooks/useDropOffAddress"
import useFetch from "./hooks/useFetch"
import useFetchState from "hooks/useFetchState"
import { useHistory } from "react-router-dom"
import useIsScheduledOrderOnly from "hooks/useIsScheduledOrderOnly"
import useModal from "hooks/useModal"
import { useMutation } from "@apollo/client"
import usePrevious from "@bluframe/grapple/usePrevious"
import useSavedAddresses from "hooks/useSavedAddresses"
import useToggle from "@bluframe/grapple/useToggle"
import useUser from "hooks/useUser"

type Props = {|
  +onDeleteItem: (index: number, id: string) => () => void,
  +onDeleteStop: (index: number) => () => void
|}

export type ComponentProps = {|
  +checkoutLabel: string,
  +couponCode?: string,
  +couponEstimatedCosts: number[],
  +deliveryInstructions?: string,
  +estimatedSubtotal: number,
  +estimates?: {|
    +couponCode: string | null,
    +couponDiscountAmount: number | null,
    +deliveryFee: number,
    +estimatedServiceFee: number | null,
    +estimatedTransactionFee: number | null
  |},
  +isCheckoutButtonDisabled: boolean,
  +isFetchLoading: boolean,
  +isFetchScheduled: boolean,
  +isButtonsDisabled: boolean,
  +isLater: boolean,
  +isLoggedIn: boolean,
  +isModalOpen: boolean,
  +isScheduledFetchLoading: boolean,
  +onAddStop: () => void,
  +onDeleteItem: (index: number, id: string) => () => void,
  +onDeleteStop: (index: number) => () => void,
  +onLogin: () => void,
  +onPostFetch: () => void,
  +onResetFetchState: () => void,
  +onSetDeliveryInstructions: (ev: SyntheticInputEvent<EventTarget>) => void,
  +onToggleModal: () => void,
  +onToggleSidebar: () => void,
  +postValidateCoupon: (params: any) => void,
  +selectDeliveryTimeLabel: string,
  +showSidebar: boolean,
  +stops: FetchStop[],
  +validateCouponLoading: boolean,
  +validateFetchLoading: boolean
|}

const ZERO = 0
const viewportWidth = Math.max(
  document.documentElement?.clientWidth || ZERO,
  window.innerWidth || ZERO
)
const DEFAULT_IS_MODAL_OPEN = false
const FIRST = 0
const MIN_STOPS_LENGTH = 1
const DEFAULT_DELIVERY_INSTRUCTIONS = ""
const SMALL_DEVICES_WIDTH = 768
// on small devices show sidebar only in closed state
const DEFAULT_SHOW_SIDEBAR = viewportWidth >= SMALL_DEVICES_WIDTH

/**
 * `usePrepareComponent` is a custom hook that prepares the props for the `Sidebar` component.
 * It provides the values and handlers for managing the state of Sidebar component essentials
 * like checkout label, coupon codes, delivery instructions, estimated costs, etc.
 *
 * @param {Props} props - The props provided to the component
 * @returns {ComponentProps} - The prepared component props including values and handlers for different states
 */
const usePrepareComponent = ({
  onDeleteItem,
  onDeleteStop
}: Props): ComponentProps => {
  const { dropOffAddress } = useDropOffAddress()
  const { isScheduledOrderOnly, onSetIsNoDriversAvailable } =
    useIsScheduledOrderOnly()

  // Mutations for posting a "Fetch", validating a coupon, and validating a fetch
  const [
    postFetch,
    { data: fetchData, error: fetchError, loading: isFetchLoading }
  ] = useMutation(POST_FETCH_MUTATION, {
    refetchQueries: ["fetchScheduled", "history"]
  })
  const [
    postScheduledFetch,
    {
      data: scheduledFetchData,
      error: scheduledFetchError,
      loading: isScheduledFetchLoading
    }
  ] = useMutation(POST_SCHEDULED_FETCH_MUTATION, {
    refetchQueries: ["fetchScheduled", "history"]
  })

  const [
    postValidateCoupon,
    { data: coupon, error: validateCouponError, loading: validateCouponLoading }
  ] = useMutation(POST_VALIDATE_COUPON_MUTATION)
  const [
    postValidateFetch,
    {
      data: validateFetchData,
      error: validateFetchError,
      loading: validateFetchLoading
    }
  ] = useMutation(POST_VALIDATE_FETCH_MUTATION)

  // Constants and states
  const history = useHistory()
  const [isModalOpen, toggleIsModalOpen] = useToggle(DEFAULT_IS_MODAL_OPEN)
  const [deliveryInstructions, setDeliveryInstructions] = useState(
    DEFAULT_DELIVERY_INSTRUCTIONS
  )
  const previousDeliveryInstructions = usePrevious(deliveryInstructions)
  const { fetch: state, onResetFetchState } = useFetchState()
  const { user } = useUser()
  const [showSidebar, toggleShowSidebar] = useToggle(DEFAULT_SHOW_SIDEBAR)
  const { savedAddresses } = useSavedAddresses()
  const { deliveryTime, formattedDeliveryDateTime, onResetDeliveryTimeState } =
    useDeliveryTime()

  // Global Modal
  const { onSetModal } = useModal()

  // Handlers for toggling the sidebar and modal
  const onToggleSidebar = useCallback(() => {
    toggleShowSidebar()
  }, [toggleShowSidebar])

  const onToggleModal = useCallback(() => {
    toggleIsModalOpen()
  }, [toggleIsModalOpen])

  // Handler for setting delivery instructions
  const onSetDeliveryInstructions = useCallback(
    (ev: SyntheticInputEvent<EventTarget>) => {
      setDeliveryInstructions(ev.target.value)
    },
    [setDeliveryInstructions]
  )

  // Error handling
  if (
    fetchError ||
    scheduledFetchError ||
    validateCouponError ||
    validateFetchError
  ) {
    // eslint-disable-next-line no-console
    console.log(fetchError)
    // eslint-disable-next-line no-console
    console.log(scheduledFetchError)
    // eslint-disable-next-line no-console
    console.log(validateCouponError)
    // eslint-disable-next-line no-console
    console.log(validateFetchError)
  }

  const estimatedSubtotal = state.stops.reduce(
    (subtotal, stop) =>
      stop?.items.reduce(
        (prev, next) =>
          prev +
          next.menuItem?.price +
          (next.addons?.reduce((acc, curr) => acc + curr.price, FIRST) ?? ZERO),
        ZERO
      ),
    ZERO
  )

  const fetch = useFetch({
    coupon,
    deliveryInstructions,
    dropOffAddress,
    stops: state.stops
  })

  // Handler to post a fetch
  const onPostFetch = useCallback(() => {
    const { recurring, scheduledOn, ...preparedFetch } = fetch

    // Trigger checkout GTM event
    TagManager.dataLayer({
      dataLayer: {
        event: "checkout",
        user
      }
    })

    // If we have a scheduledOn date, we post a scheduled fetch
    if (scheduledOn) {
      // Trigger schedule GTM event
      TagManager.dataLayer({
        dataLayer: {
          event: "schedule",
          user
        }
      })

      // Post a scheduled fetch
      postScheduledFetch({
        variables: {
          scheduledFetch: {
            fetch: {
              ...preparedFetch,
              stops: preparedFetch.stops.map((stop: FetchStop) => ({
                ...stop,
                placeId:
                  // eslint-disable-next-line no-undefined
                  typeof stop.placeId === "number" ? stop.placeId : undefined
              }))
            },
            nextFetchDate: scheduledOn,
            recurring:
              deliveryTime.oneOffOrRecurring === "recurring"
                ? recurring
                : undefined
          }
        }
      })
      return
    }

    // Post a fetch
    postFetch({
      variables: {
        fetch: {
          ...preparedFetch,
          stops: preparedFetch.stops.map((stop: FetchStop) => ({
            ...stop,
            placeId:
              // eslint-disable-next-line no-undefined
              typeof stop.placeId === "number" ? stop.placeId : undefined
          }))
        }
      }
    })
  }, [
    deliveryTime.oneOffOrRecurring,
    fetch,
    postFetch,
    postScheduledFetch,
    user
  ])

  const estimates = useMemo(() => {
    if (!validateFetchData?.postValidateFetch) {
      return
    }

    const { noAvvailableDrivers, ...data } = validateFetchData.postValidateFetch

    // eslint-disable-next-line consistent-return
    return data
  }, [validateFetchData])

  // Set no available drivers and only allow scheduled fetch
  useEffect(() => {
    if (validateFetchLoading) {
      return
    }

    if (validateFetchData?.postValidateFetch?.noAvailableDrivers) {
      onSetIsNoDriversAvailable(true)
      return
    }

    if (validateFetchError) {
      const noAvaialbleDriversError = getErrorByNameFromGraphQLErrors({
        errorName: "NoAvailableDrivers",
        graphQLErrors: validateFetchError.graphQLErrors
      })

      if (noAvaialbleDriversError) {
        onSetIsNoDriversAvailable(true)
        return
      }

      onSetIsNoDriversAvailable(false)
      return
    }

    onSetIsNoDriversAvailable(false)
  }, [
    onSetIsNoDriversAvailable,
    validateFetchData,
    validateFetchError,
    validateFetchLoading
  ])

  // Validate a Fetch every time we change data
  useEffect(() => {
    // Remove the scheduledOn date from fetch
    const { recurring, scheduledOn, ...preparedFetch } = fetch

    if (
      preparedFetch?.stops.length > MIN_STOPS_LENGTH &&
      user &&
      (previousDeliveryInstructions === deliveryInstructions ||
        (typeof previousDeliveryInstructions === "undefined" &&
          deliveryInstructions === ""))
    ) {
      postValidateFetch({
        variables: {
          fetch: {
            ...preparedFetch,
            stops: preparedFetch.stops.map((stop: FetchStop) => ({
              ...stop,
              placeId:
                // eslint-disable-next-line no-undefined
                typeof stop.placeId === "number" ? stop.placeId : undefined
            }))
          }
        }
      })
    }
  }, [
    deliveryInstructions,
    estimatedSubtotal,
    fetch,
    postValidateFetch,
    previousDeliveryInstructions,
    user
  ])

  // Reset the fetch state and delivery date time
  const onResetFetchStateAndDeliveryDateTime = useCallback(() => {
    onResetFetchState()
    onResetDeliveryTimeState()
  }, [onResetDeliveryTimeState, onResetFetchState])

  // Fetch success
  useEffect(() => {
    // If we have no fetchData we just return
    if (!fetchData) {
      return
    }

    // If we have fetchData and order isn't scheduled,
    // we can redirect to the chat
    onResetFetchStateAndDeliveryDateTime()
    history.push(`/chat/${fetchData.postFetch.id}`)
  }, [history, fetchData, onResetFetchStateAndDeliveryDateTime])

  // Scheduled fetch success
  useEffect(() => {
    // If we have no fetchData we just return
    if (!scheduledFetchData) {
      return
    }

    // If we have scheduledFetchData
    // we open a modal to show the scheduled date
    if (!isModalOpen) {
      onToggleModal()
    }
  }, [isModalOpen, onToggleModal, scheduledFetchData])

  useEffect(() => {
    if (user && !user.phoneNumber) {
      onSetModal({
        content: ERROR_COMPLETE_PROFILE,
        isOpen: true,
        title: "Please complete your profile",
        ...errorModalButtons[ERROR_COMPLETE_PROFILE]
      })
    }
  }, [history, onSetModal, user])

  useEffect(() => {
    const savedAddress = savedAddresses.find(
      (item) => item.address.value === dropOffAddress.value
    )

    if (savedAddress) {
      setDeliveryInstructions(savedAddress.deliveryInstructions)
    }
  }, [dropOffAddress, savedAddresses, setDeliveryInstructions])

  // Handlers for adding a stop and logging in
  const onAddStop = useCallback(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "addAStop",
        user
      }
    })

    history.push("/")
  }, [history, user])

  const onLogin = useCallback(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "loginToPlaceOrder",
        user
      }
    })

    history.push("/login")
  }, [history, user])

  const isLoggedIn = useMemo(() => Boolean(user), [user])

  const checkoutLabel = useMemo(() => {
    if (!isLoggedIn) {
      return "Log In to Place Order"
    }

    if (deliveryTime.nowOrLater === "later" && formattedDeliveryDateTime) {
      return `Checkout ${formattedDeliveryDateTime}`
    }

    return "Checkout"
  }, [deliveryTime, formattedDeliveryDateTime, isLoggedIn])

  const selectDeliveryTimeLabel = useMemo(() => {
    if (isScheduledOrderOnly) {
      return "Select a delivery time"
    }

    return "or select a delivery time"
  }, [isScheduledOrderOnly])

  const isButtonsDisabled = useMemo(
    () => isFetchLoading || isScheduledFetchLoading || validateFetchLoading,
    [isFetchLoading, isScheduledFetchLoading, validateFetchLoading]
  )

  const isCheckoutButtonDisabled = useMemo(
    () => isScheduledOrderOnly && !fetch.scheduledOn,
    [fetch.scheduledOn, isScheduledOrderOnly]
  )

  const isFetchScheduled = useMemo(
    () => Boolean(scheduledFetchData),
    [scheduledFetchData]
  )

  return {
    checkoutLabel,
    couponCode: fetch.couponCode,
    couponEstimatedCosts: fetch?.stops?.map((stop) =>
      stop?.items?.map((item) => item.estimatedCost)
    )[ZERO],
    deliveryInstructions,
    estimatedSubtotal,
    estimates,
    isButtonsDisabled,
    isCheckoutButtonDisabled,
    isFetchLoading,
    isFetchScheduled,
    isLater: deliveryTime.nowOrLater === "later",
    isLoggedIn,
    isModalOpen,
    isScheduledFetchLoading,
    onAddStop,
    onDeleteItem,
    onDeleteStop,
    onLogin,
    onPostFetch,
    onResetFetchState: onResetFetchStateAndDeliveryDateTime,
    onSetDeliveryInstructions,
    onToggleModal,
    onToggleSidebar,
    postValidateCoupon,
    selectDeliveryTimeLabel,
    showSidebar,
    stops: state.stops,
    validateCouponLoading,
    validateFetchLoading
  }
}

/**
 * `SidebarContainer` is a higher-order component prepared by `usePrepareComponent`
 * that wraps `Sidebar`. It provides values and handlers for the component to manage the
 * state of `fetch`, delivery instructions, estimates, and other related states.
 *
 * @param {Props} props - The props provided to the Sidebar component
 * @returns {React$Node} - The Sidebar component wrapped with the necessary values and handlers
 */
export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Sidebar
)

// @flow
/* eslint-disable no-ternary, no-nested-ternary*/

import styled, { type StyledComponent } from "styled-components"
import Base from "components/buttons/Base"
import type { Theme } from "@bluframe/blublocks"
import colors from "styles/colors"
import mediaQueries from "styles/mediaQueries"

export const Wrapper: StyledComponent<
  any,
  Theme,
  StyledComponent<any, Theme, any>
> = styled(Base)`
  background-color: ${({ buttonType }) =>
    buttonType === "NO_SERVICE_FEE"
      ? colors.eminence
      : buttonType === "REDUCED_SERVICE_FEE"
      ? colors.amethyst
      : buttonType === "FETCH_FAVORITE"
      ? colors.charcoal
      : colors.charcoal};
  color: ${colors.white};
  font-size: 16px;
  font-weight: 500;
  margin-right: 10px;
  padding: 10px;
  border-radius: 20px;

  ${mediaQueries.tablet} {
    font-size: 16px;
  }
`

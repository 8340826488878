// @flow

const blacksAndGrays = {
  alabaster: "#F8F8F8",
  alto: "#D0D0D0",
  black: "#000000",
  blackRussian: "#00000026",
  bonJour: "#E6E5E6",
  boulder: "#777777",
  cararra: "#EDECE6",
  charcoal: "#201B1E",
  codGray: "#191919",
  doveGray: "#707070",
  dustyGray: "#999999",
  hintOfRed: "#F6F3F3",
  mercury: "#E3E3E3",
  mineShaft: "#3C3C3C",
  outerSpace: "#292A2A",
  silver: "#BBBBBB",
  stratos: "#0000004D",
  thunder: "#201B1E",
  white: "#FFFFFF",
  whiteRock: "#EEE9DA"
}

const blues = {
  navyBlue: "#000066"
}

const greens = {
  eden: "#15615F",
  padua: "#A8E6CE",
  successGreen: "#00BAA1"
}

const yellows = {
  driftwood: "#AD974F"
}

const reds = {
  sunsetOrange: "#FF5345"
}

const purples = {
  amethyst: "#C864CC",
  eminence: "#6A2E78",
  seance: "#740F78"
}

const colors = {
  ...blacksAndGrays,
  ...blues,
  ...greens,
  ...purples,
  ...reds,
  ...yellows
}

export default colors

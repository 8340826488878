// @flow
/* eslint-disable max-lines-per-function */

import { Buttons, Content, Title, Wrapper } from "./styled"
import { Button } from "@fetch/ui"
import type { ComponentProps } from "."
import React from "react"
import ReactModal from "react-modal"

ReactModal.setAppElement("#root")

const Modal = ({
  content,
  customButtons,
  isOpen,
  isShowButtons,
  okayLabel,
  onCancel,
  onOkay,
  onCustomButtonClick,
  onGoHome,
  onRefresh,
  onToggleModal,
  showCancel,
  showOkay,
  showHome,
  showRefresh,
  title
}: ComponentProps) => (
  <ReactModal
    className="fetch-modal"
    contentLabel="modal"
    isOpen={isOpen}
    onRequestClose={onToggleModal}
  >
    <Wrapper>
      <Title>{title}</Title>

      <Content>{content}</Content>

      {isShowButtons && (
        <Buttons>
          {showOkay && <Button label={okayLabel || "Ok"} onClick={onOkay} />}

          {showCancel && <Button label="Cancel" onClick={onCancel} outlined />}

          {showRefresh && <Button label="Ok" onClick={onRefresh} />}

          {showHome && <Button label="Home Page" onClick={onGoHome} />}

          {customButtons?.map(({ label, link, tagManagerEventName }) => (
            <Button
              key={label}
              label={label}
              onClick={onCustomButtonClick(link, tagManagerEventName)}
            />
          ))}
        </Buttons>
      )}
    </Wrapper>
  </ReactModal>
)

export default Modal

// @flow
/* eslint-disable react/jsx-max-depth */

import {
  Center,
  DeliveryRouteItem,
  ItemWrapper,
  Left,
  Logo,
  Right,
  Wrapper
} from "./styled"
import AddressDropdown from "components/AddressDropdown"
import { type ComponentProps } from "."
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import InProgress from "./InProgress"
import { Link } from "react-router-dom"
import Navigation from "components/Navigation"
import React from "react"
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import styles from "components/Sidebar/DeliveryRoute/reactSelectStyles"

const Header = ({
  dropOffAddress,
  isLoggedIn,
  onAddressChange,
  requestOptions
}: ComponentProps) => (
  <Wrapper>
    <Left>
      <Navigation />
    </Left>

    <Center>
      <Link to="/">
        <Logo alt="fetch.delivery" src="/images/logo.png" />
      </Link>
    </Center>

    <Right>
      {isLoggedIn && (
        <>
          <DeliveryRouteItem>
            <ItemWrapper>
              <FontAwesomeIcon icon={faMapMarkerAlt} />

              <AddressDropdown
                onChange={onAddressChange}
                requestOptions={requestOptions}
                styles={styles}
                value={dropOffAddress}
              />
            </ItemWrapper>
          </DeliveryRouteItem>

          <InProgress />
        </>
      )}
    </Right>
  </Wrapper>
)

export default Header

// @flow

import { atom, useRecoilState } from "recoil"
import type { Address } from "@fetch/frontend"
import localStorageEffect from "effects/localStorage"

export const DEFAULT_PICK_UP_ADDRESS = {
  coordinates: { lat: 0, lng: 0 },
  label: "",
  value: ""
}

const pickUpAddressState = atom<Address>({
  default: DEFAULT_PICK_UP_ADDRESS,
  // eslint-disable-next-line camelcase
  effects_UNSTABLE: [localStorageEffect("pickUpAddress")],
  key: "pickUpAddress"
})

const usePickUpAddress = () => {
  const [pickUpAddress, setPickUpAddress] = useRecoilState(pickUpAddressState)

  const onSetPickUpAddress = (newPickUpAddress: any) => {
    setPickUpAddress(newPickUpAddress)
  }

  return { onSetPickUpAddress, pickUpAddress }
}

export default usePickUpAddress

// @flow

import { atom, useRecoilState } from "recoil"
import localStorageEffect from "effects/localStorage"
import { useCallback } from "react"

const DEFAULT_HAS_TOUCHED_NON_PARTNER_MODAL = []

const hasTouchedNonPartnerModalState = atom<string[]>({
  default: DEFAULT_HAS_TOUCHED_NON_PARTNER_MODAL,
  // eslint-disable-next-line camelcase
  effects_UNSTABLE: [localStorageEffect("hasTouchedNonPartnerModal")],
  key: "hasTouchedNonPartnerModal"
})

const useHasTouchedNonPartnerModal = () => {
  const [hasTouchedNonPartnerModal, setHasTouchedNonPartnerModal] =
    useRecoilState(hasTouchedNonPartnerModalState)

  const onSetHasTouchedNonPartnerModal = useCallback(
    (placeId: string) => {
      setHasTouchedNonPartnerModal((prev) => {
        if (prev.includes(placeId)) {
          return prev
        }

        return [...prev, placeId]
      })
    },
    [setHasTouchedNonPartnerModal]
  )

  return {
    hasTouchedNonPartnerModal,
    onSetHasTouchedNonPartnerModal
  }
}

export default useHasTouchedNonPartnerModal

// @flow

import { Route as BaseRoute } from "react-router-dom"
import { type ComponentProps } from "."
import React from "react"

const Route = ({ component, path }: ComponentProps) => (
  <BaseRoute component={component} path={path} />
)

export default Route

// @flow
/* eslint-disable no-console, max-statements */

import Favourites from "./Favourites"
import GET_PLACES_BY_TAG_QUERY from "queries/getPlacesByTag"
import type { PlaceType } from "@fetch/frontend"
import prepareComponent from "hocs/prepareComponent"
import useDropOffAddress from "hooks/useDropOffAddress"
import { useEffect } from "react"
import { useLazyQuery } from "@apollo/client"
import { useParams } from "react-router-dom"

export type ComponentProps = {
  +partnerPlaces: PlaceType[],
  +query: string
}

const ZERO = 0
const FIVE = 5
const FAVORITES_TAG = "no_service_fee"
const REMOVED_STRING = " mi"
const SORT_FUNCTION = ({ distance: distanceA }, { distance: distanceB }) =>
  distanceA.replace(REMOVED_STRING, "") - distanceB.replace(REMOVED_STRING, "")

export const usePrepareComponent = (): ComponentProps => {
  const params = useParams()
  const {
    dropOffAddress: {
      coordinates: { lat, lng }
    }
  } = useDropOffAddress()
  const [getPlacesByTag, { data, error, loading }] = useLazyQuery(
    GET_PLACES_BY_TAG_QUERY,
    {
      variables: {
        input: { latitude: lat, longitude: lng, tag: FAVORITES_TAG }
      }
    }
  )

  useEffect(() => {
    if (lat && lng) {
      getPlacesByTag()
    }
  }, [getPlacesByTag, lat, lng])

  if (error) {
    console.log(error.message)
    return {
      partnerPlaces: [],
      query: ""
    }
  }

  if (loading || !data) {
    return {
      partnerPlaces: [],
      query: ""
    }
  }

  return {
    partnerPlaces: [...data.placesByTag].sort(SORT_FUNCTION).slice(ZERO, FIVE),
    query: decodeURIComponent(params.subcategory || "")
  }
}

export default prepareComponent(usePrepareComponent)(Favourites)

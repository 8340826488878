// @flow

import styled, { type StyledComponent } from "styled-components"
import type { Theme } from "@bluframe/blublocks"
import colors from "styles/colors"
import mediaQueries from "@bluframe/blublocks/mediaQueries"

export const Wrapper: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  align-items: center;
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.bonJour};
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 101;

  ${mediaQueries.tablet} {
    display: flex;
    max-width: 1920px;
    padding: 20px 60px;
    justify-content: space-between;
  }
`

export const Logo: StyledComponent<{||}, Theme, HTMLElement> = styled.img`
  height: 30px;
  margin-top: 14px;
  max-width: unset;
  vertical-align: baseline;
`

export const Left: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-start;
  width: 33.333%;
  grid-area: 1 / 1 / 2 / 3;
`

export const Center: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  width: 33.333%;
  grid-area: 1 / 3 / 2 / 6;
`

export const Right: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
  grid-area: 2 / 1 / 3 / 6;
  width: 100%;
  margin-top: 10px;

  ${mediaQueries.tablet} {
    width: 33.333%;
  }
`

export const DeliveryRouteItem: StyledComponent<
  {| +isClickable?: boolean |},
  Theme,
  HTMLElement
> = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  text-align: left;
  font-family: Roboto, Arial, open-sans;
  font-size: 14px;
  letter-spacing: 0px;
  color: ${colors.doveGray};
  ${({ isClickable }) => isClickable && "cursor: pointer;"};
  padding-top: 5px;
  padding-bottom: 5px;

  svg {
    padding-right: 5px;
    width: 26px !important;
  }
`

export const ItemWrapper: StyledComponent<
  {||},
  Theme,
  HTMLElement
> = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  max-width: none;

  ${mediaQueries.tablet} {
    max-width: 350px;
  }
`

// @flow

import colors from "styles/colors"
import styles from "components/Sidebar/DeliveryRoute/reactSelectStyles"

const dropdownStyles = {
  ...styles,
  control: (provided: any) => ({
    ...provided,
    ...styles.control(provided),
    "&:focus": {
      borderColor: "transparent"
    },
    "&:hover": {
      borderColor: "transparent"
    },
    borderColor: "transparent",
    boxShadow: `0px 0px 4px ${colors.stratos}`,
    padding: "0 10px"
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: colors.alto,
    fontSize: "16px",
    fontWeight: 400
  })
}

export default dropdownStyles

// @flow

const isCustomOrder = (label: string) =>
  label === "Custom Order" || label === "Anything Delivered"

export const generateLink = (
  slug: string,
  label: string,
  isSearch?: boolean
) => {
  // Absolute links
  if (slug.startsWith("/")) {
    return slug
  }

  if (isCustomOrder(label)) {
    return `/pickup-address`
  }

  if (isSearch) {
    return `/categories/${slug}/subcategories/${slug}/places`
  }

  return `/categories/${slug}/subcategories`
}

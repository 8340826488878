// @flow

import { atom, useRecoilState } from "recoil"
import GET_ME from "queries/getMe"
import type { User } from "@fetch/frontend"
import localStorageEffect from "effects/localStorage"
import { useEffect } from "react"
import { useQuery } from "@apollo/client"

const userState = atom<?User>({
  default: null,
  // eslint-disable-next-line camelcase
  effects_UNSTABLE: [localStorageEffect("user")],
  key: "user"
})

const useUser = (): ({|
  +onSetUser: (newUser: ?User) => void,
  +refetch: () => void,
  +user: ?User
|}) => {
  const [user, setUser] = useRecoilState(userState)
  const fetchToken = localStorage.getItem("fetchToken")

  const { data, error, loading, refetch } = useQuery(GET_ME, {
    skip: !fetchToken
  })

  useEffect(() => {
    if (error || loading || !data) {
      return
    }

    setUser(data.me)
  }, [data, error, loading, setUser])

  const onSetUser = (newUser: ?User) => {
    if (!newUser) {
      localStorage.removeItem("fetchToken")
    }
    setUser(newUser)
  }

  return {
    onSetUser,
    refetch,
    user
  }
}

export default useUser

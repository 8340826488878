// @flow
/* eslint-disable no-ternary */

import styled, { type StyledComponent } from "styled-components"
import { CancelButton } from "../styled"
import colors from "styles/colors"
import { rgba } from "polished"

const ITEM_ALPHA = 0.3

export const Box: StyledComponent<any, mixed, HTMLElement> = styled.div`
  background-color: ${colors.white};
  box-shadow: 1px 1px 10px ${rgba(colors.black, ITEM_ALPHA)};
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 0 auto 20px;
  width: 80%;

  @media only screen and (min-width: 768px) {
    flex: 0 0 auto;
    margin: 0 0 20px;
    padding: 30px;
  }
`

export const ButtonsWrapper: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  display: flex;
  padding: 20px 0 0;
  border-top: 1px solid ${colors.bonJour};
`

export const IconWrapper: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.span`
  margin-right: 10px;
`

export const ProgressButton: StyledComponent<
  any,
  mixed,
  StyledComponent<any, mixed, HTMLElement>
> = styled(CancelButton)`
  background: ${({ status }) => (status ? colors.seance : colors.doveGray)};
  border-radius: 5px;
  width: 100%;
  cursor: auto;

  &:nth-child(2) {
    margin-left: 5%;
  }

  &:hover {
    opacity: 1;
  }
`

export const Subtitle: StyledComponent<any, mixed, HTMLElement> = styled.h3`
  font-family: "Roboto", Arial, open-sans, serif;
  font-size: 22px;
  text-align: center;
  margin: 0 0 14px;
`

export const Attention: StyledComponent<any, mixed, HTMLElement> = styled.div`
  color: ${colors.sunsetOrange};
  padding: 10px 0;
`

export const ItemsContainer: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div``

export const ItemsWrapper: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  display: flex;
  flex-direction: column;
`

export const MenuItem: StyledComponent<any, mixed, HTMLElement> = styled.div`
  border-top: 1px solid ${colors.bonJour};
  color: ${colors.doveGray};
  font-family: Roboto, Arial, open-sans;
  font-size: 14px;
  letter-spacing: 0px;
  padding: 10px 0;
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const MenuItemName: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  margin: 0;
`

// @flow
/* eslint-disable max-statements, max-lines-per-function */

import { useEffect, useMemo } from "react"
import Profile from "./Profile"
import TagManager from "react-gtm-module"
import UPDATE_PROFILE from "mutations/updateProfile"
import type { User } from "@fetch/frontend"
import prepareComponent from "hocs/prepareComponent"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { useLastLocation } from "react-router-last-location"
import { useMutation } from "@apollo/client"

type Props = {|
  +handleSubmit: any,
  +loading: boolean,
  +onSubmit: (newUser: User) => void,
  +register: any
|}

export type ComponentProps = {|
  ...Props
|}

const usePrepareComponent = (): ComponentProps => {
  const history = useHistory()
  const lastLocation = useLastLocation()
  const params = new URLSearchParams(history.location.search)

  const onUpdateCompleted = () => {
    if (params.get("lastLocation") && lastLocation?.pathname) {
      history.push(lastLocation.pathname)
    } else {
      history.push("/")
    }
  }

  const [updateProfile, { data, error, loading }] = useMutation(
    UPDATE_PROFILE,
    { onCompleted: onUpdateCompleted }
  )

  const userDetails = localStorage.getItem("user")

  useEffect(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }

    if (data) {
      localStorage.setItem("user", JSON.stringify(data.updateProfile))
    }
  }, [data, error])

  const user = useMemo(
    () => (typeof userDetails === "string" && JSON.parse(userDetails)) || {},
    [userDetails]
  )

  const { register, handleSubmit } = useForm({
    defaultValues: user
  })

  const onSubmit = (newUser: User) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "saveProfile"
      }
    })

    updateProfile({ variables: { user: { ...newUser, id: user.id } } })
  }

  return {
    handleSubmit,
    loading,
    onSubmit,
    register
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Profile
)

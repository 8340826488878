// @flow

import styled, { type StyledComponent } from "styled-components"
import type { Theme } from "@bluframe/blublocks"
import colors from "styles/colors"
import mediaQueries from "@bluframe/blublocks/mediaQueries"

export const Wrapper: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  align-items: center;
  color: ${colors.charcoal};
  justify-items: center;
  display: grid;
  padding: 20px;
  row-gap: 20px;

  ${mediaQueries.tablet} {
    padding: 40px 80px;
  }
`

export const Title: StyledComponent<{||}, Theme, HTMLElement> = styled.h4`
  margin: 0;
`

export const Content: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  font-size: 20px;
  line-height: 1.5;
  margin: 0;
  width: 100%;
`

export const Buttons: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  column-gap: 10px;
  display: grid;
  grid-template-columns: auto auto;
`

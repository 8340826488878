// @flow

import {
  Card,
  CardTitle,
  DeliveryRouteItem,
  ItemsWrapper
} from "components/Sidebar/styled"
import { type ComponentProps } from "."
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { faCreditCard } from "@fortawesome/free-regular-svg-icons"

const Payment = ({ last4 }: ComponentProps) => (
  <Card>
    <CardTitle>{"Payment Method"}</CardTitle>

    <ItemsWrapper>
      <DeliveryRouteItem>
        <FontAwesomeIcon icon={faCreditCard} />

        {`  Card ending in ${last4 || ""}`}
      </DeliveryRouteItem>
    </ItemsWrapper>
  </Card>
)

export default Payment

// @flow
/* eslint-disable react/no-array-index-key */

import { Button } from "@fetch/ui"
import { type ComponentProps } from "."
import H1 from "components/typography/H1"
import Item from "./Item"
import { Items } from "./styled"
import React from "react"
import type { SavedAddress } from "@fetch/frontend"
import Wrapper from "components/layout/Wrapper"

const SavedAddresses = ({
  editingAddress,
  editingIndex,
  items,
  onDeleteAddress,
  onSaveEditingAddress,
  onSetEditingAddress,
  onSetEditingIndex,
  onToggleAdding
}: ComponentProps) => (
  <Wrapper>
    <H1>{"Saved Addresses"}</H1>

    <Items>
      {items.map((item: SavedAddress, index: number) => (
        <Item
          editingAddress={editingAddress}
          isEditing={editingIndex === index}
          isEditingOthers={editingIndex !== null}
          key={`saved-address-${index}`}
          onDeleteAddress={onDeleteAddress(index)}
          onSaveEditingAddress={onSaveEditingAddress(index)}
          onSetEditingAddress={onSetEditingAddress}
          onSetEditingIndex={onSetEditingIndex(index)}
          savedAddress={item}
        />
      ))}
    </Items>

    <Button label="Add Address" onClick={onToggleAdding} />
  </Wrapper>
)

export default SavedAddresses

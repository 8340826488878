// @flow

import compose from "ramda/src/compose"
import filter from "ramda/src/filter"
import head from "ramda/src/head"
import path from "ramda/src/path"

type GetErrorByNameFromGraphQLErrors = {|
  +errorName: string,
  +graphQLErrors: any[]
|}

const getErrorByNameFromGraphQLErrors = ({
  errorName,
  graphQLErrors
}: GetErrorByNameFromGraphQLErrors) =>
  compose(
    path(["extensions", "response", "body", "context", "message"]),
    head(),
    filter(
      ({ extensions }) =>
        extensions?.response?.body?.context?.name === errorName
    )
  )(graphQLErrors)

export default getErrorByNameFromGraphQLErrors

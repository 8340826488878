// @flow
/* eslint-disable react/jsx-max-depth */

import {
  Buttons,
  CancelButton,
  CancelOrderButton,
  Container,
  HeadLine,
  Subtitle
} from "./styled"
import { type ComponentProps } from "."
import React from "react"
import ReactModal from "react-modal"

ReactModal.setAppElement("#root")

const Modal = ({ isModalOpen, onToggleModal }: ComponentProps) => (
  <ReactModal
    className="fetch-modal driver-modal"
    contentLabel="Cancel Order"
    isOpen={isModalOpen}
    onRequestClose={onToggleModal({})}
  >
    <Container>
      <HeadLine>{`Cancel Order`}</HeadLine>

      <Subtitle>{`Are you sure?`}</Subtitle>

      <Buttons>
        <CancelButton onClick={onToggleModal({})}>{`Cancel`}</CancelButton>

        <CancelOrderButton>{`Cancel Order`}</CancelOrderButton>
      </Buttons>
    </Container>
  </ReactModal>
)

export default Modal

// @flow

import styled, { type StyledComponent } from "styled-components"
import colors from "styles/colors"

export const Wrapper: StyledComponent<any, mixed, HTMLElement> = styled.div`
  background: ${colors.white} 0% 0% no-repeat padding-box;
  padding: 20px;
  box-shadow: 0px 8px 16px ${colors.blackRussian};
`

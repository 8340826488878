// @flow
/* eslint-disable no-ternary */

import styled, { type StyledComponent } from "styled-components"
import colors from "styles/colors"
import mediaQueries from "styles/mediaQueries"

export const Wrapper: StyledComponent<any, mixed, HTMLElement> = styled.div`
  margin-right: 10px;

  ${mediaQueries.tablet} {
    margin-right: 22px;
  }
`

export const Input: StyledComponent<any, mixed, HTMLElement> = styled.input`
  display: none;
`

export const Label: StyledComponent<any, mixed, HTMLElement> = styled.label``

export const Span: StyledComponent<any, mixed, HTMLElement> = styled.span`
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};

  &:hover {
    cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
    opacity: ${({ disabled }) => (disabled ? "0.5" : "0.8")};
  }

  & svg {
    color: ${colors.white};
  }
`

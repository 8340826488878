// @flow

import styled, { type StyledComponent } from "styled-components"
import { Input } from "components/Sidebar/styled"
import type { Theme } from "@bluframe/blublocks"
import colors from "styles/colors"

export const Wrapper: StyledComponent<any, Theme, HTMLElement> = styled.div`
  color: ${colors.charcoal};
  margin-top: 100px;
  text-align: center;
`

export const Subtitle: StyledComponent<any, Theme, HTMLElement> = styled.h2`
  letter-spacing: -0.5px;
`

export const MiddleText: StyledComponent<
  any,
  Theme,
  StyledComponent<any, Theme, HTMLElement>
> = styled(Subtitle)`
  margin-bottom: 0px;
  font-size: 30px;
  margin-top: 20px;
`

export const Text: StyledComponent<any, Theme, HTMLElement> = styled.p``

export const PhoneInput: StyledComponent<
  any,
  Theme,
  StyledComponent<any, Theme, HTMLElement>
> = styled(Input)`
  margin-left: 0;
  margin-right: 10px;
  margin-top: 0;
  height: 45px;
  margin-right: 10px;
  font-size: 16px;

  &::placeholder {
    font-size: 16px;
  }
`

export const Button: StyledComponent<any, Theme, HTMLElement> = styled.button`
  background: ${colors.charcoal};
  border: none;
  border-radius: 3px;
  color: ${colors.white};
  cursor: pointer;
  font-family: Roboto, Arial, open-sans;
  font-size: 20px;
  height: 45px;
  letter-spacing: 0.27px;
  text-align: center;
  width: 100%;
  outline: none;
`

export const Form: StyledComponent<any, Theme, HTMLElement> = styled.form`
  display: flex;
`

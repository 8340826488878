// @flow

import DeliveryRoute from "./DeliveryRoute"
import type { FetchStop } from "@fetch/frontend"
import prepareComponent from "hocs/prepareComponent"
import useDropOffAddress from "hooks/useDropOffAddress"

type Props = {|
  +stops: FetchStop[]
|}

export type ComponentProps = {|
  +dropOffAddress: any,
  +onAddressChange: any,
  +requestOptions: any,
  +searchPrefix: string,
  +stops: FetchStop[]
|}

// Default radius for location biasing in meters
const DEFAULT_RADIUS = 2000

const SEARCH_PREFIX_TRIM = -3

const usePrepareComponent = ({ stops }: Props): ComponentProps => {
  const { dropOffAddress, onSetDropOffAddress } = useDropOffAddress()

  const requestOptions = {
    location: new window.google.maps.LatLng({
      lat: dropOffAddress.coordinates.lat,
      lng: dropOffAddress.coordinates.lng
    }),
    radius: DEFAULT_RADIUS
  }

  // We will use last three words from label to filter results by city, state and country as api provides only filtering by country
  const searchPrefix = dropOffAddress.label
    .split(", ")
    .slice(SEARCH_PREFIX_TRIM)
    .join(", ")

  const onAddressChange = (newDropOffAddress) => {
    onSetDropOffAddress(newDropOffAddress)
  }

  return {
    dropOffAddress,
    onAddressChange,
    requestOptions,
    searchPrefix,
    stops
  }
}

export default prepareComponent(usePrepareComponent)(DeliveryRoute)

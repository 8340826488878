// @flow

import styled, { type StyledComponent } from "styled-components"

export const Wrapper: StyledComponent<any, mixed, HTMLElement> = styled.div`
  font-family: Roboto, Arial, open-sans;
  height: 100%;
  margin: 40px;
`

export const Images: StyledComponent<any, mixed, HTMLElement> = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: calc(100% - 160px);
  overflow-y: auto;
  padding: 20px 0;
`

export const Image: StyledComponent<any, mixed, HTMLElement> = styled.img`
  cursor: pointer;
  flex: 1 0 auto;
  height: 200px;
  margin-bottom: 10px;
  margin-right: 10px;
  object-fit: cover;
  width: 200px;
`

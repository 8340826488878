// @flow

import InProgress from "./InProgress"
import prepareComponent from "hocs/prepareComponent"
import useRedirectFetch from "hooks/useRedirectFetch"

export type ComponentProps = {
  +link: string
}

const usePrepareComponent = (): ComponentProps => {
  const link = useRedirectFetch()

  return {
    link
  }
}

export default prepareComponent(usePrepareComponent)(InProgress)

// @flow

import gql from "graphql-tag"

const GET_PLACES_BY_TAG_QUERY = gql`
  query placesByTag($input: PlacesByTagInput!) {
    placesByTag(input: $input) {
      address
      placeType
      distance
      id
      image
      isPartner
      noServiceFee
      name
      slug
    }
  }
`

export default GET_PLACES_BY_TAG_QUERY

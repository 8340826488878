// @flow
/* eslint-disable no-ternary */

import styled, { type StyledComponent } from "styled-components"
import { Link } from "react-router-dom"
import type { Theme } from "@bluframe/blublocks"
import colors from "styles/colors"
import mediaQueries from "@bluframe/blublocks/mediaQueries"

export const Wrapper: StyledComponent<any, Theme, any> = styled(Link)`
  align-items: center;
  cursor: pointer;
  display: grid;
  flex-direction: column;
  height: 125px;
  justify-items: center;
  margin: 0 10px;
  row-gap: 6px;
  text-decoration: none;
  width: 81px;

  ${mediaQueries.tablet} {
    height: 125px;
    row-gap: 8px;
    width: 81px;
  }
`

export const Main: StyledComponent<any, Theme, HTMLElement> = styled.div`
  background-image: url(${({ image }) => image});
  background-size: cover;
  border-radius: 50%;
  height: 80px;
  padding: 10px;
  width: 81px;

  ${mediaQueries.tablet} {
    height: 80px;
    width: 81px;
  }
`

export const Label: StyledComponent<any, Theme, HTMLElement> = styled.div`
  align-items: center;
  background-color: ${colors.white};
  color: ${colors.thunder};
  display: flex;
  font-family: "Roboto Condensed", Arial, open-sans;
  font-weight: bold;
  font-size: 14px;
  padding: 0 10px;
  white-space: break-spaces;
  text-align: center;
  margin-top: ${({ slug }) => (slug === "liquor" ? "5px" : "-5px")};
`

export const Title: StyledComponent<any, Theme, HTMLElement> = styled.h2`
  color: ${colors.white};
  font-family: "Roboto Condensed", Arial, open-sans;
  font-weight: bold;
  font-size: 30px;
  margin: 0;
  max-width: 100px;
`

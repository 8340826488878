// @flow

import OrderSummary from "./OrderSummary"
import prepareComponent from "@bluframe/grapple/prepareComponent"

type Props = {|
  +estimatedSubtotal: number,
  +estimates?: any,
  +loading: boolean
|}

export type ComponentProps = {|
  +couponCode?: string,
  +couponDiscountAmount?: number,
  +deliveryFee?: number,
  +estimatedCreditCardFee?: ?number,
  +estimatedServiceFee?: ?number,
  +estimatedSubtotal: number,
  +estimatedTotal: number,
  +fetchUnlimitedDiscountAmount?: number,
  +loading: boolean
|}

const ZERO = 0

const usePrepareComponent = ({
  estimatedSubtotal,
  estimates,
  loading
}: Props): ComponentProps => {
  const couponCode = estimates?.couponCode
  const couponDiscountAmount = estimates?.couponDiscountAmount ?? ZERO
  const deliveryFee = estimates?.deliveryFee
  const estimatedCreditCardFee = estimates?.estimatedTransactionFee
  const estimatedServiceFee = estimates?.estimatedServiceFee
  const fetchUnlimitedDiscountAmount =
    estimates?.fetchUnlimitedDiscountAmount ?? ZERO

  const estimatedTotal =
    deliveryFee +
    estimatedCreditCardFee +
    estimatedServiceFee -
    couponDiscountAmount -
    fetchUnlimitedDiscountAmount +
    estimatedSubtotal

  return {
    couponCode,
    couponDiscountAmount,
    deliveryFee,
    estimatedCreditCardFee,
    estimatedServiceFee,
    estimatedSubtotal,
    estimatedTotal,
    fetchUnlimitedDiscountAmount,
    loading
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  OrderSummary
)

// @flow
/* eslint-disable max-lines-per-function, max-statements */

import { useCallback, useMemo, useState } from "react"
import MenuItemOptions from "./MenuItemOptions"
import TagManager from "react-gtm-module"
import prepareComponent from "@bluframe/grapple/prepareComponent"

type Props = {|
  +detail: string,
  +description: string,
  +image: string,
  +id: ?string,
  +menuItemOptionGroups: any[],
  +name: string,
  +onAddToOrder: (
    id: string,
    addonIds: any[],
    instructions: string,
    counterValue: number
  ) => () => void,
  +price: number
|}

export type ComponentProps = {|
  +counterValue: number,
  +description: string,
  +detail: string,
  +id: ?string,
  +image: string,
  +instructions: string,
  +loading: boolean,
  +name: string,
  +onAddToOrder: (
    id: string,
    addonIds: any[],
    instructions: string,
    counterValue: number
  ) => () => void,
  +onDecrement: () => void,
  +onIncrement: () => void,
  +menuItemOptions: any[],
  +onSelectAddon: (
    addonId: string | number,
    isRadio: boolean,
    radioName: string
  ) => () => void,
  +onSetInstructions: (event: SyntheticKeyboardEvent<*>) => void,
  +onSetTotals: (id: string) => (total: number) => void,
  +radioValue: any,
  +selected: any[],
  +total: number
|}

const START = 0
const MAX_NAME_LENGTH = 25
const MAX_VIEWPORT_WIDTH = 768
const MIN_BOUNDARY = 1
const DEFAULT_COUNTER_VALUE = 1
const DEFAULT_TOTALS = {}
const DEFAULT_SELECTED = []
const DEFAULT_INSTRUCTIONS = ""
const DECIMALS = 2
const ZERO = 0
const ONE = 1
const DEFAULT_RADIO_VALUE = {}

const usePrepareComponent = ({
  id,
  menuItemOptionGroups,
  onAddToOrder,
  price,
  ...props
}: Props): ComponentProps => {
  const [totals, setTotals] = useState(DEFAULT_TOTALS)
  const [selected, setSelected] = useState(DEFAULT_SELECTED)
  const [instructions, setInstructions] = useState(DEFAULT_INSTRUCTIONS)
  const [counterValue, setCounterValue] = useState(DEFAULT_COUNTER_VALUE)
  const [radioValue, setRadioValue] = useState(DEFAULT_RADIO_VALUE)

  const total = useMemo(
    () =>
      Number(
        Object.values(totals)
          // $FlowFixMe
          .reduce((prev, next) => prev + next, price)
          .toFixed(DECIMALS)
      ),
    [price, totals]
  )

  const onSetTotals = useCallback(
    (optionId: string) => (optionTotal: number) => {
      setTotals({ [optionId]: optionTotal })
    },
    [setTotals]
  )

  const onSelectAddon = useCallback(
    (addonId: string | number, isRadio: boolean, radioName: string) => () => {
      if (selected.find((item) => item === addonId)) {
        if (!isRadio) {
          TagManager.dataLayer({
            dataLayer: {
              event: "removeOptionItem"
            }
          })

          setSelected(selected.filter((item) => item !== addonId))
        }
      } else if (isRadio) {
        setRadioValue({ ...radioValue, [radioName]: addonId })

        setSelected([
          ...selected.filter((item) => item !== radioValue?.[radioName]),
          addonId
        ])
      } else {
        TagManager.dataLayer({
          dataLayer: {
            event: "addOptionItem"
          }
        })

        setSelected([...selected, addonId])
      }
    },
    [radioValue, selected, setSelected]
  )

  const onSetInstructions = useCallback(
    (event: SyntheticKeyboardEvent<*>) => {
      setInstructions(event.currentTarget.value)
    },
    [setInstructions]
  )

  // eslint-disable-next-line prefer-destructuring
  let name = props.name

  const viewPortWidth = Math.max(
    document.documentElement?.clientWidth || ZERO,
    window.innerWidth || ZERO
  )

  if (name.length > MAX_NAME_LENGTH && viewPortWidth < MAX_VIEWPORT_WIDTH) {
    name = props.name.substr(START, MAX_NAME_LENGTH)
    name += "..."
  }

  const onDecrement = () => {
    if (counterValue > MIN_BOUNDARY) {
      setCounterValue(counterValue - ONE)
    }
  }

  const onIncrement = () => {
    setCounterValue(counterValue + ONE)
  }

  return {
    ...props,
    counterValue,
    id,
    instructions,
    loading: false,
    menuItemOptions: menuItemOptionGroups,
    name,
    onAddToOrder,
    onDecrement,
    onIncrement,
    onSelectAddon,
    onSetInstructions,
    onSetTotals,
    radioValue,
    selected,
    total
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  MenuItemOptions
)

// @flow
/* eslint-disable max-lines-per-function */

import Main from "./Main"
import type { PlaceType } from "@fetch/frontend"
import prepareComponent from "@bluframe/grapple/prepareComponent"
import { useEffect } from "react"
import useHasTouchedNonPartnerModal from "hooks/useHasTouchedNonPartnerModal"
import useModal from "hooks/useModal"
import { useParams } from "react-router-dom"

type Props = {|
  +categories: string,
  +isLoggedIn: boolean,
  +onChange: (event: SyntheticInputEvent<EventTarget>) => void,
  +onLogin: () => void,
  +onToggleModal: (options: any) => () => void,
  +options: any[],
  +place: PlaceType
|}

export type ComponentProps = {|
  ...Props
|}

const usePrepareComponent = ({ place, ...props }: Props): ComponentProps => {
  const { id } = useParams()
  const { hasTouchedNonPartnerModal, onSetHasTouchedNonPartnerModal } =
    useHasTouchedNonPartnerModal()

  const {
    modal: { isOpen: isAppModalOpen },
    onSetModal: onSetAppModal
  } = useModal()

  useEffect(() => {
    if (
      place.isPartner ||
      !place.nonPartnerMessage ||
      hasTouchedNonPartnerModal.includes(id) ||
      isAppModalOpen
    ) {
      return
    }

    onSetAppModal({
      content: place.nonPartnerMessage,
      isOpen: true,
      onOkay: () => {
        if (id) {
          onSetHasTouchedNonPartnerModal(id)
        }
      },
      showHome: false,
      showOkay: true,
      showRefresh: false,
      title: "About our fees"
    })
  }, [
    hasTouchedNonPartnerModal,
    id,
    isAppModalOpen,
    onSetAppModal,
    onSetHasTouchedNonPartnerModal,
    place.isPartner,
    place.nonPartnerMessage
  ])

  return {
    ...props,
    place
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Main
)

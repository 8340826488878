// @flow

import { Button } from "./styled"
import { CardElement } from "@stripe/react-stripe-js"
import { type ComponentProps } from "."
import React from "react"

const StripeElement = ({ onSubmit }: ComponentProps) => (
  <form onSubmit={onSubmit}>
    <CardElement
      options={{
        style: {
          base: {
            "::placeholder": {
              color: "#aab7c4"
            },
            color: "#424770",
            fontSize: "16px"
          },
          invalid: {
            color: "#9e2146"
          }
        }
      }}
    />

    <Button type="submit">{`Confirm`}</Button>
  </form>
)

export default StripeElement

// @flow
/* eslint-disable no-ternary */

import Button from "./Button"
import { type ComponentProps } from "."
import React from "react"
import { Wrapper } from "./styled"

const Buttons = ({ noServiceFee, reducedServiceFee }: ComponentProps) => (
  <Wrapper>
    {noServiceFee || reducedServiceFee ? (
      <Button buttonType="NO_SERVICE_FEE" label="Reduced Fees *" />
    ) : null}

    {/* {reducedServiceFee && ( */}
    {/*   <Button buttonType="REDUCED_SERVICE_FEE" label="Reduced Service Fee" /> */}
    {/* )} */}
  </Wrapper>
)

export default Buttons

// @flow
/* eslint-disable max-lines-per-function, react/no-array-index-key, no-ternary */

import {
  Bottom,
  DateAndTime,
  FetchedBy,
  Main,
  Order,
  Recurring,
  Section,
  State,
  Stop,
  StopInstructions,
  StopItem,
  StopItemLabel,
  StopName,
  Stops,
  Top,
  Total,
  TotalAmount,
  TotalLabel,
  Wrapper
} from "./styled"
import { Button } from "@fetch/ui"
import type { ComponentProps } from "."
import React from "react"

const Item = ({
  activeTab,
  dateAndTime,
  driverName,
  estimatedTotal,
  id,
  isReoderConfirm,
  isShowCancel,
  isShowDetails,
  isShowReorder,
  isShowState,
  loading,
  loadingCancelScheduledFetch,
  loadingPostFetch,
  onCancelScheduledFetch,
  onReorder,
  onToggleShowDetails,
  recurring,
  state,
  stops,
  total
}: ComponentProps) => (
  <Wrapper>
    <Top isShowState={isShowState}>
      <DateAndTime>{dateAndTime}</DateAndTime>
      {isShowState && <State>{state}</State>}
    </Top>

    <Main>
      {activeTab === "history" ? (
        <Section>
          <Order>{`Order #${id}`}</Order>
        </Section>
      ) : null}

      {recurring ? (
        <Section>
          <Recurring>{`Recurring: ${recurring}`}</Recurring>
        </Section>
      ) : null}

      <Stops>
        {stops.map((stop) => (
          <Stop key={`${id}-${stop.index}`}>
            <StopName>{stop.name}</StopName>
            {isShowDetails && (
              <>
                {stop.items.map((item, index) => (
                  <StopItem key={`${id}-${stop.index}-${index}`}>
                    <StopItemLabel>{item.label}</StopItemLabel>
                  </StopItem>
                ))}
                {stop.instructions && (
                  <StopInstructions>{stop.instructions}</StopInstructions>
                )}
              </>
            )}
          </Stop>
        ))}
      </Stops>

      {driverName && (
        <Section>
          <FetchedBy>{`Fetched by ${driverName}`}</FetchedBy>
        </Section>
      )}

      {state !== "canceled" && state !== "terminated" && (
        <>
          {total ? (
            <Total>
              <TotalLabel>{`Total:`}</TotalLabel>

              <TotalAmount>{`$${total}`}</TotalAmount>
            </Total>
          ) : null}

          {Boolean(estimatedTotal) && !total ? (
            <Total>
              <TotalLabel>{`Estimated Total:`}</TotalLabel>

              <TotalAmount>{`$${estimatedTotal}`}</TotalAmount>
            </Total>
          ) : null}
        </>
      )}
    </Main>
    <Bottom>
      <Button
        disabled={loading}
        label={isReoderConfirm ? "Back" : "Details"}
        onClick={onToggleShowDetails}
        outlined
      />
      {isShowCancel && (
        <Button
          disabled={loading}
          label={"Cancel"}
          loading={loadingCancelScheduledFetch}
          onClick={onCancelScheduledFetch}
        />
      )}

      {isShowReorder && (
        <Button
          disabled={loading}
          label={isReoderConfirm ? "Checkout" : "Reorder"}
          loading={loadingPostFetch}
          onClick={onReorder}
        />
      )}
    </Bottom>
  </Wrapper>
)

export default Item

// @flow
/* eslint-disable no-ternary, react/jsx-max-depth, max-lines-per-function, react/no-array-index-key */

import {
  AddonItem,
  Addons,
  Container,
  Icons,
  IconsWrapper,
  Instructions,
  ListItem,
  MenuItem,
  MenuItemName,
  MenuItemPrice,
  RightSide,
  Wrapper
} from "./styled"
import { Card, CardTitle, ItemsWrapper } from "components/Sidebar/styled"
import { type ComponentProps } from "."
import type { FetchStop } from "@fetch/frontend"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import ResetButton from "components/buttons/Reset"
import { faTrash } from "@fortawesome/free-solid-svg-icons"

const DEFAULT_DIGITS = 2

const getKey = ({ name, placeId }): string =>
  placeId?.toString() ?? name.replace(/s*/gu, "")

const Items = ({
  onDeleteItem,
  onDeleteStop,
  placeId,
  stops
}: ComponentProps) => (
  <Wrapper>
    {stops.map((stop: FetchStop, stopIndex: number) => (
      <Card key={getKey(stop)}>
        <CardTitle>
          {stop.name}

          {/* for current stop or current custom order dont display delete button */}
          {(placeId
            ? placeId !== String(stop.placeId)
            : Boolean(stop.placeId)) && (
            <Icons>
              <IconsWrapper primary>
                <ResetButton onClick={onDeleteStop(stopIndex)}>
                  <FontAwesomeIcon icon={faTrash} />
                </ResetButton>
              </IconsWrapper>
            </Icons>
          )}
        </CardTitle>

        <ItemsWrapper>
          {stop.items.map(
            ({ id, menuItem, addons, instructions, price, name }, index) => (
              <MenuItem key={`${menuItem?.id ?? id}-${index}`}>
                <Container>
                  <MenuItemName>{menuItem?.name ?? name}</MenuItemName>

                  <RightSide>
                    <MenuItemPrice>
                      {Boolean(menuItem?.price ?? price) &&
                        `$${
                          menuItem?.price?.toFixed(DEFAULT_DIGITS) ??
                          price?.toFixed(DEFAULT_DIGITS)
                        }`}
                    </MenuItemPrice>

                    <Icons>
                      <IconsWrapper>
                        <ResetButton
                          onClick={onDeleteItem(index, menuItem?.id ?? id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </ResetButton>
                      </IconsWrapper>
                    </Icons>
                  </RightSide>
                </Container>

                {instructions && <Instructions>{instructions}</Instructions>}

                {addons && (
                  <Addons>
                    {addons.map((addon) => (
                      <ListItem key={addon.id}>
                        <AddonItem>{addon.name}</AddonItem>

                        <MenuItemPrice>
                          {Boolean(addon.price) &&
                            `$${addon.price.toFixed(DEFAULT_DIGITS)}`}
                        </MenuItemPrice>
                      </ListItem>
                    ))}
                  </Addons>
                )}
              </MenuItem>
            )
          )}
        </ItemsWrapper>
      </Card>
    ))}
  </Wrapper>
)

export default Items

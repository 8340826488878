// @flow

import gql from "graphql-tag"

const GET_CATEGORIES = gql`
  query getCategories {
    categories {
      image
      isSearch
      label
      slug
      title
    }
  }
`

export default GET_CATEGORIES

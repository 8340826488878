// @flow

import MenuItem from "./MenuItem"
import prepareComponent from "hocs/prepareComponent"

type Props = {|
  +detail: string,
  +image: string,
  +name: string,
  +onClick: () => void,
  +price: number
|}

export type ComponentProps = {|
  +detail: string,
  +image: string,
  +name: string,
  +onClick: () => void,
  +price: string
|}

const DIGITS = 2
const START = 0
const MAX_DETAIL_LENGTH = 50
const MAX_NAME_LENGTH = 25

const usePrepareComponent = (props: Props): ComponentProps => {
  // eslint-disable-next-line prefer-destructuring
  let detail = props.detail
  // eslint-disable-next-line prefer-destructuring
  let name = props.name

  if (detail.length > MAX_DETAIL_LENGTH) {
    detail = props.detail.substr(START, MAX_DETAIL_LENGTH)
    detail += "..."
  }

  if (name.length > MAX_NAME_LENGTH) {
    name = props.name.substr(START, MAX_NAME_LENGTH)
    name += "..."
  }

  return {
    ...props,
    detail,
    name,
    price: `$ ${props.price.toFixed(DIGITS)}`
  }
}

export default prepareComponent(usePrepareComponent)(MenuItem)

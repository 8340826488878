// @flow

import gql from "graphql-tag"

const HISTORY = gql`
  query history {
    history {
      dateTime
      driver {
        user {
          firstName
          id
        }
      }
      estimatedTotal
      id
      state
      stops {
        index
        instructions
        items {
          estimatedCost
          index
          label
        }
        latitude
        longitude
        name
        placeId
      }
      total
    }
  }
`

export default HISTORY

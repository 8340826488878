// @flow
/* eslint-disable max-lines, max-params, max-lines-per-function, max-statements */

import type {
  CustomOrderItem,
  FetchStop,
  Menu,
  MenuItem,
  PlaceType
} from "@fetch/frontend"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import GET_PLACE_QUERY from "queries/getPlace"
import Place from "./Place"
import TagManager from "react-gtm-module"
import flatten from "ramda/src/flatten"
import prepareComponent from "@bluframe/grapple/prepareComponent"
import useDropOffAddress from "hooks/useDropOffAddress"
import useFetchState from "hooks/useFetchState"
import { useLazyQuery } from "@apollo/client"
import useUser from "hooks/useUser"

type Props = {||}

export type ComponentProps = {|
  +categories: string,
  +imageSearch: string,
  +items: CustomOrderItem[],
  +isCustomOrderOpen: boolean,
  +isLoggedIn: boolean,
  +isModalOpen: boolean,
  +loading: boolean,
  +onAddToOrder: (
    id: string,
    addonIds: any[],
    instructions: string,
    counterValue: number
  ) => () => void,
  +onAddCustomOrder: (params: any) => void,
  +onChange: (event: SyntheticInputEvent<any>) => void,
  +onDeleteItem: (index: number, id: string) => () => void,
  +onDeleteStop: (index: number) => () => void,
  +onLogin: () => void,
  +onSelectImage: (image: string) => void,
  +onSetItems: (newItems: CustomOrderItem[]) => void,
  +onToggleModal: (options: any) => () => void,
  +options: any[],
  +place: PlaceType,
  +selectedImage: string,
  +selectedItem: ?MenuItem,
  +setImageSearch: (imageSearch: string) => void
|}

const DEFAULT_IS_CUSTOM_ORDER_OPEN = false
const DEFAULT_IS_MODAL_OPEN = false
const DEFAULT_OPTIONS = []
const DEFAULT_PLACE = {}
const DEFAULT_SEARCH_TERM = ""
const DEFAULT_SELECTED_ID = null
const DEFAULT_IMAGE_SEARCH = ""
const DEFAULT_SELECTED_IMAGE = ""
const DEFAULT_ITEM = { image: "", name: "" }

const FIRST = 0
const ZERO = 0

const isASearchMatch =
  (searchTerm: string) =>
  ({ description, name }): boolean => {
    const regExp = new RegExp(searchTerm, "iu")

    return regExp.test(name) || regExp.test(description)
  }

const usePrepareComponent = (): ComponentProps => {
  const { dropOffAddress } = useDropOffAddress()
  const { id } = useParams()
  const [getPlace, { data, error, loading }] = useLazyQuery(GET_PLACE_QUERY, {
    variables: {
      input: {
        externalId: id,
        // eslint-disable-next-line no-undefined
        latitude: dropOffAddress.coordinates.lat || undefined,
        // eslint-disable-next-line no-undefined
        longitude: dropOffAddress.coordinates.lng || undefined
      }
    }
  })

  const [searchTerm, setSearchTerm] = useState(DEFAULT_SEARCH_TERM)
  const [isModalOpen, setIsModalOpen] = useState(DEFAULT_IS_MODAL_OPEN)
  const [selectedItem, setSelectedItem] = useState(DEFAULT_SELECTED_ID)
  const [imageSearch, setImageSearch] = useState(DEFAULT_IMAGE_SEARCH)
  const [selectedImage, setSelectedImage] = useState(DEFAULT_SELECTED_IMAGE)
  const {
    fetch: state,
    onAddItemToOrder,
    onDeleteItem,
    onRemovePlace,
    onSetPlace
  } = useFetchState()
  const [items, setItems] = useState([DEFAULT_ITEM])
  const [isCustomOrderOpen, setIsCustomOrderOpen] = useState(
    DEFAULT_IS_CUSTOM_ORDER_OPEN
  )
  const { user } = useUser()
  const history = useHistory()

  useEffect(() => {
    getPlace()
  }, [getPlace])

  useEffect(() => {
    if (!loading && data) {
      const {
        place: { distance, id: placeId, latitude, longitude, name }
      } = data

      const isPlacePresent =
        state.stops.findIndex(
          (stop: FetchStop) =>
            Number(placeId) === stop.placeId || placeId === stop.placeId
        ) >= ZERO

      if (!isPlacePresent) {
        onSetPlace({
          distance,
          latitude,
          longitude,
          name,
          // eslint-disable-next-line no-ternary
          placeId: isNaN(Number(placeId)) ? placeId : Number(placeId)
        })
      }
    }
  }, [data, loading, onSetPlace, state.stops])

  const options = useMemo(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.log(error.message)
      return DEFAULT_OPTIONS
    }

    if (loading || !data) {
      return DEFAULT_OPTIONS
    }

    return data.place.menus.map((item: MenuItem) => ({
      label: item.name,
      value: item.id
    }))
  }, [data, loading, error])

  const onChange = useCallback((event: SyntheticInputEvent<any>): void => {
    setSearchTerm(event.currentTarget.value)
  }, [])

  const parsedPlace = useMemo(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.log(error.message)

      return DEFAULT_PLACE
    }

    if (loading || !data) {
      return DEFAULT_PLACE
    }

    return {
      ...data.place,
      menus: data.place.menus.map((menuItem: Menu) => ({
        ...menuItem,
        items: menuItem.items.filter(isASearchMatch(searchTerm))
      }))
    }
  }, [data, error, loading, searchTerm])

  const menuItems = useMemo(() => {
    const result = []

    // eslint-disable-next-line no-unused-expressions
    data?.place.menus.forEach((menuItem: any) => {
      result.push(...menuItem.items)
    })

    return result
  }, [data])

  const onToggleModal = useCallback(
    ({ customOrderOpen, imageSearch: imageSearchName, item, modalOpen }) =>
      () => {
        setSelectedItem(item)
        setImageSearch(imageSearchName)
        setIsCustomOrderOpen(Boolean(customOrderOpen))

        if (modalOpen === true || modalOpen === false) {
          setIsModalOpen(modalOpen)
          return
        }

        setIsModalOpen(!isModalOpen)
      },
    [
      isModalOpen,
      setImageSearch,
      setIsCustomOrderOpen,
      setIsModalOpen,
      setSelectedItem
    ]
  )

  const onAddToOrder = useCallback(
    (
        menuItemId: string,
        addonIds: any[],
        instructions: string,
        counterValue: number
      ) =>
      () => {
        TagManager.dataLayer({
          dataLayer: {
            event: "addItemToCart",
            user
          }
        })

        onAddItemToOrder({
          menuItem: Array(counterValue).fill({
            addons: flatten(
              menuItems
                .find((menuItem: MenuItem) => menuItem.id === menuItemId)
                ?.menuItemOptionGroups.map((group: any) =>
                  group.menuItemOptions
                    .filter((option: any) => addonIds.includes(option.id))
                    .map((addon: any) => ({
                      ...addon,
                      groupName: group.name
                    }))
                )
            ),
            instructions,
            menuItem: menuItems.find(
              (menuItem: MenuItem) => menuItem.id === menuItemId
            )
          }),
          placeId: data?.place.id
        })
        setSelectedItem(null)
        setIsModalOpen(false)
      },
    [data?.place.id, menuItems, onAddItemToOrder, setIsModalOpen, user]
  )

  const onAddCustomOrder = useCallback(
    ({ image, name }) => {
      onAddItemToOrder({
        menuItem: [{ menuItem: { id: name, image, name, price: 0 } }],
        placeId: data?.place.id
      })
    },
    [data?.place.id, onAddItemToOrder]
  )

  const onSetItems = (newItems: any) => {
    setItems(newItems)
  }

  const onDeleteItemFromState = useCallback(
    (itemIndex: number, itemId: string) => () => {
      onDeleteItem({ itemIndex, placeId: Number(data?.place.id) })

      const newItems = items.filter(({ name }) => itemId !== name)

      onSetItems(newItems)
    },
    [data?.place.id, items, onDeleteItem]
  )

  const onDeleteStopFromState = useCallback(
    (index: number) => () => {
      onRemovePlace({ index })
    },
    [onRemovePlace]
  )

  const onSelectImage = useCallback(
    (image: string) => {
      setSelectedImage(image)
    },
    [setSelectedImage]
  )

  const categories = useMemo(
    () =>
      (parsedPlace?.categories?.length ?? "") &&
      parsedPlace.categories[FIRST].join(", "),
    [parsedPlace]
  )

  const onLogin = useCallback(() => {
    history.push("/login")
  }, [history])

  return {
    categories,
    imageSearch,
    isCustomOrderOpen,
    isLoggedIn: Boolean(user),
    isModalOpen,
    items,
    loading,
    onAddCustomOrder,
    onAddToOrder,
    onChange,
    onDeleteItem: onDeleteItemFromState,
    onDeleteStop: onDeleteStopFromState,
    onLogin,
    onSelectImage,
    onSetItems,
    onToggleModal,
    options,
    place: parsedPlace,
    selectedImage,
    selectedItem,
    setImageSearch
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Place
)

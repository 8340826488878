// @flow

import { atom, useRecoilState } from "recoil"
import GET_FETCH_IN_PROGRESS from "queries/getFetchInProgress"
import { useEffect } from "react"
import { useLazyQuery } from "@apollo/client"
import { useLocation } from "react-router-dom"
import useUser from "hooks/useUser"

const CHAT_STATES = [
  "accepted",
  "arrivedAtStop",
  "completionPending",
  "finishedWithStop",
  "pending"
]

const TIP_STATES = ["completionPendingForCustomer"]

const DEFAULT_LINK = ""

const redirectFetchState = atom({
  default: DEFAULT_LINK,
  key: "redirectFetchState"
})

const useRedirectFetch = () => {
  const [getFetchInProgress, { data }] = useLazyQuery(GET_FETCH_IN_PROGRESS, {
    fetchPolicy: "cache-and-network"
  })
  const [redirectFetch, setRedirectFetch] = useRecoilState(redirectFetchState)
  const location = useLocation()
  const { user } = useUser()

  useEffect(() => {
    if (user) {
      getFetchInProgress()
    }
  }, [location.pathname, getFetchInProgress, user])

  useEffect(() => {
    if (CHAT_STATES.includes(data?.fetchInProgress?.state)) {
      setRedirectFetch(`/chat/${data.fetchInProgress.id}`)
      return
    }

    if (TIP_STATES.includes(data?.fetchInProgress?.state)) {
      setRedirectFetch(`/tip/${data.fetchInProgress.id}`)
      return
    }

    setRedirectFetch(DEFAULT_LINK)
  }, [data?.fetchInProgress, setRedirectFetch])

  return redirectFetch
}

export default useRedirectFetch

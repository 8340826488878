// @flow

import { ApolloClient, HttpLink, InMemoryCache, from } from "@apollo/client"
import styled, { type StyledComponent } from "styled-components"
import Paragraraph from "components/typography/Paragraph"
import React from "react"
import type { Theme } from "@bluframe/blublocks"
import config from "config/graphql"
import errorModalButtons from "data/errorModalButtons"
import getErrorByNameFromGraphQLErrors from "helpers/getErrorByNameFromGraphQLErrors"
import mediaQueries from "styles/mediaQueries"
import { onError } from "@apollo/client/link/error"
import { setContext } from "@apollo/client/link/context"

const { HOST, PATH, PORT } = config

const ONE = 1

const httpLink = new HttpLink({
  uri: `${HOST}:${PORT}${PATH}`
})

export const ErrorMessage: StyledComponent<
  {||},
  Theme,
  StyledComponent<{||}, Theme, HTMLParagraphElement>
> = styled(Paragraraph)`
  font-size: 18px;
  text-align: center;

  ${mediaQueries.tablet} {
    font-size: 20px;
  }
`

const errorLink = (onSetModal) =>
  onError(({ graphQLErrors }) => {
    if (graphQLErrors && graphQLErrors.length) {
      const userDisplayable = getErrorByNameFromGraphQLErrors({
        errorName: "UserDisplayable",
        graphQLErrors
      })

      const noAvailableDrivers = getErrorByNameFromGraphQLErrors({
        errorName: "NoAvailableDrivers",
        graphQLErrors
      })

      if (noAvailableDrivers && graphQLErrors.length === ONE) {
        return
      }

      if (userDisplayable) {
        onSetModal({
          content: userDisplayable,
          isOpen: true,
          title: "Whoops, Error!",
          ...(errorModalButtons[userDisplayable] || {})
        })
        return
      }

      // eslint-disable-next-line no-console
      console.log(graphQLErrors)

      onSetModal({
        content: (
          <ErrorMessage>{"Click here to return to Home Page"}</ErrorMessage>
        ),
        isOpen: true,
        title: "Whoops, Error!"
      })
    }
  })

const authLink = setContext((__, { headers }) => {
  // Get the authentication token from local storage if it exists
  const token = localStorage.getItem("fetchToken")
  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      // eslint-disable-next-line no-ternary
      authorization: token ? `Bearer ${token}` : ""
    }
  }
})

export const graphql = (onSetModal: any) =>
  new ApolloClient({
    cache: new InMemoryCache(),
    defaultOptions: {
      mutate: {
        errorPolicy: "all"
      },
      query: {
        errorPolicy: "all"
      }
    },
    link: from([authLink, errorLink(onSetModal), httpLink])
  })

export default graphql

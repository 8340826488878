// @flow

import styled, { type StyledComponent } from "styled-components"
import Button from "./Reset"
import type { Theme } from "@bluframe/blublocks"

const Base: StyledComponent<
  any,
  Theme,
  StyledComponent<any, Theme, HTMLElement>
> = styled(Button)`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-family: "Roboto", Arial, open-sans;
  justify-content: center;
  text-align: center;
  transition: 0.25s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`

export default Base

// @flow

import { useEffect, useRef } from "react"

const usePrevious = (state: any) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = state
  })

  return ref.current
}

export default usePrevious

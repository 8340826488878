// @flow

import gql from "graphql-tag"

const GET_LAST_4 = gql`
  query getLast4 {
    last4
  }
`

export default GET_LAST_4

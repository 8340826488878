// @flow

import styled, { type StyledComponent } from "styled-components"
import { Box } from "@fetch/ui"
import H3 from "components/typography/H3"
import H4 from "components/typography/H4"
import type { Theme } from "@bluframe/blublocks"
import colors from "styles/colors"

export const Wrapper: StyledComponent<any, Theme, any> = styled(Box)`
  grid-template-rows: auto auto 1fr;
`

export const Top: StyledComponent<any, Theme, HTMLElement> = styled.div`
  align-self: flex-start;
  ${({ isEditing }) => !isEditing && `border-bottom: 1px solid ${colors.alto}`}
  width: 100%;
`

export const Main: StyledComponent<any, Theme, HTMLElement> = styled.div`
  align-self: flex-start;
  display: grid;
  row-gap: 20px;
`

export const Bottom: StyledComponent<any, Theme, HTMLElement> = styled.div`
  align-self: flex-end;
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`

export const DateAndTime: StyledComponent<
  any,
  Theme,
  StyledComponent<any, Theme, HTMLElement>
> = styled(H3)``

export const Order: StyledComponent<
  any,
  Theme,
  StyledComponent<any, Theme, HTMLElement>
> = styled(H4)`
  font-weight: 400;
`

export const Stops: StyledComponent<any, Theme, HTMLElement> = styled.div`
  ${({ isEditing }) => !isEditing && `border-bottom: 1px solid ${colors.alto}`}
`

export const StopName: StyledComponent<any, Theme, HTMLElement> = styled.p`
  font-size: 16px;
  margin-bottom: 5px;
`

export const Section: StyledComponent<any, Theme, HTMLElement> = styled.div`
  ${({ isEditing }) => !isEditing && `border-bottom: 1px solid ${colors.alto}`}
`

export const Input: StyledComponent<any, Theme, HTMLElement> = styled.input`
  border: 0.5px solid ${colors.mercury};
  border-radius: 3px;
  box-shadow: 0px 0px 4px ${colors.stratos};
  color: ${colors.doveGray};
  margin-bottom: 0;
  padding: 8px 20px;
  width: 100%;

  &:hover,
  &:focus {
    border: 0.5px solid ${colors.mercury};
  }

  &::placeholder {
    color: ${colors.alto};
    font-size: 16px;
  }
`

export const TextArea: StyledComponent<
  any,
  Theme,
  HTMLElement
> = styled.textarea`
  border: 0.5px solid ${colors.mercury};
  border-radius: 3px;
  box-shadow: 0px 0px 4px ${colors.stratos};
  color: ${colors.doveGray};
  height: 60px;
  margin: 0;
  min-height: 20px;
  padding: 8px 20px;
  resize: none;

  &:hover,
  &:focus {
    border: 0.5px solid ${colors.mercury};
  }

  &::placeholder {
    color: ${colors.alto};
    font-size: 16px;
  }
`

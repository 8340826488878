// @flow
/* eslint-disable no-ternary, no-constant-binary-expression, max-lines-per-function */

import groupBy from "ramda/src/groupBy"
import prop from "ramda/src/prop"
import useDeliveryTime from "hooks/useDeliveryTime"
import { useMemo } from "react"

type Props = any

const FIRST = 0
const BASE_PRICE = 100
const DIGITS = 2
const ZERO = 0

const getItemLabel = (addonsString, item) => {
  /*
   * We can differentiate labels for regular items and custom via
   * price as custom price are always === 0 and regular always > 0
   */
  let label = item.menuItem
    ? `${item.menuItem.name}${
        item.menuItem.price > ZERO
          ? ` $${item.menuItem.price.toFixed(DIGITS)}`
          : ""
      }`
    : item.name

  if (addonsString) {
    label += ` - ${addonsString}`
  }

  if (item.instructions) {
    label += ` (${item.instructions})`
  }

  return label
}

const prepareItem = (item, index) => {
  const groupedAddons = groupBy(prop("groupName"))(item.addons || [])

  const addonsString = (Object.entries(groupedAddons): any)
    ?.map(([groupName, addons]) => {
      const addonNames = addons
        .map((addon) => `${addon.name} + $${addon.price.toFixed(DIGITS)}`)
        .join(", ")

      return `${groupName}: ${addonNames}`
    })
    .join("; ")

  return {
    estimatedCost: Math.ceil(
      item.menuItem?.price * BASE_PRICE +
        item?.addons?.reduce((acc, curr) => acc + curr.price, FIRST) *
          BASE_PRICE ?? FIRST
    ),
    index,
    label: getItemLabel(addonsString, item)
  }
}

const prepareItems = (stop: any) => [...stop.items.map(prepareItem)]

const useFetch = ({
  coupon,
  deliveryInstructions,
  dropOffAddress,
  stops
}: Props) => {
  const { deliveryTime } = useDeliveryTime()

  const scheduledOn = useMemo(() => {
    // Send scheduledOn as string as GraphQL cannot deal with a timestamp
    // We convert it in the postValidateFetch and postFetch resolvers
    if (
      deliveryTime.nowOrLater === "later" &&
      deliveryTime.deliveryDate &&
      deliveryTime.deliveryTime
    ) {
      return new Date(
        `${deliveryTime.deliveryDate}T${deliveryTime.deliveryTime}:00`
      ).toISOString()
    }

    // Send null if deliveryTime is now
    return null
  }, [deliveryTime])

  const recurring = useMemo(() => {
    if (deliveryTime.oneOffOrRecurring === "recurring") {
      return deliveryTime.recurring
    }

    return null
  }, [deliveryTime])

  const dropOffStop = useMemo(
    () => ({
      // We really wanna remove these hardcoded indexes from API
      // For now this is the best way we have to add a required index in
      // a dynamic way
      index: stops.length,
      instructions: deliveryInstructions,
      latitude: dropOffAddress.coordinates.lat,
      longitude: dropOffAddress.coordinates.lng,
      name: dropOffAddress.label
    }),
    [dropOffAddress, deliveryInstructions, stops]
  )

  const fetch = useMemo(
    () => ({
      couponCode: coupon?.postValidateCoupon?.code,
      recurring,
      scheduledOn,
      stops: [
        // eslint-disable-next-line no-unused-vars
        ...stops.map(({ distance, ...stop }) => ({
          ...stop,
          items: prepareItems(stop)
        })),

        dropOffStop
      ]
    }),
    [coupon, dropOffStop, recurring, scheduledOn, stops]
  )

  return fetch
}

export default useFetch

// @flow

import styled, { type StyledComponent } from "styled-components"
import mediaQueries from "styles/mediaQueries"

export const Wrapper: StyledComponent<any, mixed, HTMLElement> = styled.div`
  display: flex;
  justify-content: flex-start;
  position: absolute;
  left: -25px;
  top: 20px;

  ${mediaQueries.tablet} {
    justify-content: flex-start;
  }
`

// @flow

import gql from "graphql-tag"

const POST_FETCH_MUTATION = gql`
  mutation postFetch($fetch: FetchInput!) {
    postFetch(fetch: $fetch) {
      id
    }
  }
`

export default POST_FETCH_MUTATION

// @flow

import { Image, Wrapper } from "./styled"
import { type ComponentProps } from "."
import GoogleLogin from "react-google-login"
import PhoneLogin from "./PhoneLogin"
import React from "react"

const Login = ({
  googleClientId,
  onFailure,
  onSetPhoneNumber,
  onSuccess
}: ComponentProps) => (
  <Wrapper>
    <PhoneLogin onSetPhoneNumber={onSetPhoneNumber} />

    <GoogleLogin
      buttonText="Login via Google"
      clientId={googleClientId}
      cookiePolicy="single_host_origin"
      onFailure={onFailure}
      onSuccess={onSuccess}
      // eslint-disable-next-line
      render={(renderProps: any) => (
        <Image
          // eslint-disable-next-line
          onClick={renderProps.onClick}
          src="/images/google-login.png"
        />
      )}
    />
  </Wrapper>
)

export default Login

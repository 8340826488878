// @flow

import AddressBox from "./AddressBox"
import { type ComponentProps } from "."
import React from "react"
import { Wrapper } from "./styled"

const PickUpAddress = ({
  onAddressSubmit,
  onInputChange,
  pickUpAddress
}: ComponentProps) => (
  <Wrapper>
    <AddressBox
      deliveryAddress={pickUpAddress}
      onAddressSubmit={onAddressSubmit}
      onInputChange={onInputChange}
    />
  </Wrapper>
)

export default PickUpAddress

// @flow

import Route from "./Route"
import prepareComponent from "hocs/prepareComponent"
import useDropOffAddress from "hooks/useDropOffAddress"
import { useHistory } from "react-router-dom"

export type ComponentProps = {
  +component: React$AbstractComponent<*, *>,
  +isLoggedInOnly?: boolean,
  +path: string,
  +skipDropOffAddress?: boolean
}

const usePrepareComponent = ({
  component,
  isLoggedInOnly,
  path,
  skipDropOffAddress
}: ComponentProps) => {
  const { dropOffAddress } = useDropOffAddress()
  const history = useHistory()

  if (
    !skipDropOffAddress &&
    !dropOffAddress.value &&
    path !== "/dropoff-address"
  ) {
    return history.push("/dropoff-address")
  }

  const token = localStorage.getItem("fetchToken")

  if (isLoggedInOnly && !token) {
    return history.push("/login")
  }

  return { component, path }
}

export default prepareComponent(usePrepareComponent)(Route)

// @flow
/* eslint-disable no-ternary, no-nested-ternary */

import styled, { type StyledComponent } from "styled-components"
import colors from "styles/colors"
import mediaQueries from "styles/mediaQueries"

export const Wrapper: StyledComponent<any, mixed, HTMLElement> = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.alto};
  border-radius: 3px;
  box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.5);
  color: ${colors.mineShaft};
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  padding: 20px 25px;
  position: absolute;
  right: 5px;
  text-align: left;
  top: 75px;
  width: 200px;
  font-family: "Roboto Condensed", Arial, open-sans;

  ${mediaQueries.desktop} {
    right: 24px;
  }
`

export const Arrow: StyledComponent<any, mixed, HTMLElement> = styled.i`
  border: 9px solid transparent;
  bottom: 100%;
  font-size: 0;
  height: 0;
  position: absolute;
  right: 20px;
  width: 0;
`

export const ArrowBefore: StyledComponent<
  any,
  mixed,
  StyledComponent<any, mixed, HTMLElement>
> = styled(Arrow)`
  border-bottom-color: ${colors.bonJour};
  margin-right: 0;
`

export const ArrowAfter: StyledComponent<
  any,
  mixed,
  StyledComponent<any, mixed, HTMLElement>
> = styled(Arrow)`
  border-bottom-color: ${colors.white};
  margin-bottom: -0.5px;
  margin-right: 1px;
`

export const Header: StyledComponent<any, mixed, HTMLElement> = styled.div`
  color: ${colors.mineShaft};
  cursor: default;
  font-size: 18px;
  text-align: center;
  position: relative;
`

export const Divider: StyledComponent<any, mixed, HTMLElement> = styled.hr`
  border: 0;
  border-top: 1px solid ${colors.cararra};
  box-sizing: content-box;
  height: 0;
  margin-bottom: 20px;
  margin-top: 10px;
`

export const Navigation: StyledComponent<any, mixed, HTMLElement> = styled.ul`
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;

  &:before {
    display: table;
    content: " ";
  }
`

export const NavigationItem: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.li`
  display: block;
  position: relative;
`

export const Link: StyledComponent<any, mixed, HTMLElement> = styled.span`
  background: none;
  color: ${({ $isDestructive, disabled }) =>
    disabled
      ? colors.alto
      : $isDestructive
      ? colors.sunsetOrange
      : colors.mineShaft};
  ${({ disabled }) => disabled && `cursor: default`};
  font-size: 18px;
  cursor: pointer;
  padding: 6px 0;
  position: relative;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const IconWrapper: StyledComponent<any, mixed, HTMLElement> = styled.div`
  position: absolute;
  top: -7px;
  left: -7px;

  &:hover {
    cursor: pointer;
  }
`

// @flow

import styled, { type StyledComponent } from "styled-components"
import type { Theme } from "@bluframe/blublocks"
import colors from "styles/colors"
import mediaQueries from "styles/mediaQueries"

const H1: StyledComponent<any, Theme, HTMLElement> = styled.h1`
  color: ${colors.thunder};
  font-size: 20px;

  ${mediaQueries.desktop} {
    font-size: 28px;
    margin-bottom: 20px;
  }
`

export default H1

// @flow

import { Label, Main, Title, Wrapper } from "./styled"
import { type ComponentProps } from "."
import React from "react"

const CarouselWheel = ({ image, label, link, slug, title }: ComponentProps) => (
  <Wrapper to={link}>
    <Main image={image}>{title && <Title>{title}</Title>}</Main>

    <Label slug={slug}>{label}</Label>
  </Wrapper>
)

export default CarouselWheel

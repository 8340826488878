// @flow

import { Email, Item, Link, Phone, Text, Wrapper } from "./styled"
import {
  faEnvelope,
  faGlobeAmericas,
  faPhone
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import H1 from "components/typography/H1"
import React from "react"

const About = () => (
  <Wrapper>
    <H1>{"About"}</H1>

    <Text>
      {
        "Fetchdelivery.com delivers anything and everything from hundreds of local businesses including restaurants, cafes, food trucks, grocery stores, convenience stores, flower shops, hardware , office supplies, and more..."
      }
    </Text>

    <Item>
      <FontAwesomeIcon icon={faGlobeAmericas} />

      <Link href="https://fetchdelivery.com/">{"Visit Fetchdelivery.com"}</Link>
    </Item>

    <Item>
      <FontAwesomeIcon icon={faPhone} />

      <Phone href="tel:+15054633824">{"Call 505-GO-FETCH"}</Phone>
    </Item>

    <Item>
      <FontAwesomeIcon icon={faEnvelope} />

      <Email href="mailto:support@fetchdelivery.com">
        {"support@fetchdelivery.com"}
      </Email>
    </Item>
  </Wrapper>
)

export default About

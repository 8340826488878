// @flow

import { useEffect, useState } from "react"
import CollapsibleBox from "./CollapsibleBox"
import prepareComponent from "hocs/prepareComponent"

export const STATUSES = Object.freeze({
  ARRIVED: "arrived",
  FINISHED: "finished"
})

type Props = {
  +icon: any,
  +items: any[],
  +status: string,
  +title: string,
  +instructions: string
}

export type ComponentProps = Props & {
  +isToggled: boolean,
  +onToggleBox: () => void
}

const usePrepareComponent = ({
  icon,
  instructions,
  items,
  status,
  title
}: Props): ComponentProps => {
  const [isToggled, setIsToggled] = useState(status !== STATUSES.FINISHED)

  const onToggleBox = () => {
    setIsToggled(!isToggled)
  }

  useEffect(() => {
    if (status === STATUSES.FINISHED) {
      setIsToggled(false)
    }
  }, [status])

  return {
    icon,
    instructions,
    isToggled,
    items,
    onToggleBox,
    status,
    title
  }
}

export default prepareComponent(usePrepareComponent)(CollapsibleBox)

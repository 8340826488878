// @flow

import { Description, Heading, Image, Name, Wrapper } from "./styled"
import type { ComponentProps } from "."
import React from "react"

const Top = ({ detail, image, name }: ComponentProps) => (
  <Wrapper>
    <Heading>
      <Name>{name}</Name>
    </Heading>

    {image && <Image src={image} />}

    <Description>{detail}</Description>
  </Wrapper>
)

export default Top

// @flow
/* eslint-disable no-ternary */

import styled, { type StyledComponent } from "styled-components"
import { Link } from "react-router-dom"
import React from "react"
import colors from "styles/colors"
import mediaQueries from "styles/mediaQueries"

export const Wrapper: StyledComponent<any, mixed, any> = styled(
  // eslint-disable-next-line no-unused-vars
  ({ isPartner, ...props }) => <Link {...props} />
)`
  cursor: pointer;
  background-color: ${colors.white};
  border: ${({ isPartner }) =>
    isPartner ? "none" : `1px solid ${colors.alto}`};
  border-radius: 5px;
  box-shadow: 0px 8px 24px ${colors.blackRussian};
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;
  text-decoration: none;

  &:nth-of-type(1) {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:nth-last-of-type(1) {
    ${({ isPartner }) => (isPartner ? "border: none" : "")};
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  ${mediaQueries.tablet} {
    width: 42%;
  }

  ${mediaQueries.desktop} {
    width: 44.6%;
  }
`

export const Image: StyledComponent<any, mixed, HTMLElement> = styled.div`
  background-image: url(${({ image }) => image});
  background-position: center;
  background-size: cover;
  height: 160px;
  margin-right: 20px;
  width: 100%;

  ${mediaQueries.tablet} {
    height: 173px;
  }
`

export const Main: StyledComponent<any, mixed, HTMLElement> = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  ${({ isPartner, isThereImage }) =>
    !isThereImage && isPartner && "margin-top: 50px"};
`

export const Name: StyledComponent<any, mixed, HTMLElement> = styled.h2`
  color: ${colors.thunder};
  font-family: "Roboto", Arial, open-sans;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0px;
  margin: 0 0 10px;

  ${mediaQueries.tablet} {
    font-size: 26px;
  }

  svg {
    margin-right: 20px;
  }
`

export const Address: StyledComponent<any, mixed, HTMLElement> = styled.h3`
  color: ${colors.doveGray};
  font-family: "Roboto", Arial, open-sans;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0px;
  margin: 0 0 5px;

  ${mediaQueries.tablet} {
    font-size: 18px;
  }
`

export const Details: StyledComponent<any, mixed, HTMLElement> = styled.h3`
  color: ${colors.doveGray};
  font-family: "Roboto", Arial, open-sans;
  font-weight: 500;
  font-size: 12px;
  margin: 0;

  ${mediaQueries.tablet} {
    font-size: 20px;
  }
`

// @flow
/* eslint-disable react/jsx-max-depth, max-lines-per-function */

import {
  Add,
  Container,
  Instructions,
  InstructionsWrapper,
  Wrapper
} from "./styled"
import Box from "./Box"
import { Button } from "@fetch/ui"
import type { ComponentProps } from "."
import Quantity from "./Quantity"
import React from "react"
import Top from "./Top"

const ONE = 1
const DEFAULT_DIGITS = 2

const MenuItemOptions = ({
  counterValue,
  detail,
  id,
  image,
  instructions,
  loading,
  menuItemOptions,
  name,
  onAddToOrder,
  onDecrement,
  onIncrement,
  onSelectAddon,
  onSetInstructions,
  onSetTotals,
  selected,
  radioValue,
  total
}: ComponentProps) => (
  <Wrapper>
    {!loading && id && (
      <>
        <Container>
          <Top detail={detail} image={image} name={name} />

          {menuItemOptions?.map(
            ({
              id: optionId,
              maxSelections,
              minSelections,
              menuItemOptions: options,
              name: optionName
            }) => (
              <Box
                isRadio={
                  maxSelections === minSelections && maxSelections === ONE
                }
                key={optionId}
                onSelectAddon={onSelectAddon}
                onSetTotals={onSetTotals(optionId)}
                options={options}
                radioValue={radioValue}
                selected={selected}
                title={optionName}
              />
            )
          )}

          <InstructionsWrapper>
            <Instructions
              onChange={onSetInstructions}
              placeholder="Additional Instructions or Needs"
              value={instructions}
            />
          </InstructionsWrapper>

          <Quantity
            counterValue={counterValue}
            onDecrement={onDecrement}
            onIncrement={onIncrement}
          />
        </Container>

        <Add>
          <Button
            expanded
            label={`Add to order / $${(total * counterValue).toFixed(
              DEFAULT_DIGITS
            )}`}
            onClick={onAddToOrder(id, selected, instructions, counterValue)}
          />
        </Add>
      </>
    )}
  </Wrapper>
)

export default MenuItemOptions

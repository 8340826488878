// @flow
/* eslint-disable no-ternary */

import styled, { type StyledComponent } from "styled-components"
import BaseH1 from "components/typography/H1"
import ResetButton from "components/buttons/Reset"
import type { Theme } from "@bluframe/blublocks"
import colors from "styles/colors"
import mediaQueries from "@bluframe/blublocks/mediaQueries"
import { rgba } from "polished"

const ITEM_ALPHA = 0.3

export const Wrapper: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  padding: 55px 20px 0;
  width: 100%;

  ${mediaQueries.tablet} {
    padding: 0;
  }
`

export const Heading: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  align-items: center;
  background-color: ${colors.white};
  border-bottom: 2px solid ${colors.whiteRock};
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;

  ${mediaQueries.desktop} {
    align-items: flex-start;
    justify-content: space-between;
    margin: 40px 0 20px;
    box-shadow: 0px 8px 24px ${rgba(colors.black, ITEM_ALPHA)};
    border-radius: 5px;
  }
`

export const Details: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  font-family: Roboto, Arial, open-sans;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${colors.doveGray};

  ${mediaQueries.desktop} {
    font-size: 16px;
  }
`

export const DetailItem: StyledComponent<{||}, Theme, HTMLElement> = styled.p`
  font-size: 14px;
  margin: 0;
`

export const Top: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaQueries.desktop} {
    padding-right: 10px;
    padding-left: 30px;
  }
`

export const Image: StyledComponent<{||}, Theme, HTMLElement> = styled.img`
  height: 165px;
  object-fit: cover;
  width: 100%;

  ${mediaQueries.desktop} {
    display: none;
  }
`

export const FlexWrapper: StyledComponent<
  {||},
  Theme,
  HTMLElement
> = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${mediaQueries.desktop} {
    flex-direction: row;
  }
`

export const H1: StyledComponent<
  {||},
  Theme,
  StyledComponent<{||}, Theme, HTMLElement>
> = styled(BaseH1)`
  margin-bottom: 6px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0px;
  font-size: 26px;

  svg {
    margin-right: 20px;
    width: 32px;
    height: 30px;
  }

  ${mediaQueries.desktop} {
    font-size: 30px;
  }
`

export const DesktopImage: StyledComponent<
  {||},
  Theme,
  HTMLElement
> = styled.img`
  display: none;

  ${mediaQueries.desktop} {
    display: block;
    object-fit: cover;
    width: 100%;
    height: auto;
    aspect-ratio: 1134 / 300;
    border-radius: 5px 5px 0px 0px;
  }
`

export const Category: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  display: grid;
  padding: 0;
  row-gap: 20px;

  ${mediaQueries.tablet} {
    column-gap: 40px;
    grid-template-columns: 1fr 1fr;
    row-gap: 40px;
    padding: 0;
  }
`

export const Button: StyledComponent<
  {||},
  Theme,
  StyledComponent<{||}, Theme, HTMLElement>
> = styled(ResetButton)`
  cursor: pointer;

  & svg {
    color: ${({ theme }) => theme.palette.secondary.main};
    font-size: 22px;
  }
`

export const CustomOrder: StyledComponent<
  {||},
  Theme,
  HTMLElement
> = styled.div`
  align-items: center;
  background: ${colors.white};
  box-shadow: 1px 1px 10px ${rgba(colors.black, ITEM_ALPHA)};
  border-radius: 3px;
  color: ${({ theme }) => theme.palette.secondary.main};
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
  padding: 10px 20px;
  width: 100%;

  & button {
    margin-right: 10px;
  }

  ${mediaQueries.tablet} {
    flex: 0 0 auto;
    width: 330px;
  }
`

export const ServiceFeeLabel: StyledComponent<
  {| +isThereImage: boolean |},
  Theme,
  HTMLElement
> = styled.div`
  background: ${colors.eminence} 0% 0% no-repeat padding-box;
  border-radius: 20px;
  color: ${colors.white};
  font-size: 15px;
  left: -12px;
  letter-spacing: 0;
  padding: 6px 25px;
  position: absolute;
  top: ${({ isThereImage }) => (isThereImage ? "10px" : "-25px")};

  ${mediaQueries.desktop} {
    font-size: 18px;
    left: -22px;
    top: ${({ isThereImage }) => (isThereImage ? "16px" : "-20px")};
  }
`

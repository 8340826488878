// @flow

import styled, { type StyledComponent } from "styled-components"
import colors from "styles/colors"

export const Wrapper: StyledComponent<any, mixed, HTMLElement> = styled.div`
  align-items: center;
  background: ${colors.white};
  display: flex;
  height: calc(100vh - 76px);
  flex-direction: column;
  padding-top: 100px;
`

export const Image: StyledComponent<any, mixed, HTMLElement> = styled.img`
  cursor: pointer;
  margin-top: 20px;
  width: 250px;
`

export const FacebookImage: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.img`
  margin-top: 20px;
  cursor: pointer;
  width: 243px;
  height: 56px;
`

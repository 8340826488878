// @flow

import styled, { type StyledComponent } from "styled-components"
import colors from "styles/colors"

export const TextArea: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.textarea`
  box-shadow: 0px 0px 4px ${colors.stratos};
  border: 0.5px solid ${colors.mercury};
  border-radius: 3px;
  color: ${({ theme }) => theme.palette.text.main};
  font-size: 14px;
  height: 60px;
  margin: 0;
  min-height: 20px;
  resize: none;

  &::placeholder {
    color: ${colors.alto};
  }

  &:disabled {
    background: ${colors.alabaster};
  }
`

export const Card: StyledComponent<any, mixed, HTMLElement> = styled.div`
  background: ${colors.white} 0% 0% no-repeat padding-box;
  border-radius: 3px;
  position: relative;
  padding: 0 20px 20px;

  &:before {
    content: " ";
    height: 100%;
    position: absolute;
    top: 0;
    width: 15px;
    left: -15px;
    box-shadow: -7px 0 15px -15px ${colors.stratos} inset;
  }
`

export const CardTitle: StyledComponent<any, mixed, HTMLElement> = styled.div`
  text-align: left;
  font-family: Roboto, Arial, open-sans;
  font-size: 22px;
  letter-spacing: 0px;
  color: ${colors.thunder};
  margin-bottom: 10px;
`

export const DeliveryRouteItem: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  align-items: center;
  display: flex;
  text-align: left;
  font-family: Roboto, Arial, open-sans;
  font-size: 14px;
  letter-spacing: 0px;
  color: ${colors.doveGray};
  ${({ isClickable }) => isClickable && "cursor: pointer;"};
  padding-top: 5px;
  padding-bottom: 5px;

  svg {
    padding-right: 5px;
    width: 26px !important;
  }
`

export const ItemWrapper: StyledComponent<any, mixed, HTMLElement> = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`

export const ItemsWrapper: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  display: flex;
  flex-direction: column;
`

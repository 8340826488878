// @flow

import Button from "./Button"
import type { PlaceButtonType } from "@fetch/frontend"

export type ComponentProps = {
  +buttonType: PlaceButtonType,
  +label: string
}

export default Button

// @flow

import styled, { type StyledComponent } from "styled-components"
import { Link } from "react-router-dom"
import colors from "styles/colors"
import mediaQueries from "styles/mediaQueries"

export const Grid: StyledComponent<any, mixed, HTMLElement> = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  ${mediaQueries.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  ${mediaQueries.desktop} {
    justify-content: space-between;

    a {
      &:first-of-type {
        width: 48%;
      }

      &:nth-of-type(2) {
        width: 48%;
      }

      padding: 0px;
      width: 30%;
    }
`

export const NotFound: StyledComponent<any, mixed, HTMLElement> = styled.h2`
  font-size: 20px;
  margin-top: 40px;
  text-align: center;
`

export const LoadingWrapper: StyledComponent<
  any,
  mixed,
  HTMLElement
> = styled.div`
  display: flex;
  justify-content: center;
`

export const Top: StyledComponent<any, mixed, HTMLElement> = styled.div`
  font: normal normal 900 30px/48px Roboto;
  color: ${colors.outerSpace};
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
    margin-top: -5px;
  }
`

export const SeeAll: StyledComponent<any, mixed, any> = styled(Link)`
  color: ${colors.eminence};
  margin-left: 20px;
  font: normal normal bold 18px/27px Roboto;
`

export const Wrapper: StyledComponent<any, mixed, HTMLElement> = styled.div`
  margin: 0 auto;
  padding: 20px 20px 0;

  ${mediaQueries.tablet} {
    max-width: 1920px;
    padding: 40px 60px 0;
  }
`

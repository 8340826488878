// @flow

import type { ChatMessage } from "@fetch/frontend"
import compose from "ramda/src/compose"
import filter from "ramda/src/filter"
import getMessage from "./getMessage"
import getMessagesData from "./getMessagesData"
import map from "ramda/src/map"
import { useMemo } from "react"

const MESSAGE_FILTERS = [
  "accept",
  "arriveAtStop",
  "finishPurchase",
  "sendImage",
  "sendMessage"
]

const useMessages = (
  actionsData?: any,
  driverName: string,
  photo?: string
): ChatMessage[] => {
  const defaultMessages = getMessagesData(driverName)
  const user = JSON.parse(localStorage.getItem("user") || "{}")

  const messages = useMemo(() => {
    const introMessages = [
      defaultMessages.thankYou,
      defaultMessages.driversAvailable
    ]

    return compose(
      map(getMessage(driverName, user.id, photo)),
      filter((item) => MESSAGE_FILTERS.includes(item.type))
    )([...introMessages, ...(actionsData?.actions ?? [])])
  }, [actionsData, defaultMessages, driverName, photo, user.id])

  return messages
}

export default useMessages

// @flow

import styled, { type StyledComponent } from "styled-components"
import mediaQueries from "styles/mediaQueries"

export const Items: StyledComponent<any, mixed, HTMLElement> = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-bottom: 20px;
  margin-top: 20px;
  row-gap: 20px;

  ${mediaQueries.tablet} {
    column-gap: 40px;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 40px;
    row-gap: 40px;
  }
`

// @flow

import styled, { type StyledComponent } from "styled-components"
import { Link } from "react-router-dom"
import colors from "styles/colors"

export const Wrapper: StyledComponent<any, mixed, any> = styled(Link)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  box-shadow: 1px 1px 5px ${colors.blackRussian};
`

export const Main: StyledComponent<any, mixed, HTMLElement> = styled.div`
  background-image: url(${({ image }) => image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 10px;
  height: 160px;
`

export const Label: StyledComponent<any, mixed, HTMLElement> = styled.div`
  align-items: center;
  background-color: ${colors.white};
  color: ${colors.thunder};
  display: flex;
  font-family: "Roboto Condensed", Arial, open-sans;
  font-weight: bold;
  font-size: 20px;
  height: 60px;
  padding: 0 10px;
`

export const Title: StyledComponent<any, mixed, HTMLElement> = styled.h2`
  color: ${colors.white};
  font-family: "Roboto Condensed", Arial, open-sans;
  font-weight: bold;
  font-size: 30px;
  margin: 0;
  max-width: 100px;
`

// @flow

import styled, { type StyledComponent } from "styled-components"
import type { Theme } from "@bluframe/blublocks"
import colors from "styles/colors"
import mediaQueries from "styles/mediaQueries"

export const Container: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;
`

export const PhoneLink: StyledComponent<{||}, Theme, HTMLElement> = styled.a`
  background: ${colors.thunder} 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 3px;
  color: ${colors.white};
  font-family: "Roboto", Arial, open-sans;
  font-size: 18px;
  height: 45px;
  letter-spacing: 0.27px;
  text-align: center;
  text-decoration: none;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 18px;

  svg {
    margin-right: 10px;
  }

  &:hover {
    opacity: 0.8;
    color: white;
  }

  ${mediaQueries.tablet} {
    font-size: 20px;
  }
`

export const HeadLine: StyledComponent<{||}, Theme, HTMLElement> = styled.h2`
  font-family: "Roboto", Arial, open-sans, serif;
  font-size: 26px;
  margin-bottom: 20px;
  text-align: left;

  &:after {
    content: "—";
    display: block;
    width: 10%;
    margin: -5px auto 0;
  }

  ${mediaQueries.tablet} {
    font-size: 34px;
  }
`

export const CrossWrapper: StyledComponent<
  {||},
  Theme,
  HTMLElement
> = styled.div`
  left: 30px;
  position: absolute;
  top: 20px;
`

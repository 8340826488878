// @flow
/* eslint-disable react/jsx-max-depth */

import {
  Card,
  CardTitle,
  DeliveryRouteItem,
  ItemWrapper,
  ItemsWrapper
} from "components/Sidebar/styled"
import { faHome, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import AddressDropdown from "components/AddressDropdown"
import type { ComponentProps } from "."
import type { FetchStop } from "@fetch/frontend"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Wrapper } from "./styled"
import styles from "./reactSelectStyles"

const DeliveryRoute = ({
  dropOffAddress,
  onAddressChange,
  requestOptions,
  searchPrefix,
  stops
}: ComponentProps) => (
  <Wrapper>
    <Card>
      <CardTitle>{"Delivery Route"}</CardTitle>

      <ItemsWrapper>
        {stops.map((stop: FetchStop) => (
          <DeliveryRouteItem key={`name-${stop.index}`}>
            <FontAwesomeIcon icon={faHome} />

            {stop.name}
          </DeliveryRouteItem>
        ))}

        <DeliveryRouteItem>
          <ItemWrapper>
            <FontAwesomeIcon icon={faMapMarkerAlt} />

            <AddressDropdown
              onChange={onAddressChange}
              requestOptions={requestOptions}
              searchPrefix={searchPrefix}
              styles={styles}
              value={dropOffAddress}
            />
          </ItemWrapper>
        </DeliveryRouteItem>
      </ItemsWrapper>
    </Card>
  </Wrapper>
)

export default DeliveryRoute

// @flow

import gql from "graphql-tag"

const POST_VALIDATE_PHONE = gql`
  mutation postValidatePhone($phoneNumber: String!) {
    postValidatePhone(phoneNumber: $phoneNumber) {
      success
    }
  }
`

export default POST_VALIDATE_PHONE

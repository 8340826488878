// @flow

import { Input, Wrapper } from "./styled"
import BaseButton from "components/buttons/Base"
import { type ComponentProps } from "."
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { faSearch } from "@fortawesome/free-solid-svg-icons"

const SearchBar = ({ onChange, placeholder, value }: ComponentProps) => (
  <Wrapper>
    <BaseButton type="submit">
      <FontAwesomeIcon icon={faSearch} />
    </BaseButton>

    <Input
      onChange={onChange}
      placeholder={placeholder}
      type="search"
      value={value}
    />
  </Wrapper>
)

export default SearchBar

// @flow

import Card from "./Card"

export type ComponentProps = {
  +image: string,
  +label: string,
  +link: string,
  +slug: string,
  +title?: string
}

export default Card

// @flow

import { useMemo, useState } from "react"
import Categories from "./Categories"
import type { Category } from "@fetch/frontend"
import GET_CATEGORIES from "queries/getCategories"
import prepareComponent from "hocs/prepareComponent"
import { useHistory } from "react-router-dom"
import { useQuery } from "@apollo/client"

export type ComponentProps = {|
  +categories: Category[],
  +loading: boolean,
  +onChange: (ev: SyntheticInputEvent<EventTarget>) => void,
  +onSubmit: (ev: SyntheticEvent<any>) => void,
  +value: string
|}

type SetValue = (value: string) => void

const DEFAULT_CATEGORIES = []
const DEFAULT_VALUE = ""

const handleChange =
  (setValue: SetValue) => (ev: SyntheticInputEvent<EventTarget>) => {
    setValue(ev.target.value)
  }

const handleSubmit =
  (history: any, value: string) => (ev: SyntheticEvent<any>) => {
    ev.preventDefault()

    if (!value) {
      return
    }

    history.push(`/categories/search/subcategories/${value}/places`)
  }

const usePrepareComponent = (): ComponentProps => {
  const { data, error, loading } = useQuery(GET_CATEGORIES)
  const [value, setValue] = useState(DEFAULT_VALUE)
  const history = useHistory()

  const categories = useMemo(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.log(error.message)
      return DEFAULT_CATEGORIES
    }

    if (loading || !data) {
      return DEFAULT_CATEGORIES
    }

    return data.categories
  }, [data, error, loading])

  return {
    categories,
    loading,
    onChange: handleChange(setValue),
    onSubmit: handleSubmit(history, value),
    value
  }
}

export default prepareComponent<any, ComponentProps>(usePrepareComponent)(
  Categories
)

// @flow

import { Address, Details, Image, Main, Name, Wrapper } from "./styled"
import Buttons from "./Buttons"
import type { ComponentProps } from "."
import React from "react"
import { ReactComponent as Star } from "assets/icons/star.svg"

const Place = ({
  address,
  distance,
  image,
  isPartner,
  link,
  name,
  noServiceFee,
  reducedServiceFee
}: ComponentProps) => (
  <Wrapper isPartner={isPartner} to={link}>
    {isPartner && (
      <Buttons
        noServiceFee={noServiceFee}
        reducedServiceFee={reducedServiceFee}
      />
    )}

    {image && <Image image={image} />}

    <Main isPartner={isPartner} isThereImage={Boolean(image)}>
      <Name isPartner={isPartner}>
        {isPartner && <Star />}
        {name}
      </Name>

      <Address isPartner={isPartner}>{address}</Address>

      <Details isPartner={isPartner}>{distance}</Details>
    </Main>
  </Wrapper>
)

export default Place

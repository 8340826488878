// @flow

const ALL_STOPS_SAME_REGION = "All stops must be in the same region."

export const ERROR_COMPLETE_PROFILE =
  "You must have a complete profile in order to post a Fetch."

export const ERROR_PAYMENT = "Please select a payment source to create a Fetch."

const NO_DRIVERS =
  "Sorry! There are no drivers available to take your order at this time. Please try again at a later time."

const errorModalButtons = Object.freeze({
  [ALL_STOPS_SAME_REGION]: {
    showRefresh: false
  },
  [ERROR_COMPLETE_PROFILE]: {
    customButtons: [
      {
        label: "Complete Profile",
        link: "/profile?lastLocation=true",
        tagManagerEventName: "completeProfile"
      }
    ],
    showHome: false,
    showRefresh: false
  },
  [ERROR_PAYMENT]: {
    customButtons: [
      {
        label: "Add a card",
        link: "/payment?lastLocation=true",
        tagManagerEventName: "addACard"
      }
    ],
    showHome: false,
    showRefresh: false
  },
  [NO_DRIVERS]: {
    showRefresh: false
  }
})

export default errorModalButtons

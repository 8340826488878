// @flow

import gql from "graphql-tag"

const GET_ACTIONS = gql`
  query getActions($id: Int!) {
    actions(id: $id) {
      createdAt
      id
      image
      stopName
      text
      type
      userId
      value
    }
  }
`

export default GET_ACTIONS

// @flow

import Wrapper from "./Wrapper"

export type ComponentProps = {|
  +children: React$Node,
  +className?: string
|}

export default Wrapper

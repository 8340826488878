// @flow

import { ApolloProvider as BaseApolloProvider } from "@apollo/client"
import React from "react"
import graphql from "./apolloGraphQL"
import useModal from "hooks/useModal"

type ComponentProps = {
  +children: React$Node
}

const ApolloProvider = ({ children }: ComponentProps) => {
  const { onSetModal } = useModal()

  return (
    <BaseApolloProvider client={graphql(onSetModal)}>
      {children}
    </BaseApolloProvider>
  )
}

export default ApolloProvider

// @flow

import { Display, LeftButton, RightButton, Wrapper } from "./styled"
import { type ComponentProps } from "."
import React from "react"

const Quantity = ({
  onDecrement,
  onIncrement,
  counterValue
}: ComponentProps) => (
  <Wrapper>
    <LeftButton onClick={onDecrement}>{`-`}</LeftButton>

    <Display>{counterValue}</Display>

    <RightButton onClick={onIncrement}>{`+`}</RightButton>
  </Wrapper>
)

export default Quantity

// @flow

import gql from "graphql-tag"

const GET_PLACE_QUERY = gql`
  query getPlace($input: PlaceInput!) {
    place(input: $input) {
      address
      categories
      distance
      id
      image
      isPartner
      latitude
      longitude
      menus {
        id
        items {
          description
          detail
          id
          image
          menuItemOptionGroups {
            id
            maxSelections
            minSelections
            name
            menuItemOptions {
              description
              id
              name
              price
            }
          }
          name
          price
        }
        name
      }
      name
      noServiceFee
      nonPartnerMessage
      placeType
    }
  }
`

export default GET_PLACE_QUERY

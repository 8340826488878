// @flow
/* eslint-disable no-ternary */

import { Content, Subtitle, Title, Wrapper } from "./styled"
import Button from "@fetch/ui/Button"
import type { ComponentProps } from "."
import React from "react"
import ReactModal from "react-modal"
import SelectADeliveryTime from "components/Sidebar/SelectADeliveryTime"

// Call setAppElement only when not in the test environment
if (process.env.NODE_ENV !== "test") {
  ReactModal.setAppElement("#root")
}

const Modal = ({
  checkoutButton,
  isFetchScheduled,
  isModalOpen,
  onGoHomePage,
  onToggleModal,
  subtitle
}: ComponentProps) => (
  <ReactModal
    className="fetch-modal"
    contentLabel="Select Delivery Time Modal"
    isOpen={isModalOpen}
    onRequestClose={onToggleModal}
  >
    <Wrapper>
      <Title>
        {isFetchScheduled
          ? "You scheduled a Fetch"
          : "When would you like your order?"}
      </Title>

      {isFetchScheduled && subtitle ? <Subtitle>{subtitle}</Subtitle> : null}

      <Content>
        {isFetchScheduled ? (
          <Button label="Home Page" onClick={onGoHomePage} />
        ) : (
          <SelectADeliveryTime
            checkoutButton={checkoutButton}
            onCancel={onToggleModal}
            onSelect={onToggleModal}
          />
        )}
      </Content>
    </Wrapper>
  </ReactModal>
)

export default Modal

// @flow

import type { Address } from "@fetch/frontend"
import AddressBox from "./AddressBox"

export type ComponentProps = {|
  +deliveryAddress: Address,
  +onAddressSubmit: (event: SyntheticEvent<any>) => void,
  +onInputChange: (event: SyntheticKeyboardEvent<HTMLInputElement>) => void,
  +placeholder?: string
|}

export default AddressBox

// @flow

import styled, { type StyledComponent } from "styled-components"
import { Input } from "components/Sidebar/styled"
import type { Theme } from "@bluframe/blublocks"
import colors from "styles/colors"

export const Wrapper: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  display: flex;
`

export const CouponInput: StyledComponent<
  {||},
  Theme,
  StyledComponent<{||}, Theme, HTMLElement>
> = styled(Input)`
  color: ${({ theme }) => theme.palette.text.main};
  height: 45px;
  margin-left: 0;
  margin-right: 10px;
  margin-top: 0;

  &::placeholder {
    font-size: 16px;
  }

  &:disabled {
    background: ${colors.alabaster};
  }
`

// @flow

import Quantity from "./Quantity"

export type ComponentProps = {
  +onDecrement: () => void,
  +onIncrement: () => void,
  +counterValue: number
}

export default Quantity

// @flow

import { useMemo, useState } from "react"
import GET_LAST_4 from "queries/getLast4"
import Payment from "./Payment"
import prepareComponent from "hocs/prepareComponent"
import { useQuery } from "@apollo/client"

export type ComponentProps = {
  +cardUpdateLoading: boolean,
  +last4: string,
  +loading: boolean,
  +onSetCardUpdateLoading: (isLoading: boolean) => void
}

const DEFAULT_LAST_4 = ""
const DEFAULT_CART_UPDATE_LOADING = false

const usePrepareComponent = (): ComponentProps => {
  const { data, error: last4Error, loading } = useQuery(GET_LAST_4)
  const [cardUpdateLoading, setCardUpdateLoading] = useState(
    DEFAULT_CART_UPDATE_LOADING
  )

  const onSetCardUpdateLoading = (isLoading: boolean) => {
    setCardUpdateLoading(isLoading)
  }

  const last4 = useMemo(() => {
    if (last4Error) {
      // eslint-disable-next-line no-console
      console.log(last4Error.message)
      return DEFAULT_LAST_4
    }

    if (loading || !data) {
      return DEFAULT_LAST_4
    }

    return data.last4
  }, [data, last4Error, loading])

  return { cardUpdateLoading, last4, loading, onSetCardUpdateLoading }
}

export default prepareComponent(usePrepareComponent)(Payment)

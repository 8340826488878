// @flow

import Carousel from "./Carousel"
import prepareComponent from "hocs/prepareComponent"
import { useRef } from "react"

export type Props = {|
  +children: any
|}

export type ComponentProps = {|
  +carouselRef: any,
  +children: any,
  +onLeftClick: () => void,
  +onRightClick: () => void
|}

const SCROLL = 500

const usePrepareComponent = ({ children }: Props): ComponentProps => {
  const carouselRef = useRef(null)

  const onLeftClick = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft -= SCROLL
    }
  }

  const onRightClick = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft += SCROLL
    }
  }

  return {
    carouselRef,
    children,
    onLeftClick,
    onRightClick
  }
}

export default prepareComponent<any, ComponentProps>(usePrepareComponent)(
  Carousel
)

// @flow
/* eslint-disable max-lines-per-function, no-ternary, react/jsx-no-bind, no-empty-function */

import {
  Bottom,
  DateAndTime,
  Input,
  Main,
  Order,
  Section,
  StopName,
  Stops,
  TextArea,
  Top,
  Wrapper
} from "./styled"
import AddressDropdown from "components/AddressDropdown"
import { Button } from "@fetch/ui"
import type { ComponentProps } from "."
import React from "react"
import dropdownStyles from "./dropdownStyles"

const Item = ({
  editingAddress,
  isEditing,
  isEditingOthers,
  onAddressChange,
  onDeleteAddress,
  onDeliveryInstructionsChange,
  onNameChange,
  onSaveEditingAddress,
  onSetEditingIndex,
  savedAddress
}: ComponentProps) => (
  <Wrapper>
    <Top isEditing={isEditing}>
      {isEditing ? (
        <Input
          onChange={onNameChange}
          placeholder="Enter a name for your address"
          type="text"
          value={editingAddress.name}
        />
      ) : (
        <DateAndTime>{savedAddress.name}</DateAndTime>
      )}
    </Top>

    <Main>
      <Section isEditing={isEditing}>
        {isEditing ? (
          <AddressDropdown
            onChange={onAddressChange}
            placeholder="Start typing your address"
            styles={dropdownStyles}
            value={editingAddress.address}
          />
        ) : (
          <Order>{savedAddress.address.label}</Order>
        )}
      </Section>

      <Stops isEditing={isEditing}>
        {isEditing ? (
          <TextArea
            onChange={onDeliveryInstructionsChange}
            placeholder="Enter special delivery instructions"
            value={editingAddress.deliveryInstructions}
          />
        ) : (
          <StopName>{savedAddress.deliveryInstructions}</StopName>
        )}
      </Stops>
    </Main>

    <Bottom>
      {isEditing ? (
        <>
          <Button label="Cancel" onClick={onSetEditingIndex} outlined />
          <Button label="Save" onClick={onSaveEditingAddress} />
        </>
      ) : (
        <>
          <Button
            disabled={isEditingOthers}
            label="Delete"
            onClick={onDeleteAddress}
            outlined
          />
          <Button
            disabled={isEditingOthers}
            label="Edit"
            onClick={onSetEditingIndex}
          />
        </>
      )}
    </Bottom>
  </Wrapper>
)

export default Item

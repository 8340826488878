// @flow
/* eslint-disable max-lines-per-function */

import { Container, CrossWrapper } from "./styled"
import type { ComponentProps } from "."
import Cross from "components/icons/Cross"
import CustomOrder from "components/CustomOrder"
import ImageSearch from "./ImageSearch"
import MenuItemOptions from "./MenuItemOptions"
import React from "react"
import ReactModal from "react-modal"

// To avoid error in unit tests
if (process.env.NODE_ENV !== "test") ReactModal.setAppElement("#root")

const Modal = ({
  customOrderItems,
  imageSearch,
  isCustomOrderOpen,
  isModalOpen,
  onAddCustomOrder,
  onAddToOrder,
  onRequestClose,
  onSelectImage,
  onSetCustomOrderItems,
  onToggleModal,
  selectedImage,
  selectedItem,
  setImageSearch
}: ComponentProps) => (
  <ReactModal
    className="fetch-modal custom-order"
    contentLabel="Place Modal"
    isOpen={isModalOpen}
    onRequestClose={onRequestClose}
  >
    <Container>
      <CrossWrapper>
        <Cross onClick={onToggleModal({})} />
      </CrossWrapper>
      {isCustomOrderOpen && isModalOpen && (
        <CustomOrder
          items={customOrderItems}
          onAddCustomOrder={onAddCustomOrder}
          onSelectImage={onSelectImage}
          onSetItems={onSetCustomOrderItems}
          onToggleModal={onToggleModal}
          selectedImage={selectedImage}
        />
      )}

      {selectedItem && !isCustomOrderOpen && isModalOpen && (
        <MenuItemOptions {...selectedItem} onAddToOrder={onAddToOrder} />
      )}

      {!selectedItem && !isCustomOrderOpen && isModalOpen && (
        <ImageSearch
          imageSearch={imageSearch}
          onCloseModal={onToggleModal({
            customOrderOpen: true,
            modalOpen: true
          })}
          onSelectImage={onSelectImage}
          setImageSearch={setImageSearch}
        />
      )}
    </Container>
  </ReactModal>
)

export default Modal

// @flow
/* eslint-disable max-lines-per-function, max-statements */

import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import GET_PLACES_BY_TAG_QUERY from "queries/getPlacesByTag"
import GET_PLACES_QUERY from "queries/getPlaces"
import type { PlaceType } from "@fetch/frontend"
import Places from "./Places"
import curatedCategories from "data/curatedCategories"
import prepareComponent from "hocs/prepareComponent"
import propEq from "ramda/src/propEq"
import useDropOffAddress from "hooks/useDropOffAddress"
import { useLazyQuery } from "@apollo/client"

export type ComponentProps = {|
  +basicPlaces: PlaceType[],
  +loading?: boolean,
  +notFound: boolean,
  +onChange: (ev: SyntheticInputEvent<EventTarget>) => void,
  +onSubmit: (ev: SyntheticEvent<any>) => void,
  +premiumPlaces: PlaceType[],
  +publicNonPartnerPlaces: PlaceType[],
  +standardPlaces: PlaceType[],
  +subcategory: string,
  +value: string
|}

type SetValue = (value: string) => void

const DEFAULT_VALUE = ""
const REMOVED_STRING = " mi"
const SORT_FUNCTION = ({ distance: distanceA }, { distance: distanceB }) =>
  distanceA.replace(REMOVED_STRING, "") - distanceB.replace(REMOVED_STRING, "")
const ZERO = 0

const handleChange =
  (setValue: SetValue) => (ev: SyntheticInputEvent<EventTarget>) => {
    setValue(ev.target.value)
  }

const handleSubmit =
  (history: any, value: string) => (ev: SyntheticEvent<any>) => {
    ev.preventDefault()

    if (!value) {
      return
    }

    history.push(`/categories/search/subcategories/${value}/places`)
  }

export const usePrepareComponent = (): ComponentProps => {
  const params = useParams()
  const {
    dropOffAddress: {
      coordinates: { lat, lng }
    }
  } = useDropOffAddress()
  const [
    getPlacesBySubcategory,
    {
      data: dataSubcategory,
      error: errorSubcategory,
      loading: loadingSubcategory
    }
  ] = useLazyQuery(GET_PLACES_QUERY, {
    variables: {
      input: { latitude: lat, longitude: lng, query: params.subcategory }
    }
  })
  const [
    getPlacesByTag,
    { data: dataTag, error: errorTag, loading: loadingTag }
  ] = useLazyQuery(GET_PLACES_BY_TAG_QUERY)
  const [value, setValue] = useState(DEFAULT_VALUE)
  const history = useHistory()

  useEffect(() => {
    if (lat && lng) {
      if (params.subcategory) {
        const tag = curatedCategories[params.subcategory]

        if (tag) {
          getPlacesByTag({
            variables: {
              input: {
                latitude: lat,
                longitude: lng,
                tag
              }
            }
          })
          return
        }
      }

      getPlacesBySubcategory()
    }
  }, [getPlacesBySubcategory, getPlacesByTag, lat, lng, params.subcategory])

  if (errorSubcategory || errorTag) {
    // eslint-disable-next-line no-console
    console.log(errorSubcategory?.message)
    // eslint-disable-next-line no-console
    console.log(errorTag?.message)
    return {
      basicPlaces: [],
      notFound: false,
      onChange: handleChange(setValue),
      onSubmit: handleSubmit(history, value),
      premiumPlaces: [],
      publicNonPartnerPlaces: [],
      standardPlaces: [],
      subcategory: "",
      value
    }
  }

  if (loadingSubcategory || loadingTag || (!dataSubcategory && !dataTag)) {
    return {
      basicPlaces: [],
      loading: loadingSubcategory || loadingTag,
      notFound: false,
      onChange: handleChange(setValue),
      onSubmit: handleSubmit(history, value),
      premiumPlaces: [],
      publicNonPartnerPlaces: [],
      standardPlaces: [],
      subcategory: "",
      value
    }
  }

  const data = dataSubcategory?.places || dataTag?.placesByTag

  const basicPlaces = data
    .filter(
      ({ reducedServiceFee, noServiceFee, hasMenu }) =>
        !reducedServiceFee && !noServiceFee && hasMenu
    )
    .sort(SORT_FUNCTION)

  const premiumPlaces = data
    .filter(propEq("noServiceFee", true))
    .sort(SORT_FUNCTION)

  const publicNonPartnerPlaces = data
    .filter(
      ({ reducedServiceFee, noServiceFee, hasMenu }) =>
        !reducedServiceFee && !noServiceFee && !hasMenu
    )
    .sort(SORT_FUNCTION)

  const standardPlaces = data
    .filter(propEq("reducedServiceFee", true))
    .sort(SORT_FUNCTION)

  const notFound = [
    premiumPlaces.length,
    basicPlaces.length,
    publicNonPartnerPlaces.length,
    standardPlaces.length
  ].every((item) => item === ZERO)

  return {
    basicPlaces,
    notFound,
    onChange: handleChange(setValue),
    onSubmit: handleSubmit(history, value),
    premiumPlaces,
    publicNonPartnerPlaces,
    standardPlaces,
    subcategory: params?.subcategory ?? "",
    value
  }
}

export default prepareComponent(usePrepareComponent)(Places)

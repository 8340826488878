// @flow
/* eslint-disable no-ternary, no-magic-numbers */

import styled, { type StyledComponent } from "styled-components"
import ResetButton from "components/buttons/Reset"
import type { Theme } from "@bluframe/blublocks"
import colors from "styles/colors"
import mediaQueries from "@bluframe/blublocks/mediaQueries"

export const Wrapper: StyledComponent<
  {| +showSidebar: boolean |},
  Theme,
  HTMLElement
> = styled.div`
  background: ${({ showSidebar }) => (showSidebar ? colors.white : "initial")};
  display: block;
  left: ${({ showSidebar }) => (showSidebar ? 0 : "auto")};
  position: fixed;
  right: ${({ showSidebar }) => (showSidebar ? "auto" : 0)};
  top: ${({ showSidebar }) => (showSidebar ? 0 : "auto")};
  width: ${({ showSidebar }) => (showSidebar ? "100%" : "auto")};
  z-index: ${({ showSidebar }) => (showSidebar ? "150" : "0")};

  ${mediaQueries.tablet} {
    background: initial;
    display: flex;
    height: calc(100vh - 98px);
    justify-content: center;
    position: sticky;
    right: 0;
    top: 98px;
    z-index: auto;
  }
`

export const Container: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  position: sticky;
  margin: 0 0 0 auto;
  width: 100%;

  ${mediaQueries.tablet} {
    width: 370px;
  }
`

export const Cards: StyledComponent<
  {| +showSidebar: boolean |},
  Theme,
  HTMLElement
> = styled.div`
  display: ${({ showSidebar }) => (showSidebar ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  margin-left: 0px;

  ${mediaQueries.desktop} {
    margin-left: 20px;
  }
`

export const Card: StyledComponent<{||}, Theme, HTMLElement> = styled.div``

export const CardTitle: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  color: ${colors.thunder};
  display: flex;
  font-family: Roboto, Arial, open-sans;
  font-size: 18px;
  letter-spacing: 0px;
  justify-content: space-between;
  margin-bottom: 10px;
  text-align: left;
`

export const ItemsWrapper: StyledComponent<
  {||},
  Theme,
  HTMLElement
> = styled.div`
  display: flex;
  flex-direction: column;
`

export const DeliveryRouteItem: StyledComponent<
  {| +isClickable?: boolean |},
  Theme,
  HTMLElement
> = styled.div`
  align-items: center;
  color: ${colors.doveGray};
  ${({ isClickable }) => isClickable && "cursor: pointer;"};
  display: flex;
  font-family: Roboto, Arial, open-sans;
  font-size: 14px;
  letter-spacing: 0px;
  padding-bottom: 5px;
  padding-top: 5px;
  text-align: left;

  svg {
    padding-right: 5px;
    width: 26px !important;
  }
`

export const ItemWrapper: StyledComponent<
  {||},
  Theme,
  HTMLElement
> = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`

export const Input: StyledComponent<{||}, Theme, HTMLElement> = styled.input`
  border: 0.5px solid ${colors.mercury};
  border-radius: 3px;
  box-shadow: 0px 0px 4px ${colors.stratos};
  color: ${colors.doveGray};
  height: 20px;
  max-width: 180px;
  margin: 0 10px;
  padding: 0 20px;
`

export const ButtonWrapper: StyledComponent<
  {||},
  Theme,
  HTMLElement
> = styled.div`
  background: ${colors.white};
  bottom: 0;
  box-shadow: 0 4px 2px -2px ${colors.stratos};
  display: grid;
  left: 0;
  padding: 20px;
  position: fixed;
  row-gap: 10px;
  width: 100%;
  z-index: 300;

  ${mediaQueries.tablet} {
    padding-bottom: 20px;
    padding-top: 20px;
    position: relative;
  }

  &:before {
    box-shadow: -7px 0 15px -15px ${colors.stratos} inset;
    content: " ";
    height: 100%;
    left: -15px;
    position: absolute;
    top: 0;
    width: 15px;
  }
`

export const Box: StyledComponent<{||}, Theme, HTMLElement> = styled.div`
  border-top: 1px solid ${colors.silver};
  padding: 20px;
  position: relative;

  &:before {
    box-shadow: -7px 0 15px -15px ${colors.stratos} inset;
    content: " ";
    height: 100%;
    left: -15px;
    position: absolute;
    top: 0;
    width: 15px;
  }
`

export const BoxPromoCode: StyledComponent<
  {||},
  Theme,
  StyledComponent<{||}, Theme, HTMLElement>
> = styled(Box)`
  ${mediaQueries.tablet} {
    padding-bottom: 20px;
  }
`

export const SidebarWrapper: StyledComponent<
  {||},
  Theme,
  HTMLElement
> = styled.div`
  max-height: 100vh;
  overflow-y: scroll;
  padding-bottom: 200px;

  ${mediaQueries.tablet} {
    height: calc(100vh - 252px);
    max-height: none;
    overflow-y: auto;
    padding-bottom: 0;
  }
`

export const ShowSidebar: StyledComponent<
  {| +showSidebar: boolean |},
  Theme,
  HTMLElement
> = styled.div`
  display: ${({ showSidebar }) => (showSidebar ? "none" : "flex")};
  flex-direction: row-reverse;
  margin-right: 10px;
  margin-top: 10px;

  ${mediaQueries.tablet} {
    margin-top: 15px;
  }
`

export const ShowSidebarButton: StyledComponent<
  {||},
  Theme,
  StyledComponent<{||}, Theme, HTMLElement>
> = styled(ResetButton)`
  align-items: center;
  background: ${colors.thunder};
  border-radius: 25px;
  color: ${colors.white};
  cursor: pointer;
  display: flex;
  margin-right: 10px;
  padding: 8px 17px 8px 8px;
  text-align: center;

  svg {
    margin: 0 10px;

    path {
      fill: ${colors.white};
    }
  }
`

export const OrderHeader: StyledComponent<
  {||},
  Theme,
  HTMLElement
> = styled.div`
  align-items: center;
  border-bottom: 1px solid ${colors.stratos};
  box-shadow: 0px 8px 16px ${colors.blackRussian};
  display: flex;
  font: normal normal bold 24px/42px Roboto;
  height: 67px;
  justify-content: center;
  position: relative;
`

export const CrossWrapper: StyledComponent<
  {||},
  Theme,
  HTMLElement
> = styled.div`
  left: 15px;
  position: absolute;
  top: 24px;
`

// @flow
/* eslint-disable no-ternary, require-unicode-regexp */

import colors from "styles/colors"
import mediaQueries from "styles/mediaQueries"

const styles = {
  container: (provided: any) => ({
    ...provided,
    "&:hover": {
      border: "none",
      outline: "none"
    },
    marginBottom: 30,
    marginTop: 30,
    width: "100%",
    [mediaQueries.desktop]: {
      width: "250px"
    }
  }),
  control: (provided: any) => ({
    ...provided,
    "&:hover": {
      border: "none",
      outline: "none"
    },
    border: "none",
    boxShadow: "none",
    cursor: "pointer",
    fontFamily: "Roboto, Arial, open-sans",
    fontWeight: 700
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: "none"
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,

    "& div": {
      color: colors.thunder
    },
    flex: "3",
    [mediaQueries.desktop]: {
      flex: "0 1 auto"
    }
  }),
  input: (provided: any) => ({
    ...provided,
    "& input": {
      "&:focus": {
        boxShadow: "none"
      },
      boxShadow: "none",
      height: "auto",
      margin: 0
    },
    caretColor: colors.white
  }),
  option: (provided: any) => ({
    ...provided,
    fontFamily: "Roboto, Arial, open-sans",
    fontWeight: 700
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: colors.eminence,
    fontSize: "18px",
    paddingRight: "12px",
    textAlign: "left",
    [mediaQueries.desktop]: {
      textAlign: "right"
    },
    width: "100%"
  }),
  singleValue: (provided: any) => ({
    ...provided,
    [mediaQueries.desktop]: {
      textAlign: "right",
      width: "100%"
    }
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    flex: 1,
    paddingLeft: 0,
    [mediaQueries.desktop]: {
      paddingLeft: "auto"
    }
  })
}

export default styles

// @flow
/* eslint-disable max-lines-per-function, max-statements */

import type { CustomOrderItem, MenuItem } from "@fetch/frontend"
import { useCallback, useEffect, useState } from "react"
import CustomPlace from "./CustomPlace"
import prepareComponent from "hocs/prepareComponent"
import useDropOffAddress from "hooks/useDropOffAddress"
import useFetchState from "hooks/useFetchState"
import usePickUpAddress from "hooks/usePickUpAddress"

export type ComponentProps = {|
  +imageSearch: string,
  +items: CustomOrderItem[],
  +isCustomOrderOpen: boolean,
  +isModalOpen: boolean,
  +onAddCustomOrder: (params: any) => void,
  +onChange: (event: SyntheticKeyboardEvent<*>) => void,
  +onDeleteItem: (params: any) => () => void,
  +onDeleteStop: (index: number) => () => void,
  +onSelectImage: (image: string) => void,
  +onSetItems: (params: any) => void,
  +onToggleModal: (options: any) => () => void,
  +selectedImage: string,
  +selectedItem: ?MenuItem,
  +setImageSearch: (imageSearch: string) => void
|}

const DEFAULT_IMAGE_SEARCH = ""
const DEFAULT_IS_CUSTOM_ORDER_OPEN = false
const DEFAULT_IS_MODAL_OPEN = false
const DEFAULT_ITEM = { image: "", name: "" }
const DEFAULT_SEARCH_TERM = ""
const DEFAULT_SELECTED_ID = null
const DEFAULT_SELECTED_IMAGE = ""

const usePrepareComponent = (): ComponentProps => {
  const {
    dropOffAddress: {
      // eslint-disable-next-line
      coordinates: { lat: dropOffLat, lng: dropOffLng }
    }
  } = useDropOffAddress()
  const {
    pickUpAddress: {
      coordinates: { lat: pickUpLat, lng: pickUpLng },
      label: pickUpName
    }
  } = usePickUpAddress()
  // eslint-disable-next-line
  const [searchTerm, setSearchTerm] = useState(DEFAULT_SEARCH_TERM)
  const [isModalOpen, setIsModalOpen] = useState(DEFAULT_IS_MODAL_OPEN)
  const [selectedItem, setSelectedItem] = useState(DEFAULT_SELECTED_ID)
  const [imageSearch, setImageSearch] = useState(DEFAULT_IMAGE_SEARCH)
  const [selectedImage, setSelectedImage] = useState(DEFAULT_SELECTED_IMAGE)
  const { onAddItemToOrder, onDeleteItem, onRemovePlace, onSetPlace } =
    useFetchState()
  const [items, setItems] = useState([DEFAULT_ITEM])
  const [isCustomOrderOpen, setIsCustomOrderOpen] = useState(
    DEFAULT_IS_CUSTOM_ORDER_OPEN
  )

  useEffect(() => {
    if (pickUpName && pickUpLat && pickUpLng) {
      onSetPlace({
        distance: "",
        latitude: pickUpLat,
        longitude: pickUpLng,
        name: pickUpName
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickUpLat, pickUpLng, pickUpName])

  const onChange = useCallback((event: SyntheticKeyboardEvent<*>): void => {
    setSearchTerm(event.currentTarget.value)
  }, [])

  const onToggleModal = useCallback(
    ({ customOrderOpen, imageSearch: imageSearchName, item, modalOpen }) =>
      () => {
        setSelectedItem(item)
        setImageSearch(imageSearchName)
        setIsCustomOrderOpen(Boolean(customOrderOpen))

        if (modalOpen === true || modalOpen === false) {
          setIsModalOpen(modalOpen)
          return
        }

        setIsModalOpen(!isModalOpen)
      },
    [
      isModalOpen,
      setImageSearch,
      setIsCustomOrderOpen,
      setIsModalOpen,
      setSelectedItem
    ]
  )

  const onAddCustomOrder = useCallback(
    ({ image, name }) => {
      onAddItemToOrder({
        menuItem: [{ menuItem: { id: name, image, name, price: 0 } }],
        name: pickUpName
      })

      setSelectedItem(null)
      setIsModalOpen(false)
    },
    [onAddItemToOrder, pickUpName]
  )

  const onSetItems = (newItems: CustomOrderItem[]) => {
    setItems(newItems)
  }

  const onDeleteItemFromState = useCallback(
    (itemIndex, itemId) => () => {
      onDeleteItem({ itemIndex, name: pickUpName })

      const newItems = items.filter(({ name }) => itemId !== name)

      onSetItems(newItems)
    },
    [items, onDeleteItem, pickUpName]
  )

  const onDeleteStop = useCallback(
    (index: number) => () => {
      onRemovePlace({ index })
    },
    [onRemovePlace]
  )

  const onSelectImage = useCallback(
    (image: string) => {
      setSelectedImage(image)
    },
    [setSelectedImage]
  )

  return {
    imageSearch,
    isCustomOrderOpen,
    isModalOpen,
    items,
    onAddCustomOrder,
    onChange,
    onDeleteItem: onDeleteItemFromState,
    onDeleteStop,
    onSelectImage,
    onSetItems,
    onToggleModal,
    selectedImage,
    selectedItem,
    setImageSearch
  }
}

export default prepareComponent(usePrepareComponent)(CustomPlace)

// @flow

import gql from "graphql-tag"

const POST_SCHEDULED_FETCH_MUTATION = gql`
  mutation postScheduledFetch($scheduledFetch: ScheduledFetchInput!) {
    postScheduledFetch(scheduledFetch: $scheduledFetch) {
      id
      nextFetchDate
    }
  }
`

export default POST_SCHEDULED_FETCH_MUTATION

// @flow
/* eslint-disable no-process-env, camelcase */

const DEFAULT_STRIPE_PUBLISHABLE_kEY = "pk_test_VTve7dV2NxrXnNtKIMlvBlhC"

const config = {
  STRIPE_PUBLISHABLE_KEY:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
    DEFAULT_STRIPE_PUBLISHABLE_kEY
}

module.exports = config

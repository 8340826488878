// @flow
/* eslint-disable max-lines-per-function, max-statements */

import CategorySection from "./CategorySection"
import GET_PLACES_QUERY from "queries/getPlaces"
import type { PlaceType } from "@fetch/frontend"
import prepareComponent from "hocs/prepareComponent"
import propEq from "ramda/src/propEq"
import useDropOffAddress from "hooks/useDropOffAddress"
import { useEffect } from "react"
import { useLazyQuery } from "@apollo/client"

type Props = {|
  +label: string,
  +slug: string
|}

export type ComponentProps = {|
  ...Props,
  +sectionPlaces: PlaceType[]
|}

const REMOVED_STRING = " mi"
const SORT_FUNCTION = ({ distance: distanceA }, { distance: distanceB }) =>
  distanceA.replace(REMOVED_STRING, "") - distanceB.replace(REMOVED_STRING, "")
const ZERO = 0
const FIVE = 5

export const usePrepareComponent = ({ label, slug }: Props): ComponentProps => {
  const {
    dropOffAddress: {
      coordinates: { lat, lng }
    }
  } = useDropOffAddress()
  const [
    getPlacesBySubcategory,
    {
      data: dataSubcategory,
      error: errorSubcategory,
      loading: loadingSubcategory
    }
  ] = useLazyQuery(GET_PLACES_QUERY, {
    variables: {
      input: { latitude: lat, longitude: lng, query: slug }
    }
  })

  useEffect(() => {
    if (lat && lng) {
      getPlacesBySubcategory()
    }
  }, [getPlacesBySubcategory, lat, lng])

  if (errorSubcategory) {
    // eslint-disable-next-line no-console
    console.log(errorSubcategory?.message)
    return {
      label: "",
      sectionPlaces: [],
      slug: ""
    }
  }

  if (loadingSubcategory || !dataSubcategory) {
    return {
      label: "",
      sectionPlaces: [],
      slug: ""
    }
  }

  const data = dataSubcategory?.places

  const basicPlaces = data
    .filter(
      ({ reducedServiceFee, noServiceFee, hasMenu }) =>
        !reducedServiceFee && !noServiceFee && hasMenu
    )
    .sort(SORT_FUNCTION)

  const premiumPlaces = data
    .filter(propEq("noServiceFee", true))
    .sort(SORT_FUNCTION)

  const publicNonPartnerPlaces = data
    .filter(
      ({ reducedServiceFee, noServiceFee, hasMenu }) =>
        !reducedServiceFee && !noServiceFee && !hasMenu
    )
    .sort(SORT_FUNCTION)

  const standardPlaces = data
    .filter(propEq("reducedServiceFee", true))
    .sort(SORT_FUNCTION)

  return {
    label,
    sectionPlaces: [
      ...premiumPlaces,
      ...standardPlaces,
      ...basicPlaces,
      ...publicNonPartnerPlaces
    ].slice(ZERO, FIVE),
    slug
  }
}

export default prepareComponent(usePrepareComponent)(CategorySection)

// @flow

import gql from "graphql-tag"

const POST_VALIDATE_COUPON_MUTATION = gql`
  mutation postValidateCoupon($couponInput: CouponInput!) {
    postValidateCoupon(couponInput: $couponInput) {
      code
      discountAmount
      id
    }
  }
`

export default POST_VALIDATE_COUPON_MUTATION

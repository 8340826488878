// @flow
/* eslint-disable max-lines-per-function, no-ternary, react/jsx-max-depth */

import {
  Attention,
  Box,
  ButtonsWrapper,
  IconWrapper,
  ItemsContainer,
  ItemsWrapper,
  MenuItem,
  MenuItemName,
  ProgressButton,
  Subtitle
} from "./styled"
import { type ComponentProps, STATUSES } from "."
import AnimateHeight from "react-animate-height"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

const ZERO = 0

const CollapsibleBox = ({
  icon,
  instructions,
  items,
  isToggled,
  onToggleBox,
  status,
  title
}: ComponentProps) => (
  <Box onClick={onToggleBox}>
    <Subtitle>
      <IconWrapper>
        <FontAwesomeIcon icon={icon} />
      </IconWrapper>

      {title}
    </Subtitle>

    <AnimateHeight
      animateOpacity
      duration={850}
      height={isToggled ? "auto" : ZERO}
    >
      <ItemsWrapper>
        <ItemsContainer>
          {items?.map(({ index, instructions: instructionsItem, label }) => (
            <MenuItem key={index}>
              <MenuItemName>
                {label}

                <Attention>{instructionsItem}</Attention>
              </MenuItemName>
            </MenuItem>
          ))}

          <MenuItem>
            <Attention>{instructions}</Attention>
          </MenuItem>
        </ItemsContainer>
      </ItemsWrapper>
    </AnimateHeight>

    <ButtonsWrapper>
      {status === STATUSES.FINISHED ? (
        <ProgressButton status>{`Completed`}</ProgressButton>
      ) : (
        <ProgressButton status={false}>{`Pending`}</ProgressButton>
      )}
    </ButtonsWrapper>
  </Box>
)

export default CollapsibleBox

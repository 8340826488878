// @flow
/* eslint-disable max-lines-per-function */

import { useCallback, useMemo } from "react"
import type { ErrorButton } from "@fetch/frontend"
import Modal from "./Modal"
import TagManager from "react-gtm-module"
import prepareComponent from "@bluframe/grapple/prepareComponent"
import { useHistory } from "react-router-dom"
import useModal from "hooks/useModal"

type Props = {||}

export type ComponentProps = {|
  +content: string | React$Node,
  +customButtons?: ErrorButton[],
  +isOpen: boolean,
  +isShowButtons: boolean,
  +okayLabel?: string,
  +onCancel: () => void,
  +onCustomButtonClick: (
    link: string,
    tagManagerEventName?: string
  ) => () => void,
  +onOkay: () => void,
  +onGoHome: () => void,
  +onRefresh: () => void,
  +onToggleModal?: () => void,
  +showCancel: boolean,
  +showOkay: boolean,
  +showHome: boolean,
  +showRefresh: boolean,
  +title: string
|}

const usePrepareComponent = (): ComponentProps => {
  const {
    modal: {
      content,
      customButtons,
      isOpen,
      okayLabel,
      onOkay: onOkayCustom,
      showCancel = false,
      showOkay = false,
      showHome = true,
      showRefresh = false,
      title
    },
    onSetModal
  } = useModal()
  const history = useHistory()

  const onRefresh = () => {
    window.location.reload(false)
  }

  const onGoHome = useCallback(() => {
    onSetModal({ content, isOpen: false, title })
    history.push("/")
  }, [content, history, onSetModal, title])

  const onCustomButtonClick = useCallback(
    (link: string, tagManagerEventName?: string) => () => {
      if (tagManagerEventName) {
        TagManager.dataLayer({
          dataLayer: {
            event: tagManagerEventName
          }
        })
      }

      onSetModal({ content, isOpen: false, title })
      history.push(link)
    },
    [content, history, onSetModal, title]
  )

  const onOkay = useCallback(() => {
    onSetModal({ content, isOpen: false, title })

    if (onOkayCustom) {
      onOkayCustom()
    }
  }, [content, onOkayCustom, onSetModal, title])

  const onCancel = useCallback(() => {
    onSetModal({ content, isOpen: false, title })
  }, [content, onSetModal, title])

  const isShowButtons = useMemo(
    () =>
      Boolean(
        showOkay ||
          showCancel ||
          showRefresh ||
          showHome ||
          customButtons?.length
      ),
    [customButtons?.length, showCancel, showHome, showOkay, showRefresh]
  )

  return {
    content,
    customButtons,
    isOpen,
    isShowButtons,
    okayLabel,
    onCancel,
    onCustomButtonClick,
    onGoHome,
    onOkay,
    onRefresh,
    showCancel,
    showHome,
    showOkay,
    showRefresh,
    title
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Modal
)

// @flow

import gql from "graphql-tag"

const UNSUBSCRIBE = gql`
  mutation unsubscribe {
    unsubscribe {
      id
      subscribedOn
      subscriptionId
      unsubscribedOn
      userId
    }
  }
`

export default UNSUBSCRIBE

// @flow
/* eslint-disable no-ternary, react/jsx-no-useless-fragment, max-lines-per-function */

import { Buttons, Wrapper } from "./styled"
import Button from "@fetch/ui/Button"
import type { ComponentProps } from "."
import DateTime from "@fetch/ui/DateTime"
import RadioGroup from "@fetch/ui/RadioGroup"
import React from "react"

const SelectADeliveryTime = ({
  checkoutButton,
  date,
  isActionDisabled,
  isScheduledOrderOnly,
  nowOrLater,
  nowOrLaterOptions,
  onCancel,
  onChangeNowOrLater,
  onChangeOneOffOrRecurring,
  onChangeRecurring,
  onSelect,
  onSelectDate,
  onSelectTime,
  oneOffOrRecurring,
  oneOffOrRecurringOptions,
  recurring,
  recurringOptions,
  time
}: ComponentProps) => (
  <Wrapper>
    {isScheduledOrderOnly ? null : (
      <RadioGroup
        label="We can deliver your order now or at a later time"
        name="nowOrLater"
        onChange={onChangeNowOrLater}
        options={nowOrLaterOptions}
        selected={nowOrLater}
      />
    )}

    {nowOrLater === "later" ? (
      <RadioGroup
        label="You can have a one-off or a recurring delivery"
        name="oneOffOrRecurring"
        onChange={onChangeOneOffOrRecurring}
        options={oneOffOrRecurringOptions}
        selected={oneOffOrRecurring}
      />
    ) : null}

    {oneOffOrRecurring === "recurring" ? (
      <RadioGroup
        label="How often would you like your order to be delivered?"
        name="recurring"
        onChange={onChangeRecurring}
        options={recurringOptions}
        selected={recurring}
      />
    ) : null}

    {nowOrLater === "later" ? (
      <DateTime
        isRecurring={oneOffOrRecurring === "recurring"}
        onSelectDate={onSelectDate}
        onSelectTime={onSelectTime}
        selectedDate={date}
        selectedTime={time}
      />
    ) : null}

    <Buttons>
      <Button label="Cancel" onClick={onCancel} outlined />
      {nowOrLater === "later" ? (
        <>{checkoutButton}</>
      ) : (
        <Button disabled={isActionDisabled} label="Select" onClick={onSelect} />
      )}
    </Buttons>
  </Wrapper>
)

export default SelectADeliveryTime

// @flow

import type { Address } from "@fetch/frontend"
import DropOffAddress from "./DropOffAddress"
import TagManager from "react-gtm-module"
import prepareComponent from "hocs/prepareComponent"
import useDropOffAddress from "hooks/useDropOffAddress"
import { useHistory } from "react-router-dom"

export type ComponentProps = {
  +dropOffAddress: Address,
  +onAddressSubmit: () => void,
  +onInputChange: (event: SyntheticKeyboardEvent<*>) => void
}

const usePrepareComponent = () => {
  const { dropOffAddress, onSetDropOffAddress } = useDropOffAddress()

  const history = useHistory()

  const onAddressSubmit = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "selectDropOffAddress"
      }
    })

    history.push("/")
  }

  const onInputChange = (newAddress) => {
    onSetDropOffAddress(newAddress)
  }

  return {
    dropOffAddress,
    onAddressSubmit,
    onInputChange
  }
}

export default prepareComponent(usePrepareComponent)(DropOffAddress)

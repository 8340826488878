// @flow

import { CouponInput, Wrapper } from "./styled"
import Button from "@fetch/ui/Button"
import type { ComponentProps } from "."
import React from "react"

const PromoCode = ({
  couponName,
  disabled,
  loading,
  onApplyPromoCode,
  onSetCouponName
}: ComponentProps) => (
  <Wrapper>
    <CouponInput
      disabled={disabled || loading}
      onChange={onSetCouponName}
      placeholder="Promo Code"
      value={couponName}
    />

    <Button
      disabled={disabled || loading}
      expanded
      label="Apply"
      loading={loading}
      onClick={onApplyPromoCode}
    />
  </Wrapper>
)

export default PromoCode

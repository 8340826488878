// @flow

import styled, { type StyledComponent } from "styled-components"
import ResetButton from "components/buttons/Reset"
import type { Theme } from "@bluframe/blublocks"
import colors from "styles/colors"
import mediaQueries from "styles/mediaQueries"

export const Wrapper: StyledComponent<any, Theme, HTMLElement> = styled.div`
  background-color: ${colors.white};
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 60px 40px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;

  ${mediaQueries.tablet} {
    flex: 0 0 auto;
    padding: 60px 40px;
  }

  ${mediaQueries.desktop} {
    justify-content: space-between;
  }
`

export const Name: StyledComponent<any, Theme, HTMLElement> = styled.h3`
  margin: 0;
  text-align: left;
  font-family: Roboto, Arial, open-sans;
  font-size: 16px;
  letter-spacing: 0px;
  color: ${colors.thunder};
`

export const Items: StyledComponent<any, Theme, HTMLElement> = styled.div`
  border-bottom: 1px solid ${colors.alto};
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

export const ItemWrapper: StyledComponent<any, Theme, HTMLElement> = styled.div`
  border-top: 1px solid ${colors.alto};
  position: relative;
`

export const Item: StyledComponent<
  any,
  Theme,
  HTMLElement
> = styled.input.attrs({ type: "text" })`
  border: none;
  box-shadow: none;
  font-family: Roboto, Arial, open-sans;
  font-size: 16px;
  margin: 0;
  padding: 10px;
  width: calc(100% - 70px);

  &:disabled {
    background-color: ${colors.white};
    color: ${colors.thunder};
  }

  &::placeholder {
    color: ${colors.alto};
  }

  &:active,
  &:focus {
    border: none;
    box-shadow: none;
    outline: 0;
  }
`

export const Icons: StyledComponent<any, Theme, HTMLElement> = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: 0;
  width: 80px;

  & svg {
    color: ${colors.driftwood};

    &:nth-of-type(1) {
      margin-right: 10px;
    }
  }
`

export const Image: StyledComponent<any, Theme, HTMLElement> = styled.img`
  height: 30px;
  margin-right: 5px;
  object-fit: cover;
  width: 30px;
`

export const Button: StyledComponent<
  any,
  Theme,
  StyledComponent<any, Theme, HTMLElement>
> = styled(ResetButton)`
  cursor: pointer;

  &:disabled {
    cursor: default;
    opacity: 60%;
  }

  & svg {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`

export const ButtonPlusWrapper: StyledComponent<
  any,
  Theme,
  HTMLElement
> = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
  padding-top: 20px;
`

export const Confirm: StyledComponent<any, Theme, any> = styled(Button)`
  background: ${colors.charcoal};
  color: ${colors.white};
  cursor: pointer;
  display: flex;
  font-family: "Roboto", Arial, open-sans;
  font-size: 18px;
  font-weight: 500;
  justify-content: center;
  margin: 20px 0 0;
  padding: 10px;
  bottom: 0;
`

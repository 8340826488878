// @flow
/* eslint-disable max-statements, max-lines-per-function */

import type { Address } from "@fetch/frontend"
import PickUpAddress from "./PickUpAddress"
import prepareComponent from "hocs/prepareComponent"
import { useHistory } from "react-router-dom"
import usePickUpAddress from "hooks/usePickUpAddress"

export type ComponentProps = {
  +onAddressSubmit: () => void,
  +onInputChange: (event: SyntheticKeyboardEvent<*>) => void,
  +pickUpAddress: Address
}

const usePrepareComponent = () => {
  const { pickUpAddress, onSetPickUpAddress } = usePickUpAddress()
  const history = useHistory()

  const onAddressSubmit = () => {
    history.push("/place/custom")
  }

  const onInputChange = (newAddress) => {
    onSetPickUpAddress(newAddress)
  }

  return {
    onAddressSubmit,
    onInputChange,
    pickUpAddress
  }
}

export default prepareComponent(usePrepareComponent)(PickUpAddress)

// @flow
/* eslint-disable react/jsx-max-depth, max-lines-per-function */

import {
  Container,
  Content,
  IconWrapper,
  InnerWrapper,
  InputWrapper,
  Label,
  SearchBox,
  SearchBoxWrapper,
  SearchButton,
  SearchButtonSpanWrapper,
  SearchButtonWrapper,
  SearchInnerSpan,
  Subtitle,
  Wrapper
} from "./styled"
import { faArrowRight, faSearch } from "@fortawesome/free-solid-svg-icons"
import AddressDropdown from "components/AddressDropdown"
import { type ComponentProps } from "."
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

const AddressBox = ({
  deliveryAddress,
  onAddressSubmit,
  onInputChange
}: ComponentProps) => (
  <Wrapper>
    <Subtitle>{`Search for your pick up address`}</Subtitle>

    <Container>
      <Content>
        <InnerWrapper>
          <InputWrapper>
            <Label htmlFor="location-search">
              {`Search for your pick up address`}
            </Label>

            <SearchBox onSubmit={onAddressSubmit}>
              <SearchBoxWrapper>
                <AddressDropdown
                  onChange={onInputChange}
                  value={deliveryAddress}
                />
              </SearchBoxWrapper>

              <SearchButtonWrapper>
                <SearchButtonSpanWrapper>
                  <SearchButton tabIndex="0" type="submit">
                    <SearchInnerSpan>
                      <FontAwesomeIcon icon={faArrowRight} />
                    </SearchInnerSpan>
                  </SearchButton>
                </SearchButtonSpanWrapper>
              </SearchButtonWrapper>

              <IconWrapper>
                <FontAwesomeIcon icon={faSearch} />
              </IconWrapper>
            </SearchBox>
          </InputWrapper>
        </InnerWrapper>
      </Content>
    </Container>
  </Wrapper>
)

export default AddressBox
